import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { Divider } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import Loader from "../components/Loader/Loader";
import { Link, useLocation } from "react-router-dom";
import Toast from "../components/Toast";
import { Trans, useTranslation } from "react-i18next";
import AdvancedSearch from "../components/SearchTab/AdvancedSearch";
import CollectionTile from "../components/Tiles/CollectionTile";
import ArtistTile from "../components/Tiles/ArtistTile";
import axios from "axios";
import { isObjEmpty } from "../utils/helper_functions";
import useScroll from "../utils/hooks/useScroll";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import ArtistName from "../components/ArtistName";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { Spinner } from "../components/Spinner/Spinner";
import { Hidden } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  hero: {
    // backgroundImage: `url(${Background})`,
    // backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    minHeight: "90vh",
    width: "100%",
    marginTop: "-60px",
    display: "flex",
    alignItems: "center",
  },

  heroMobile: {
    width: "100%",
    // marginTop: "-30px",
    height: "100px",
    display: "flex",
    alignItems: "center",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      // alignSelf: "flex-start",
    },
  },

  sectionMobile: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  searchtabs: {
    margin: "120px auto !important",
    maxWidth: "630px !important",
    display: "block",
  },

  searchArea: {
    width: "100%",
  },
  mainTitle: {
    color: "#000000",
    marginBottom: "50px",
  },
  mainMobileTitle: {
    width: "100%",
    color: "#000000",
    textAlign: "center",
  },

  contentGridLeft: {
    backgroundColor: "#F7F7F7",
  },

  contentGridLeftSection: {
    margin: "60px 105px",
  },
  collectionButton: {
    marginTop: "30px",
  },

  title: { fontSize: "35px", fontWeight: "700" },
  subtitle: { fontSize: "25px", fontWeight: "700" },
  divider: { marginTop: "20px !important", marginBottom: "10px !important" },
  contentBox: { marginBottom: "90px" },
  tilesBox: { marginTop: "40px" },
  collectionsGrid: { marginBottom: "60px" },
  bannerCollectionImg: { width: "100%", height: "100%", objectFit: "cover" },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Homepage() {
  const { t, i18n } = useTranslation();

  const language = i18n.language;
  const en = language === "en";

  let query = useQuery();
  var login = query.get("login");
  var logout = query.get("logout");
  useEffect(() => {
    checklogin();
    checkLogout();
  }, []);
  const [loginSnack, setLoginSnack] = React.useState(false);
  const [loginFailedSnack, setLoginFailedSnack] = React.useState(false);
  const [logoutSnack, setLogoutSnack] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      setLoginSnack(true);
      const url = new URL(window.location.href);
      url.searchParams.delete("login");
      window.history.replaceState({}, "", url);
    } else if (login === "fail") {
      setLoginFailedSnack(true);
      const url = new URL(window.location.href);
      url.searchParams.delete("fail");
      window.history.replaceState({}, "", url);
    }
  };

  const checkLogout = () => {
    if (logout === "success") {
      setLogoutSnack(true);
    }
  };
  const classes = useStyles();

  const [collections, setCollections] = useState([]);
  const [BannerCollection, setBannerCollection] = useState({});
  const [OnThisDayArtists, setOnThisDayArtists] = useState(null);
  const [RecentRecords, setRecentRecords] = React.useState([]);
  const [FreeRecords, setFreeRecords] = React.useState([]);
  const scroll = useScroll();

  useEffect(() => {
    if (scroll[1] >= 325) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scroll]);

  useEffect(() => {
    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME + "/collection/homepageBanner"
        )
        .then((response) => {
          if (response.status === 200) {
            const collection = response.data;
            if (collection.isAccessible) {
              setBannerCollection(collection);
            }
          }
        })
        .catch((err) => {
          console.log("API request Error: ", err);
        }),
      "banner"
    );

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            "/collection/homepageCollections"
        )
        .then((response) => {
          if (response.status === 200)
            setCollections(response.data.filter((i) => i.isAccessible));
        })
        .catch((err) => {
          console.log("API request Error: ", err);
        }),
      "collections"
    );

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            "/book/onThisDay?date=" +
            new Date().toLocaleDateString("en-US") +
            "&size=5"
        )
        .then((response) => {
          if (response.status === 200) setOnThisDayArtists(response.data);
        })
        .catch((err) => {
          console.log("API request Error: ", err);
        }),
      "on-this-day"
    );

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME + "/free/get-homepage-entries"
        )
        .then((response) => {
          if (response.status === 200) setFreeRecords(response.data);
        })
        .catch((err) => {
          console.log("API request Error: ", err);
        }),
      "free-entries"
    );

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            "/recentEntry/recentEntryArray?count=10"
        )
        .then((response) => {
          if (response.status === 200) setRecentRecords(response.data);
        })
        .catch((err) => {
          console.log("API request Error: ", err);
        }),
      "recent-entries"
    );
  }, []);

  const { promiseInProgress: recentEntriesPromiseInProgress } =
    usePromiseTracker({
      area: "recent-entries",
    });
  const { promiseInProgress: onThisDayPromiseInProgress } = usePromiseTracker({
    area: "on-this-day",
  });
  const { promiseInProgress: collectionsPromiseInProgress } = usePromiseTracker(
    {
      area: "collections",
    }
  );
  const { promiseInProgress: bannerPromiseInProgress } = usePromiseTracker({
    area: "banner",
  });

  const { promiseInProgress: freeEntriesPromiseInProgress } = usePromiseTracker(
    {
      area: "free-entries",
    }
  );

  let freeEntries = FreeRecords?.slice(0, 6).map((a) => (
    <Grid item md={6} sm={12}>
      <ArtistName
        name={a?.name}
        id={a?.id}
        isFree={a?.isFree}
        isNew={a?.isNew}
        isArtist={a?.type === "Person"}
      />
      <Divider sx={{ borderBottomWidth: 2 }} />
    </Grid>
  ));

  let gridArtist = [];
  // for (let index = 0; index < RecentRecords?.length; index += 2) {
  //   gridArtist.push(RecentRecords?.slice(index, index + 2));
  // }
  // console.log(JSON.stringify(FreeRecords, null, 2));
  gridArtist = RecentRecords?.map((a) => (
    <Grid item md={6} sm={12}>
      <ArtistName
        name={a?.name}
        id={a?.id}
        isFree={a?.isFree}
        isNew={a?.isNew}
        isArtist={a?.type === "Person"}
      />
      <Divider sx={{ borderBottomWidth: 2 }} />
    </Grid>
  ));

  let freeItemsBox = (
    <Box className={classes.contentBox}>
      <Typography className={classes.title}>
        {t("Publicly available")}
      </Typography>
      <Divider
        variant="fullWidth"
        className={classes.divider}
        sx={{ borderBottomWidth: 3 }}
      />
      {freeEntriesPromiseInProgress ? (
        <Spinner show />
      ) : (
        <Grid container spacing={4} className={classes.nameGrid}>
          {freeEntries}
        </Grid>
      )}
      <Box className={classes.tilesBox}>
        <Grid container spacing={3}>
          <Grid item md={6}></Grid>
          <Grid item md={6}></Grid>
        </Grid>
      </Box>
      <Link to="/results?accessType=free">
        <Button variant="contained" className="see-btn">
          &nbsp;{t("See all the free items")}&nbsp;
          <ArrowForwardIcon />
        </Button>
      </Link>
    </Box>
  );

  let recentOrUpdatedBox = (
    <Box className={classes.contentBox}>
      <Typography className={classes.title}>
        {t("Recently added or updated entries")}
      </Typography>
      <Divider
        variant="fullWidth"
        className={classes.divider}
        sx={{ borderBottomWidth: 3 }}
      />
      {recentEntriesPromiseInProgress ? (
        <Spinner show />
      ) : (
        <Grid container spacing={4} className={classes.nameGrid}>
          {gridArtist}
        </Grid>
      )}
      <Box className={classes.tilesBox}>
        <Grid container spacing={3}>
          <Grid item md={6}></Grid>
          <Grid item md={6}></Grid>
        </Grid>
      </Box>
      <Link to="/AddedUpdate">
        <Button variant="contained" className="see-btn">
          &nbsp;{t("See all added and updated entries")}
          &nbsp;
          <ArrowForwardIcon />
        </Button>
      </Link>
    </Box>
  );

  let onThisDayBox = (
    <Box className={classes.contentBox}>
      <Typography className={classes.title}>{t("On this day")}</Typography>
      <Divider
        variant="fullWidth"
        className={classes.divider}
        sx={{ borderBottomWidth: 2 }}
      />
      {onThisDayPromiseInProgress ? (
        <Spinner show />
      ) : OnThisDayArtists ? (
        <Box className="onthisday-box">
          <Grid container spacing={3}>
            {OnThisDayArtists.born.length > 0 ? (
              <Grid item md={6} sm={12}>
                <Typography className={classes.subtitle}>
                  {t("Born")}
                </Typography>
                {OnThisDayArtists.born.map((artist) => (
                  <div className="onthisday-tile">
                    <ArtistName
                      name={artist?.title}
                      id={artist?.id}
                      isArtist={true}
                      subtext={`
                      ${artist?.placeOfBirth?.split(";")[0] ?? ""}${
                        artist?.dateOfBirth && artist?.placeOfBirth ? ", " : ""
                      }${artist?.dateOfBirth?.split(".")[0] ?? ""}`}
                    />
                    <Divider sx={{ borderBottomWidth: 2 }} />
                  </div>
                ))}
              </Grid>
            ) : null}
            {OnThisDayArtists.died.length > 0 ? (
              <Grid item md={6} sm={12}>
                <Typography className={classes.subtitle}>
                  {t("Died")}
                </Typography>
                {OnThisDayArtists.died.map((artist) => (
                  <div className="onthisday-tile">
                    <ArtistName
                      name={artist?.title}
                      id={artist?.id}
                      isArtist={true}
                      subtext={`
                    ${artist?.placeOfDeath?.split(";")[0] ?? ""}${
                        artist?.dateOfDeath && artist?.placeOfDeath ? ", " : ""
                      }${artist?.dateOfDeath?.split(".")[0] ?? ""}`}
                    />
                    <Divider sx={{ borderBottomWidth: 2 }} />
                  </div>
                ))}
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );

  let gridCollections = [];
  for (let index = 0; index < collections.length; index += 5) {
    gridCollections.push(collections.slice(index, index + 5));
  }

  gridCollections = gridCollections.map((arr) => (
    <>
      {arr?.length !== 0 ? (
        <Grid container spacing={3} className={classes.collectionsGrid}>
          {arr?.length !== 0 ? (
            <Grid item md={6}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={6}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {arr?.length !== 0 ? (
        <Grid container spacing={3} className={classes.collectionsGrid}>
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </>
  ));

  if (!i18n.language) {
    return <></>;
  }

  return (
    <>
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      {loginFailedSnack ? (
        <Toast status={true} type="error" message="Login Failed" />
      ) : (
        ""
      )}
      {logoutSnack ? (
        <Toast status={true} type="success" message="Logged Out Successfully" />
      ) : (
        ""
      )}

      <div className="body-container">
        <Navbar isHomepage={isScrolled ? false : true} />
        <div className={classes.sectionDesktop}>
          <div className={classes.hero}>
            <div className={classes.searchArea}>
              <div className={classes.searchtabs}>
                <Typography
                  variant="h1"
                  align="center"
                  className={classes.mainTitle}
                >
                  <Trans i18nKey="homeTitle">
                    The world's largest artists database featuring 1.257.679
                    artists
                  </Trans>
                </Typography>
                {/* <SearchTabs close={(e) => {}} /> */}
                {/* <AdvancedSearch
                  variant={variant}
                  close={(e) => {}}
                  values={values}
                /> */}
                <AdvancedSearch isMobile={false} close={(e) => {}} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.sectionMobile}>
          <div className={classes.heroMobile}>
            <Typography
              variant="h2"
              align="center"
              className={classes.mainMobileTitle}
            >
              <Trans i18nKey="homeTitle">
                The world's largest artists database featuring 1.257.679 artists
              </Trans>
            </Typography>
          </div>
        </div>
        <div className={classes.sectionMobile}>
          <div className={classes.mobileSearchArea}>
            <AdvancedSearch isMobile={true} close={(e) => {}} />
          </div>
        </div>

        {/* <Hidden lgUp>
          <Box mdUp style={{ paddingBottom: "75px" }}></Box>
        </Hidden> */}

        <div className="main">
          <div className="homepage-container">{freeItemsBox}</div>

          {bannerPromiseInProgress ? (
            <Spinner show />
          ) : BannerCollection && !isObjEmpty(BannerCollection) ? (
            <Grid container>
              <Grid item md={6} className={classes.contentGridLeft}>
                <section className={classes.contentGridLeftSection}>
                  <Hidden lgDown>
                    <Box mdUp style={{ paddingBottom: "75px" }}></Box>
                  </Hidden>

                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    {BannerCollection?.collectionType === "template"
                      ? t("COLLECTION")
                      : BannerCollection?.collectionType === "free"
                      ? t("FREE ACCESS COLLECTION")
                      : null}
                  </Typography>
                  <Typography
                    variant="h1"
                    style={{
                      fontFamily: "Merriweather",
                      fontWeight: 400,
                      marginTop: "10px",
                    }}
                  >
                    {en
                      ? BannerCollection?.name
                      : BannerCollection?.nameDE || BannerCollection?.name}
                  </Typography>
                  <Hidden lgDown>
                    <Box mdUp style={{ paddingBottom: "75px" }}></Box>
                  </Hidden>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {en
                      ? BannerCollection?.description
                      : BannerCollection?.descriptionDE ||
                        BannerCollection?.description}
                  </Typography>
                  <div className={classes.collectionButton}>
                    <Link to={"/collection/" + BannerCollection?.id}>
                      <Button variant="contained" className="see-btn">
                        &nbsp; {t("See the collection")}
                        &nbsp;
                        <ArrowForwardIcon />
                      </Button>
                    </Link>
                  </div>
                </section>
              </Grid>
              <Grid item sm={12} md={6} className={classes.contentGridRight}>
                <Box style={{ height: "100%" }}>
                  <img
                    className={"image " + classes.bannerCollectionImg}
                    alt={t("Collection Image")}
                    src={BannerCollection?.imageURL}
                  />
                  <Typography
                    variant="body1"
                    align="right"
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      display: "block",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    {en
                      ? BannerCollection?.imageCredit
                      : BannerCollection?.imageCreditDE ||
                        BannerCollection?.imageCredit}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : null}
          <div className="homepage-container">
            {gridCollections && gridCollections.length > 0 ? (
              <Box className={classes.contentBox}>
                <Typography className={classes.title}>
                  {t("Explore")}
                </Typography>
                <Divider
                  variant="fullWidth"
                  className={classes.divider}
                  sx={{ borderBottomWidth: 2 }}
                />
                {collectionsPromiseInProgress ? (
                  <Spinner show />
                ) : (
                  <Box className={classes.tilesBox}>{gridCollections}</Box>
                )}
                <Link to="/collections">
                  <Button variant="contained" className="see-btn">
                    &nbsp;{t("See more collections")}&nbsp;
                    <ArrowForwardIcon />
                  </Button>
                </Link>
              </Box>
            ) : (
              <></>
            )}
            {recentOrUpdatedBox}

            {onThisDayBox}

            {/* <Divider
              variant="fullWidth"
              className={classes.divider}
              sx={{ borderBottomWidth: 2 }}
            /> */}
          </div>

          {/* <section className="section section-y"></section> */}
        </div>
        <OuterSpinner />
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
