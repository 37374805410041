import {
  Button,
  InputBase,
  InputLabel,
  Paper,
  withStyles,
} from "@material-ui/core";
import { Select } from "@mui/material";
import React, { createRef } from "react";
import { withTranslation } from "react-i18next";
import { SEARCH_TYPE } from "../../utils/Constants";
import { getSearchURL } from "../../utils/helper_functions";
import withRouter from "../../utils/hoc/withRouter";
import SearchButtonMobile from "../MobileTabs/SearchButtonMobile";
import SearchListMobile from "../MobileTabs/SearchListMobile";
import CustomDropDown from "./CustomDropDown";
import SearchButton from "./SearchButton";
import SearchList from "./SearchList";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  searchpaper: {
    marginBottom: "9px",
    display: "flex",
    alignItems: "center",
    width: 620,
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "0px",
  },
  searchbtn: {
    marginLeft: "43px !important",
  },
  input: {
    flex: 2,
    display: "block",
    // minHeight: "70px",
    paddingLeft: "22px",
    // clear: "left",
  },
  searchDiv: {
    display: "flex",
    width: "100%",
    height: "100%",
    "&:hover": {
      border: "1px solid #007596",
    },
    "&:focus-within": {
      backgroundColor: "#DDE9EE !important",
      border: "1px solid #007596;",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    },
  },
  searchInput: {
    padding: "10px",
    display: "block",
    width: "100%",
  },
  category: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "21px",
    },
  },
  button: { margin: "10px", border: "1px solid #007596" },
  formbtn: {
    "& button:hover": {
      backgroundColor: "#DDE9EE !important",
      // color: "#000000 !important",
      // "& svg path": {
      //   stroke: "#000000",
      // },
    },
  },
  textsearchlabel: {
    fontFamily: "Gotham-book",
    marginLeft: "20px",
    // padding: "10px",
    fontSize: "15px",
    // display:"block",
  },
  intInputs: {
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Gotham-Medium","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
    },
  },

  //CSS for mobile
  rowName: {
    fontSize: "17px",
    fontFamily: '"Gotham-Medium"',
    lineHeight: "25px",
    width: "100%",
    marginBottom: "20px",
  },
  rowContainer: {
    padding: "30px 37px 20px",
    maxWidth: "100%",
  },

  bottom: {
    position: "fixed",
    bottom: "0",
    backgroundColor: "#ffffff",
    // marginBottom: "20px",
    marginTop: "100px",
    width: "100%",
    height: "80px",
    padding: "12px 37px",
    left: 0,
    fontFamily: "'Gotham-Medium'!important",
  },
  categorytag: {
    lineHeight: "30px",
    fontFamily: '"Gotham-Book"',
    fontWeight: "700",
  },

  selectMob: {
    marginBottom: "10px",
    borderRadius: "0 !important",
  },
  inputMob: {
    padding: "10px",
    border: "0.5px solid #a4a4a4",
  },
  buttonMob: { marginTop: "20px", border: "1px solid rgba(32, 33, 34, 0.4)" },
});

class AdvancedSearch extends React.Component {
  state = {
    temp: {
      search: [
        {
          field: "ALL_FIELDS",
          value: "",
        },
      ],
    },
    searchDetails: [],
    intvalue: {},
  };

  constructor(props) {
    super(props);
    this.searchInputRef = createRef();
    this.clearSearch();
  }

  handleChange = (e, idx) => {
    console.log(e);
    if (["value"].includes(e.target.name)) {
      let newArr = [...this.state.searchDetails]; // copying the old datas array
      newArr[idx][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        searchDetails: newArr,
      }));
    } else if (["field" + idx].includes(e.target.name)) {
      let newArr = [...this.state.searchDetails]; // copying the old datas array
      newArr[idx]["field"] = e.target.value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        searchDetails: newArr,
      }));
    } else if (["searchQualifier" + idx].includes(e.target.name)) {
      let newArr = [...this.state.searchDetails]; // copying the old datas array
      newArr[idx]["searchQualifier"] = e.target.value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        searchDetails: newArr,
      }));
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  inputHandler = (e, id) => {
    if (["value"].includes(e.target.name)) {
      let name = e.target.name;
      let value = e.target.value;

      let newArr = { ...this.state.temp }; // copying the old datas array
      newArr.search[0][name] = value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        temp: newArr,
      }));
    } else if (["field" + id].includes(e.target.name)) {
      let name = "field";
      let value = e.target.value;

      let newArr = { ...this.state.temp }; // copying the old datas array
      newArr.search[0][name] = value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        temp: newArr,
      }));
    }
  };
  handleint = (e) => {
    if (["volume", "paragraph", "page", "line"].includes(e.target.name)) {
      let name = e.target.name;
      let value = e.target.value;

      let newArr = { ...this.state.intvalue }; // copying the old datas array
      // replace e.target.value with whatever you want to change it to
      newArr[name] = value;
      this.setState(() => ({
        intvalue: newArr,
      }));
    }
  };
  addNewRow = (e) => {
    let i = 0;
    if (
      Array.isArray(this.state.searchDetails) &&
      this.state.searchDetails.length
    ) {
      i = parseInt(
        this.state.searchDetails[this.state.searchDetails.length - 1].index + 1
      );
    }
    this.setState((prevState) => ({
      searchDetails: [
        ...prevState.searchDetails,
        {
          index: i,
          value: "",
          searchQualifier: "AND",
          field: "ALL_FIELDS",
        },
      ],
    }));
  };
  clearSearch = (e) => {
    this.setState({
      temp: {
        search: [
          {
            field: "ALL_FIELDS",
            value: "",
          },
        ],
      },
      searchDetails: [],
      intvalue: {},
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let sd = this.state.searchDetails;

    sd.map((el) => {
      delete el.index;
    });

    let formdata = this.state.temp;

    formdata.search.push(...sd);
    formdata.search = formdata.search.filter(function (element) {
      return element !== undefined;
    });

    if (
      !isNaN(parseInt(this.state.intvalue.volume)) &&
      parseInt(this.state.intvalue.volume) !== undefined &&
      parseInt(this.state.intvalue.volume) !== null
    ) {
      formdata.volume = parseInt(this.state.intvalue.volume);
    }
    if (
      !isNaN(parseInt(this.state.intvalue.paragraph)) &&
      parseInt(this.state.intvalue.paragraph) !== undefined &&
      parseInt(this.state.intvalue.paragraph) !== null
    ) {
      formdata.paragraph = parseInt(this.state.intvalue.paragraph);
    }
    if (
      !isNaN(parseInt(this.state.intvalue.page)) &&
      parseInt(this.state.intvalue.page) !== undefined &&
      parseInt(this.state.intvalue.page) !== null
    ) {
      formdata.page = parseInt(this.state.intvalue.page);
    }
    if (
      !isNaN(parseInt(this.state.intvalue.line)) &&
      parseInt(this.state.intvalue.line) !== undefined &&
      parseInt(this.state.intvalue.line) !== null
    ) {
      formdata.line = parseInt(this.state.intvalue.line);
    }
    let indexflag = true;
    formdata.search.map((el) => {
      if (el.field !== "INDEX_LIBRORUM") {
        indexflag = false;
      }
      return indexflag;
    });
    const query = getSearchURL(SEARCH_TYPE.ADVANCE_SEARCH, formdata);
    this.props.router.navigate({
      pathname: "/results",
      search: query,
    });
  };
  deteteRow = (index) => {
    this.setState({
      searchDetails: this.state.searchDetails.filter(
        (s, sindex) => index !== sindex
      ),
    });
  };

  clickOnDelete(record) {
    this.setState({
      searchDetails: this.state.searchDetails.filter((r) => r !== record),
    });
  }
  componentDidMount = () => {
    let searchData = this.state.temp;
    let searchDetailsData = [];
    let intvaluesData = this.state.intvalue;

    let propdata = this.props?.values;
    if (this.props?.values !== undefined && this.props?.value !== null) {
      if (
        propdata.search &&
        propdata.search.length &&
        propdata?.search[0]?.value !== undefined &&
        propdata?.search[0]?.value !== null
      ) {
        searchData.search[0].value = propdata.search[0].value;
      }
      if (
        propdata.search &&
        propdata.search.length &&
        propdata?.search[0]?.field !== undefined &&
        propdata?.search[0]?.field !== null
      ) {
        searchData.search[0].field = propdata.search[0].field;
      }
      if (
        propdata.search &&
        propdata.search.length &&
        propdata.search.length > 1
      ) {
        propdata.search.map((el, i) => {
          if (i > 0) {
            let tempData = {};
            tempData.index = i - 1;
            if (el.value !== undefined && el.value !== null) {
              tempData.value = el.value;
            }
            if (el.field !== undefined && el.field !== null) {
              tempData.field = el.field;
            }
            if (
              el.searchQualifier !== undefined &&
              el.searchQualifier !== null
            ) {
              tempData.searchQualifier = el.searchQualifier;
            }
            searchDetailsData.push(tempData);
          }
        });
      }
      if (propdata?.volume !== undefined && propdata?.volume !== null) {
        intvaluesData.volume = propdata.volume;
      }
      if (propdata?.paragraph !== undefined && propdata?.paragraph !== null) {
        intvaluesData.paragraph = propdata.paragraph;
      }
      if (propdata?.line !== undefined && propdata?.line !== null) {
        intvaluesData.line = propdata.line;
      }
      if (propdata?.page !== undefined && propdata?.page !== null) {
        intvaluesData.page = propdata.page;
      }
      this.setState({
        search: searchData,
        searchDetails: searchDetailsData,
        intvalue: intvaluesData,
      });
    }

    //focus the serach input
    this.searchInputRef?.current?.focus();
  };

  render() {
    const { t, i18n } = this.props;
    let { searchDetails } = this.state;

    const { classes } = this.props;
    let bgcolor = "#007596";
    if (this.props.variant === "dark") {
      bgcolor = "#3c3c3c";
    } else {
      bgcolor = "#007596";
    }
    const fvalue = ["NAME", "ALL_FIELDS", "AUTHOR", "COUNTRY", "OCCUPATION"];
    const fname = ["Title", "All fields", "Author", "Country", "Occupation"];

    const addRowButton = (
      <Button
        className={classes.buttonMob + " addrow gm text-unset"}
        style={{ color: "black" }}
        onClick={this.addNewRow}
        startIcon={
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2913 6.0212L5.63449 6.0212L5.63449 0.364381"
              stroke="black"
              strokeWidth="1.5"
            />
            <path
              d="M-0.000276499 6.00028L5.65653 6.00028L5.65653 11.6571"
              stroke="black"
              strokeWidth="1.5"
            />
          </svg>
        }
      >
        {t("Add Row")}
      </Button>
    );

    return (
      <div className="content">
        <form onSubmit={this.handleSubmit}>
          {this.props.isMobile ? (
            <>
              <div style={{ marginBottom: this.props.bottom ? "40px" : null }}>
                <div className={classes.rowContainer}>
                  <InputLabel
                    htmlFor="field"
                    shrink={true}
                    className={classes.categorytag}
                    style={{ color: "#000000" }}
                  >
                    {t("Field")}:
                  </InputLabel>

                  {/* <CustomDropDownMobile  id='field' name='field' row={55} title='Category' optionsList={fvalue}
                                  onChange={(e) => this.inputHandler(e, 55)}
                                  nameList={fname}
                                  defaultSelected={this.state.temp.search[0].field}
                              >

                    </CustomDropDownMobile> */}
                  <Select
                    native
                    id="field"
                    name="field55"
                    value={this.state.temp.search[0].field}
                    fullWidth
                    onChange={(e) => this.inputHandler(e, 55)}
                    className={classes.selectMob}
                  >
                    {fvalue.map((el, index) => {
                      return (
                        <option key={"op" + index} value={el}>
                          {t(fname[index])}
                        </option>
                      );
                    })}
                  </Select>

                  <InputLabel
                    htmlFor="field"
                    shrink={true}
                    className={classes.categorytag}
                    //style={{ color: "#000000" }}
                  >
                    {t("Search Term")}:
                  </InputLabel>

                  <InputBase
                    fullWidth
                    className={classes.inputMob}
                    name="value"
                    autoComplete="off"
                    value={this.state.temp.search[0].value}
                    onChange={(e) => this.inputHandler(e, 55)}
                    inputProps={{
                      "aria-label": "search term",
                      autoCapitalize: "none",
                    }}
                  />

                  {searchDetails.length < 1 ? addRowButton : null}
                </div>
                <SearchListMobile
                  delete={this.clickOnDelete.bind(this)}
                  searchDetails={searchDetails}
                  variant={this.props.variant}
                  handleChange={this.handleChange.bind(this)}
                  close={this.props.close}
                  addRowButton={addRowButton}
                />
              </div>
              <div className={classes.rowContainer}>
                <div
                  className={
                    this.props.bottom ? classes.bottom : "mobile-search-btn"
                  }
                  style={{
                    marginTop: "0px",
                  }}
                >
                  <SearchButtonMobile close={this.props.close} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Paper elevation={0} className={classes.searchpaper}>
                <CustomDropDown
                  label={t("Field")}
                  name="field"
                  row={this.props.isNavbar ? 5 : 55}
                  title={t("Category")}
                  optionsList={fvalue}
                  onChange={(e) =>
                    this.inputHandler(e, this.props.isNavbar ? 5 : 55)
                  }
                  nameList={fname}
                  defaultSelected={this.state.temp.search[0].field}
                ></CustomDropDown>

                <div className={classes.searchDiv}>
                  <div className={classes.searchInput}>
                    <label className={classes.textsearchlabel}>
                      {t("Search Term")}
                    </label>
                    <InputBase
                      inputRef={this.searchInputRef}
                      className={classes.input}
                      name="value"
                      autoComplete="off"
                      //placeholder={t("Search Term")}
                      value={this.state.temp.search[0].value}
                      onChange={(e) => this.inputHandler(e, 55)}
                      inputProps={
                        {
                          //"aria-label": "search term",
                        }
                      }
                    />
                  </div>
                  {searchDetails.length === 0 ? (
                    <div className={classes.searchbtn1 + " searchbtn"}>
                      <SearchButton close={this.props.close} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Paper>

              <SearchList
                delete={this.clickOnDelete.bind(this)}
                searchDetails={searchDetails}
                variant={this.props.variant}
                handleChange={this.handleChange.bind(this)}
                close={this.props.close}
              />

              <div className={classes.formbtn}>
                <Button
                  variant="outlined"
                  className={classes.button + " addrow gm text-unset"}
                  style={{ color: bgcolor }}
                  onClick={this.addNewRow}
                  startIcon={
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2913 6.0212L5.63449 6.0212L5.63449 0.364381"
                        stroke={bgcolor}
                        strokeWidth="1.5"
                      />
                      <path
                        d="M-0.000276499 6.00028L5.65653 6.00028L5.65653 11.6571"
                        stroke={bgcolor}
                        strokeWidth="1.5"
                      />
                    </svg>
                  }
                >
                  {t("Add Row")}
                </Button>
                <Button
                  variant="outlined"
                  className={classes.button + " btn addrow gm"}
                  style={{ color: bgcolor }}
                  onClick={this.clearSearch}
                  startIcon={
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.96872 9L4.96875 5.00003L8.96872 1.00006"
                        stroke={bgcolor}
                        strokeWidth="1.5"
                      />
                      <path
                        d="M1.00003 1L5 4.99997L1.00003 8.99994"
                        stroke={bgcolor}
                        strokeWidth="1.5"
                      />
                    </svg>
                  }
                >
                  {t("Clear search")}
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    );
  }
}
export default withRouter(
  withTranslation()(withStyles(useStyles)(AdvancedSearch))
);
