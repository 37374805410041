import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Logo from "./images/logo-white.png";
import { Trans, useTranslation } from "react-i18next";
import { checkIfLanguageIs } from "../utils/helper_functions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    color: "#ffffff",
    backgroundColor: "#000000",
    [theme.breakpoints.up("md")]: {
      paddingRight: "90px",
      paddingLeft: "90px",
      padding: "15px",
    },
  },
  designcontainer: {
    padding: "10px",
    display: "flex",
    color: "#ffffff",
    backgroundColor: "#000000",
    [theme.breakpoints.up("md")]: {},
  },
  end: {
    display: "flex",
    marginBottom: "60px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      marginBottom: "0",
    },
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  footerlogo: {
    "& img": {
      width: "192px",
    },
    marginBottom: "30px",
  },
  footerlinks: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  flinks: {
    fontSize: "24px",
    lineHeight: "50px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      fontSize: "20px",
      lineHeight: "30px",
      marginRight: "30px",
    },
  },
}));

function Footer() {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container
        maxWidth={false}
        className={classes.container}
        // style={{ position: "absolute", bottom: "0", width: "100%" }}
      >
        <div className={classes.sectionMobile}>
          <div className={classes.footerlogo}>
            <NavLink to="/">
              <img src={Logo} alt="logo" />
            </NavLink>
          </div>
        </div>
        <Grid container className={classes.footercontainer} spacing={2}>
          <Grid item xs={12} md={6} className={classes.footerlinks}>
            <Typography className={classes.flinks}>
              <Trans i18nKey="contact">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/contacts?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Contact
                </a>
              </Trans>
            </Typography>
            <Typography className={classes.flinks}>
              <Trans i18nKey="privacy">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/privacy-policy?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Trans>
            </Typography>
            <Typography className={classes.flinks}>
              <span dangerouslySetInnerHTML={{ __html: t("cookiePolicy") }} />
            </Typography>
            <Typography className={classes.flinks}>
              <Trans i18nKey="termsCondition">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/terms-and-conditions?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Terms &amp; Conditions
                </a>
              </Trans>
            </Typography>
            <Typography className={classes.flinks}>
              <Trans i18nKey="imprint">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/imprint?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Imprint
                </a>
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.end}>
            <Typography variant="h5" align="center">
              © Walter de Gruyter GmbH {new Date().getFullYear() + " "}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {/* <Container maxWidth={false} className={classes.designcontainer}>
        <Grid container className={classes.footercontainer} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className="gb" align="center">
              {t("Created by") + " "}
              <a
                href="http://northerncomfort.co.uk"
                target="_blank"
                rel="noreferrer"
                color="inherit"
              >
                Northern Comfort
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container> */}
    </section>
  );
}

export default Footer;
