import { Button, Divider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useTranslation, Trans } from "react-i18next";
import AppliedFilter from "./AppliedFilter";
import { isNotNullOrEmptyStr } from "../../utils/helper_functions";
const useStyles = makeStyles({
  filterTitle: {
    marginBottom: "10px !important",
    fontFamily: "Gotham-Book,tllmedium,Times !important",
  },
  topDiv: { marginBottom: "20px !important" },
  clearButton: {
    marginLeft: "10px !important",
    marginBottom: "10px !important",
    color: "#007596 !important",
    "&:hover": {
      backgroundColor: "#DDE9EE !important",
    },
  },
});

const AppliedFilters = ({
  appliedFilters,
  handleFilterRemove,
  clearAllFilters,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let areFiltersApplied = false;
  let dobValue = null;

  Object.keys(appliedFilters).forEach((key) => {
    if (key !== "dob" && appliedFilters[key].length !== 0) {
      areFiltersApplied = true;
    }
    if (
      key === "dob" &&
      (isNotNullOrEmptyStr(appliedFilters[key].from) ||
        isNotNullOrEmptyStr(appliedFilters[key].to))
    ) {
      areFiltersApplied = true;
      dobValue =
        appliedFilters[key].from +
        (appliedFilters[key].from !== appliedFilters[key].to
          ? "-" + appliedFilters[key].to
          : "");
    }
  });

  return areFiltersApplied ? (
    <div className={classes.topDiv}>
      <Typography className={classes.filterTitle}>
        {t("Applied filters")}:
      </Typography>
      {appliedFilters.country.map((value, i) => (
        <AppliedFilter
          key={"AppliedFilter-Country-" + i}
          name={t("Country")}
          value={value}
          handleFilterRemove={() => handleFilterRemove("country", value)}
        />
      ))}
      {appliedFilters.occupation.map((value, i) => (
        <AppliedFilter
          key={"AppliedFilter-Occupation-" + i}
          name={t("Occupation")}
          value={value}
          handleFilterRemove={() => handleFilterRemove("occupation", value)}
        />
      ))}
      {appliedFilters.place.map((value, i) => (
        <AppliedFilter
          key={"AppliedFilter-Place-" + i}
          name={t("Place")}
          value={value}
          handleFilterRemove={() => handleFilterRemove("place", value)}
        />
      ))}
      {dobValue ? (
        <AppliedFilter
          key={"AppliedFilter-dob"}
          name={t("Year of Birth")}
          value={dobValue}
          handleFilterRemove={() => handleFilterRemove("dob")}
        />
      ) : null}
      {appliedFilters.gender.map((value, i) => (
        <AppliedFilter
          key={"AppliedFilter-Gender-" + i}
          name={t("Gender")}
          value={value}
          handleFilterRemove={() => handleFilterRemove("gender", value)}
        />
      ))}
      {appliedFilters.entryType.map((value, i) => (
        <AppliedFilter
          key={"AppliedFilter-EntryType-" + i}
          name={t("Entry Type")}
          value={value}
          handleFilterRemove={() => handleFilterRemove("entryType", value)}
        />
      ))}
      {appliedFilters.accessType.map((value, i) => (
        <AppliedFilter
          key={"AppliedFilter-AccessType-" + i}
          name={t("Access")}
          value={t("Publicly available")}
          handleFilterRemove={() => handleFilterRemove("accessType", value)}
        />
      ))}
      <Button
        className={classes.clearButton + " gm"}
        onClick={clearAllFilters()}
      >
        {t("Clear all filters")}
      </Button>
    </div>
  ) : null;
};
export default AppliedFilters;
