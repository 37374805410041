import React from "react";
import { useTranslation } from "react-i18next";
import { DGLogoHTML } from "../DGLogo/DGLogo";
import { Close } from "@mui/icons-material";

export const PDFLoader = ({ loaded }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {DGLogoHTML(72)}
          {!loaded ? t("Downloading PDF") : t("Download complete")}
        </div>
        {loaded && (
          <>
            <a
              onClick={() => window.close()}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.1rem",
                color: "#007596",
              }}
            >
              <Close></Close>
              {t("Close tab")}
            </a>
          </>
        )}
      </div>
    </div>
  );
};
