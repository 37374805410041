import { useEffect, useState } from "react";
import { isNotNullOrUndefined } from "../helper_functions";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  var token = localStorage.getItem("jwtToken");
  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);
      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );
    }
  }, [token]);

  const logout = () => {
    // unencode jwtToken and get the "links" object
    // get the logout link
    const logoutLink = localStorage.getItem("AOW_logout_link");
    // call the logout link
    if (logoutLink) {
      // if we're on https, we need to call the logout link with https
      // if we're on http, we need to call the logout link with http

      const protocol = window.location.protocol;
      const logoutLinkWithoutProtocol = logoutLink.split("://")[1];

      const logoutLinkWithProtocol = `${protocol}//${logoutLinkWithoutProtocol}`;

      try {
        fetch(logoutLinkWithProtocol, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }

    localStorage.removeItem("jwtToken");
    localStorage.removeItem("user_name");
    localStorage.removeItem("AOW_logout_link");
    localStorage.removeItem("isAuthorized");
    localStorage.removeItem("aid");
    localStorage.removeItem("aname");
    setIsLoggedIn(false);
    setIsUserAuthorized(false);
  };

  return { isLoggedIn, isUserAuthorized, logout };
};

export default useAuth;
