import { Divider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useTranslation, Trans } from "react-i18next";
import FilterItem from "./FilterItem";
const useStyles = makeStyles({
  filterTitle: {
    marginBottom: "30px !important",
    fontWeight: "600 !important",
    fontFamily: "Gotham-Book,tllmedium,Times !important",
  },
  divider: { marginTop: "15px !important", marginBottom: "15px !important" },
});

const SearchFilter = ({
  data,
  appliedFilters,
  handleFilterToggle,
  applyDOBFilter,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.filterTitle}>
        {t("Filter your search")}:
      </Typography>
      {/* <Divider className={classes.divider} /> */}

      {/* <>
        <FilterItem title="Country" />
        <Divider className={classes.divider} />
      </> */}
      {data?.countries?.length === 0 ? null : (
        <FilterItem
          id="country"
          filterChecked={appliedFilters.country}
          handleFilterToggle={handleFilterToggle}
          title={t("Country")}
          values={data?.countries}
        />
      )}
      {data?.occupations?.length === 0 ? null : (
        <FilterItem
          id="occupation"
          filterChecked={appliedFilters.occupation}
          handleFilterToggle={handleFilterToggle}
          title={t("Occupation")}
          values={data?.occupations}
        />
      )}
      {data?.places?.length === 0 ? null : (
        <FilterItem
          id="place"
          filterChecked={appliedFilters.place}
          handleFilterToggle={handleFilterToggle}
          title={t("Place")}
          values={data?.places}
        />
      )}
      {data?.dateOfBirthAggregation?.from === "Infinity" &&
      data?.dateOfBirthAggregation?.to === "-Infinity" ? null : (
        <FilterItem
          id="dob"
          appliedRange={appliedFilters?.dob}
          handleFilterToggle={handleFilterToggle}
          title={t("Year of Birth")}
          range={data?.dateOfBirthAggregation}
          applyDOBFilter={applyDOBFilter}
        />
      )}
      {data?.gender?.length === 0 ? null : (
        <FilterItem
          id="gender"
          filterChecked={appliedFilters.gender}
          handleFilterToggle={handleFilterToggle}
          title={t("Gender")}
          values={data?.gender}
        />
      )}
      {data?.entryType?.length === 0 ? null : (
        <FilterItem
          id="entryType"
          filterChecked={appliedFilters.entryType}
          handleFilterToggle={handleFilterToggle}
          title={t("Entry Type")}
          values={data?.entryType}
        />
      )}
      {data && (
        <FilterItem
          id="accessType"
          filterChecked={appliedFilters.accessType}
          handleFilterToggle={handleFilterToggle}
          title={t("Access")}
        />
      )}
    </>
  );
};
export default SearchFilter;
