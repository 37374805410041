import React from "react";
import { Svg, Path } from "@react-pdf/renderer";

export const DGLogoPDF = (size) => {
  return (
    <Svg
      width={size / 2}
      height={size}
      viewBox="0 0 276 552"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path d="M0 0V551.8H275.9V0H0Z" fill="black" />
      <Path d="M69 200.1V213.7H206.9V200.1H69Z" fill="white" />
      <Path
        d="M207.4 137.9V124.4H170V110H202.5V96.5H170V82.5H207V69H155V138H207.4V137.9ZM117.2 103.7C117.2 115.9 108.8 124.3 96.4 124.3H84.7V82.7H96.4C108.8 82.7 117.2 91.3 117.2 103.5V103.7ZM133.1 103.5V103.3C133.1 83.9 118.1 69 96.4 69H69.5V138H96.4C118.1 137.9 133.1 122.9 133.1 103.5Z"
        fill="white"
      />
      <Path
        d="M181.2 386.4V444.1C173.6 448.6 159.4 454.6 141.8 454.6C102.2 454.6 86.7 430.2 86.7 369.1C86.7 308 103 283.8 142.1 283.5C159.8 283.6 170.4 287.5 179.6 295.4C187.2 304.2 195.9 315.9 199.9 330.8H203.6V280.3H199.4C198.6 282.3 197.7 283.6 196.7 284.4C195.7 285.1 194.1 285.5 191.8 285.5C189.6 285.5 187 285 183.9 284C180.8 283 177.2 282 173.3 280.9C169.3 279.8 165 278.8 160.4 277.8C158.3 277.3 156.1 277 153.9 276.8C150.1 276.2 146.2 275.8 141.9 275.9C92.3 276.2 50.8 319.2 50.8 369.8C50.8 421.7 92.3 462.2 141.9 462.2C172.9 462.2 202.8 443.3 212.2 429.1V386.4H235.8V379.4H149.5V386.4H181.2Z"
        fill="white"
      />
    </Svg>
  );
};

export const DGLogoHTML = (size) => {
  return (
    <svg width={size / 2} height={size} viewBox="0 0 276 552" fill="none">
      <path d="M0 0V551.8H275.9V0H0Z" fill="black" />
      <path d="M69 200.1V213.7H206.9V200.1H69Z" fill="white" />
      <path
        d="M207.4 137.9V124.4H170V110H202.5V96.5H170V82.5H207V69H155V138H207.4V137.9ZM117.2 103.7C117.2 115.9 108.8 124.3 96.4 124.3H84.7V82.7H96.4C108.8 82.7 117.2 91.3 117.2 103.5V103.7ZM133.1 103.5V103.3C133.1 83.9 118.1 69 96.4 69H69.5V138H96.4C118.1 137.9 133.1 122.9 133.1 103.5Z"
        fill="white"
      />
      <path
        d="M181.2 386.4V444.1C173.6 448.6 159.4 454.6 141.8 454.6C102.2 454.6 86.7 430.2 86.7 369.1C86.7 308 103 283.8 142.1 283.5C159.8 283.6 170.4 287.5 179.6 295.4C187.2 304.2 195.9 315.9 199.9 330.8H203.6V280.3H199.4C198.6 282.3 197.7 283.6 196.7 284.4C195.7 285.1 194.1 285.5 191.8 285.5C189.6 285.5 187 285 183.9 284C180.8 283 177.2 282 173.3 280.9C169.3 279.8 165 278.8 160.4 277.8C158.3 277.3 156.1 277 153.9 276.8C150.1 276.2 146.2 275.8 141.9 275.9C92.3 276.2 50.8 319.2 50.8 369.8C50.8 421.7 92.3 462.2 141.9 462.2C172.9 462.2 202.8 443.3 212.2 429.1V386.4H235.8V379.4H149.5V386.4H181.2Z"
        fill="white"
      />
    </svg>
  );
};
