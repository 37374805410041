import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import * as constants from "../utils/Constants";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import jsesc from "jsesc";
import { ReactSession } from "react-client-session";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ArtistName from "../components/ArtistName";
import BackToTop from "../components/BackToTop";
import ConnectedPlaces from "../components/ConnectedPlaces";
import PayWall from "../components/Paywall";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { Spinner } from "../components/Spinner/Spinner";
import Toast from "../components/Toast";
import commonStyles from "../utils/commonStyles";
import {
  capitalizeStr,
  formateDate,
  getSearchFiltersFromQuery,
  isNotNullOrEmptyArr,
  isNotNullOrEmptyStr,
  isNotNullOrUndefined,
  isObjEmpty,
  prepareSearchFormListForHighlight,
} from "../utils/helper_functions";
import useFindOnPage from "../utils/hooks/useFindOnPage";
import { useInitialRender } from "../utils/hooks/useInitialRender";

import DownloadButton from "../components/Buttons/DownloadButton";
import ShareButton from "../components/Buttons/ShareButton";
import CiteButton from "../components/Buttons/CiteButton";
import { bodyOldFormat, contentTableOldFormat } from "./bodyOldFormat";
import { BodyNewFormat, ContentTable } from "./bodyNewFormat";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  toparea: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "50px",
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  titlearea: {
    marginBottom: "10px",
  },
  ovdataTitle: {
    marginTop: "16px",
  },
  results: {
    lineHeight: "40px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      lineHeight: "50px",
    },
  },
  findpaper: {
    width: "100%",
    padding: "2px 3px 2px 25px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-around",
    border: "0.5px solid #A4A4A4",
    [theme.breakpoints.up("md")]: {
      minWidth: "355px",
      maxWidth: "355px",
    },
  },
  findpapermobile: {
    width: "100%",
    padding: "12px 20px 13px 25px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff",
    border: "0.5px solid #A4A4A4",
  },
  icons: {
    borderLeft: "1px solid #dddddd",
    borderRadius: "0",
  },
  icon: {
    width: "39px",
    height: "39px",
    backgroundColor: "#005d781a !important",
    marginLeft: "3px!important",
  },
  artistDetail: {
    fontFamily: "Gotham-book",
    fontSize: "16px",
    marginTop: "5px",
  },
  artistInfo: {
    fontFamily: "Gotham-book",
    fontSize: "16px",
    // display: "flex",
    "& > *": {
      display: "inline-block",
    },
  },
  lineNumberToggleButton: {
    height: "45px",
    marginRight: "15px",
    width: "153px !important",
    textTransform: "initial !important",
  },
  // abtn: {
  //     fontFamily: ['Gotham'],
  //     fontSize: '16px',
  //     margin: '4.5px 0px',
  //     '&.MuiButtonGroup-grouped': {
  //         minWidth: '30px',
  //         minHeight: '30px',
  //         maxWidth: '30px',
  //         maxHeight: '30px',
  //     },
  //     '&.MuiButtonGroup-groupedTextPrimary:not(:last-child)': {
  //         border: 'none!important',
  //     },
  // },
  // alphabetBtn: {
  //     display: 'flex',
  //     flexDirection: 'column',
  // },
  resultpaper: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    padding: "20px",
  },
  btnarea: {
    display: "flex",
    marginBottom: "40px",
  },
  btn: {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50px",
      maxHeight: "50px",
      minWidth: "50px",
      minHeight: "50px",
    },

    "& button.iconButton": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
      minWidth: "100% !important",
      minHeight: "100% !important",
    },
  },
  displayContent: {
    marginTop: "25px",
  },

  btnareamobile: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "60px",
  },
  btnmobile: {
    marginRight: "10px",
    border: "1px solid #a4a4a4",
    minWidth: "50px",
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nav: {
    marginBottom: "60px",
  },
  sectionMain: {
    marginTop: "30px",
    wordWrap: "break-word",
  },
  input: {
    marginRight: "10px",
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Merriweather","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
      opacity: "unset!important",
      textOverflow: "ellipsis",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
    },
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  overviewDataDiv: { marginTop: "60px" },
  contentDiv: { marginTop: "60px" },
  navigate: {
    display: "flex",
    height: "45px",
    "& button": {
      height: "100%",
      minWidth: "170px",
    },
  },
  backbtn: {
    marginRight: "10px",
  },
  navigationIcon: {
    marginTop: "-1px",
  },
  mobileFindOnPage: {
    marginTop: "30px",
    marginBottom: "30px",
    height: "55px",
  },
  mobileFindOnPageSticky: {
    position: "sticky",
    top: "70px",
    left: "0px",
    display: "inline-block",
    zIndex: "99999",
  },
  buttonGroup: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    display: "flex",
    width: "100%",
    "& .iconButton": {
      minHeight: "50px",
      minWidth: "50px",
      border: "1px solid #005D78",
      background: "#E5EFF1",
    },
    "& .iconButton svg": {
      height: "50px",
    },
  },
  bottomStickyGroup: {
    position: "sticky",
    top: "90%",
  },
  imgbox: {
    height: "50%",
  },
  bottomStickyGroupDiv: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "flex-end",
    transition: "opacity 0.4s",
  },
  hidden: {
    opacity: "0",
  },
  mesBox: {
    justifyContent: "center",
    margin: "65px",
    padding: "65px",
    display: "flex",
  },
  switchclick: {
    backgroundColor: "#00495D !important",
  },
  mainTitle: {
    display: "block",
    fontWeight: "600",
    fontSize: "26px",
    marginBottom: "30px",
    // float: "right",
  },
  mainTitle1: {
    color: "#007596",
  },
  tableTextContent: {
    display: "flex",
    marginBottom: "15px",
    width: "100%",
  },
  switchContent: {
    justifyContent: "flex-end !important",
  },
  textExpand: {
    color: "#007596",
    display: "none",
    textAlign: "right !important",
    borderBottom: "0px solid white !important",
    justifyContent: "flex-start !important",
    // width: "120%",
    alignItems: "left",
    "& svg": {
      display: "none",
      justifyContent: "flex-start",
    },
  },
  exaAbbr: {
    // width: "24%",
    color: "#007596",
  },
  subTitle: {
    display: "block",
    fontWeight: "500",
    fontSize: "20px",
    width: "80%",
  },
  boxSubTitle: {
    color: "#007596",
    marginLeft: "18px",
    // "&  a": {
    //   padding: "10px !important",
    //   color: "#007596",
    // },
  },
  ContentTitle: {
    fontFamily: "Gotham-Medium",
  },
  mainBox: {
    width: "100%",
    color: "#007596",
    fontFamily: "Gotham-book",
  },
  boxDesign: {
    background: "rgba(0, 0, 0, 0.03)",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box",
    padding: "10px",
    lineHeight: "27px",
    fontWeight: "400",
    fontSize: "15px",
    // height: "400px",
  },
  content: {
    "& > p": {
      fontFamily: "times",
      fontSize: "18px",
      lineHeight: "26px",
    },
    "& > span": {
      fontFamily: "times",
      fontSize: "18px",
      lineHeight: "26px",
    },
  },
  dialogContainer: {
    width: "100% !important",
  },
  dialogPaper: {
    maxWidth: "780px !important",
    borderRadius: "0px !important",
  },
  paddingHorizontal0: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  body: {
    paddingBottom: "30px",
  },
  format: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "30px",
  },
  clipboardArea: {
    // border: "0.5px solid #717173",
    overflowWrap: "anywhere",
    wordBreak: "break-word",
    [theme.breakpoints.up("sm")]: {
      // padding: "20px",
    },
    "& *": {
      display: "inline !important",
    },
  },
  clipboardAreaText: {
    fontFamily: "Times",
    fontSize: "18px !important",
    lineHeight: "30px !important",
  },
}));

function Artist(props) {
  const { t, i18n } = useTranslation();
  const isInitialRender = useInitialRender();
  const currentUrl = new URL(window.location.href);
  const currentUrlParams = currentUrl.searchParams;
  const [showLineNumbers, setShowLineNumbers] = useState(false);
  const [tabDataLoaded, setTabDataLoaded] = useState([]);
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);

  var token = localStorage.getItem("jwtToken");
  const currentIndex = currentUrlParams.get("currentIndex");
  const { fileName } = useParams();
  let location = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  const locationHash = location.hash;
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const searchURL = useRef(currentUrlParams.get("search_url"));
  const mobileFindOnPageRef = useRef(null);
  const { alphabetSearch, searchForm } = getSearchFiltersFromQuery(
    new URLSearchParams(decodeURIComponent(searchURL.current ?? ""))
  );
  const entryTabsRef = useRef(null);

  const createSortHref = () => {
    const sortParams = new URLSearchParams();

    if (searchURL.current) {
      sortParams.set("search_url", searchURL.current);
    }

    return sortParams.toString();
  };

  const login = currentUrlParams.get("login");
  const [loginSnack, setLoginSnack] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      setLoginSnack(true);
      // remove success=true from url
      const url = new URL(window.location.href);
      url.searchParams.delete("login");
      window.history.replaceState({}, "", url);
    }
  };

  // const nextId = currentUrlParams.get("nextPackageFileName");
  // const prevId = currentUrlParams.get("prevPackageFileName");

  // const history = useHistory();
  const navigate = useNavigate();
  const [entry, setEntry] = React.useState({});
  const [citeData, setCiteData] = React.useState();
  const [currentTab, setCurrentTab] = useState(constants.EntryTabs.LEMMA_TAB);
  // const [SwitchChecked, setSwitchChecked] = useState({});

  // const switchClick = (e, name) => {
  //   let newSwitchChecked = { ...SwitchChecked };

  //   newSwitchChecked[name] = e.target.checked;
  //   setSwitchChecked(newSwitchChecked);
  // };

  // current url with /pdf added after /artist
  const pdfUrl = currentUrl.href.replace("/artist", "/artist/pdf");

  const tableData = ["Werke", "Ausstellungen", "Bibliographie"];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let dialogPadding = fullScreen ? "15px" : "40px";

  // liblynx counter
  function getCounterAnalytics() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    if (isNotNullOrUndefined(localStorage.getItem("jwtToken"))) {
      const aid = localStorage.getItem("aid") ?? "";
      const aname = localStorage.getItem("aname") ?? "";

      // don't track unauthenticated users
      // return an empty script if aid is not present
      if (aid === "") return script;

      if (
        isNotNullOrUndefined(localStorage.getItem("isAuthorized")) &&
        localStorage.getItem("isAuthorized") === "true"
      ) {
        script.innerHTML = `var _ll = _ll || [];
          _ll.push(["trackItemRequest", {
          ${
            localStorage.getItem("aid")
              ? `"aid": '${aid}',
                 "aname": '${aname}',`
              : "anon:true,"
          }
          "url": '${window.location.href}',
          "sid": '${ReactSession.get("sid")}',
          "at": "controlled",
          "am": "regular",
          "im": {
            "title": '${jsesc(entry?.title)}',
            "dtype": "article",
            "id": 'AOW_${fileName ?? ""}',
          }
          }]);
        `;
      } else {
        script.innerHTML = `var _ll = _ll || [];
          _ll.push(["trackDenial", {
          ${
            localStorage.getItem("aid")
              ? `"aid": '${aid}',
                 "aname": '${aname}',`
              : "anon:true,"
          }
          "sid": '${ReactSession.get("sid")}',
          "im": {
            "title": '${jsesc(entry?.title)}',
            "dtype": "article",
            "id": 'AOW_${fileName ?? ""}',
          },
          "deny": 'No_License'
          }]);
        `;
      }
    }
    return script;
  }

  const navigateToValidate = () => {
    navigate({
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);
      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );
    }

    let script = null;
    if (!isInitialRender) {
      script = getCounterAnalytics();
      document.body.appendChild(script);
    }

    return () => {
      if (script !== null) document.body.removeChild(script);
    };
  }, [token]);

  useEffect(() => {
    checklogin();
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);

      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );

      setTabDataLoaded(() => []);
    }
  }, [fileName]);

  useEffect(() => {
    let script = null;
    if (entry?.title) {
      script = getCounterAnalytics();
      document.body.appendChild(script);
    }

    return () => {
      if (script !== null) document.body.removeChild(script);
    };
  }, [entry?.title]);

  const [isNewFormat, setIsNewFormat] = useState(undefined);

  useEffect(() => {
    if (entry?.body === undefined) return;
    if (entry.body === "AOW-2023") {
      setIsNewFormat(true);
    } else {
      setIsNewFormat(false);
    }
  }, [entry]);

  const [ldata, setLdata] = useState();
  const [contentTable, setContentTable] = useState();

  useEffect(() => {
    if (isNewFormat === undefined) return;
    if (entry === undefined) return;

    if (isNewFormat) {
      setLdata(
        <BodyNewFormat classes={classes} entry={entry} tableData={tableData} />
      );
      setContentTable(<ContentTable classes={classes} entry={entry} />);
    } else {
      setLdata(bodyOldFormat(classes, entry, tableData, t));
      setContentTable(contentTableOldFormat(classes, entry, t));
    }
  }, [entry, isNewFormat]);

  const canGoBackToSearchResult = () => {
    if (searchURL?.current && searchURL.current.length) {
      return true;
    }
    return false;
  };

  const loadData = () => {
    // const apiUrlSearchParams = new URLSearchParams();
    // const nextRequired = nextId === null || nextId === undefined;
    // if (nextRequired && !tabDataLoaded?.length) {
    //   apiUrlSearchParams.append("nextRequired", true);
    // }

    // const prevRequired = prevId === null || prevId === undefined;
    // if (prevRequired && !tabDataLoaded?.length) {
    //   apiUrlSearchParams.append("prevRequired", true);
    // }
    // if (
    //   currentIndex !== null &&
    //   currentIndex !== undefined &&
    //   currentIndex !== "null"
    // ) {
    //   apiUrlSearchParams.append("currentIndex", currentIndex);
    // }

    const tempCurrentTab = currentTab; // TODO: If tab changes in between API call;

    mobileFindOnPageRef.current?.searchValue("");

    trackPromise(
      axios
        .get(process.env.REACT_APP_SERVER_HOST_NAME + `/book/${fileName}`)
        .then((response) => {
          const uParams = new URLSearchParams();
          if (currentIndex && currentIndex !== "null")
            uParams.append("currentIndex", currentIndex);

          if (tabDataLoaded.length === 0) {
            const url =
              "/artist/" +
              fileName +
              "?" +
              uParams.toString() +
              "&" +
              createSortHref() +
              locationHash;

            navigate(url, { replace: true });

            // remove name variations with empty names
            response.data.nameVariations = response.data.nameVariations?.filter(
              (n) => n.name?.trim()?.length > 0
            );

            setEntry(response.data);
          } else {
            setEntry((currentEntry) => ({
              ...currentEntry,
              fullXML: response.data?.fullXML?.length
                ? response.data?.fullXML
                : currentEntry?.fullXML,
              newCitList: response.data?.newCitList?.length
                ? response.data?.newCitList
                : currentEntry?.newCitList,
            }));
          }
          setTabDataLoaded((prevData) => [...prevData, tempCurrentTab]);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigateToValidate();
          }
        })
    );

    const citationUrl =
      process.env.REACT_APP_SERVER_HOST_NAME + `/book/${fileName}/cite`;

    axios
      .get(citationUrl)
      .then((response) => {
        setCiteData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const lemmaprop = {
  //   search: [{ value: "" }],
  // };
  // lemmaprop.search[0].value = entry?.lemma ? entry.lemma[0] : "";

  let keywords = i18n.language === "de" ? entry?.deKeywords : entry?.enKeywords;
  const isEnglish = i18n.language === "en";

  const backToResults = () => {
    // history.push(decodeURIComponent(searchURL?.current));
    navigate(decodeURIComponent(searchURL?.current));
  };

  const [bodyFindString] = useFindOnPage(
    "permanentHighlight",
    ".lemma-search-div",
    true
  );

  const [citeFindString] = useFindOnPage(
    "permanentHighlight",
    ".cit-search-div",
    true
  );

  //TODO: was this copy-pasted from TLL?
  // To mark search result query
  // search after data gets loaded
  useEffect(() => {
    if (searchForm && searchForm.search) {
      const searchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "FULL_TEXT")
      );
      const citSearchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "CITATION")
      );
      if (currentTab === constants.EntryTabs.LEMMA_TAB) {
        bodyFindString(searchValues, false, true);
      } else if (currentTab === constants.EntryTabs.CITE_TAB) {
        citeFindString(citSearchValues, false, true);
      }
    }
    if (locationHash && locationHash.length) {
      const targetElement = document.getElementById(locationHash.substring(1));
      targetElement?.scrollIntoView();
    }
  }, [entry]);

  const [gndIDs, setGndIDs] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [familiesData, setFamiliesData] = useState([]);

  useEffect(() => {
    if (!entry) return;

    const gndIDs = entry?.entries?.filter(
      (e) => e.type && e.type.toLowerCase() === "gnd"
    );
    setGndIDs(gndIDs ?? []);

    setGroupsData(
      entry?.entries?.filter(
        (e) => e.type && e.type.toLowerCase() === "group"
      ) ?? []
    );

    setFamiliesData(
      entry?.entries?.filter(
        (e) => e.type && e.type.toLowerCase() === "family"
      ) ?? []
    );
  }, [entry]);

  useEffect(() => {
    if (!tabDataLoaded.length) {
      const searchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "FULL_TEXT")
      );

      const citSearchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "CITATION")
      );

      if (citSearchValues.length && !searchValues.length) {
        // load cite only
        if (currentTab === constants.EntryTabs.CITE_TAB) {
          loadData();
        } else if (entryTabsRef?.current?.changeTab) {
          entryTabsRef.current.changeTab(constants.EntryTabs.CITE_TAB);
        }
      } else {
        loadData();
      }
    } else if (!tabDataLoaded.includes(currentTab)) {
      loadData();
    }
  }, [currentTab, tabDataLoaded?.length]);

  const [ConnectedPeople, setConnectedPeople] = useState([]);
  useEffect(() => {
    if (isInitialRender || promiseInProgress) return;

    // try {
    //   const response = await axios.get(
    //     process.env.REACT_APP_SERVER_HOST_NAME +
    //     `/search/connectedPeople?id=${fileName}${entry?.title ? "&name=" + entry?.title : ""
    //     }`
    //   ).toPromise()
    //   if (response?.status === 200) {
    //     setConnectedPeople(res?.data);
    //   }
    // } catch (error) {
    //   console.log("API request Error: ", error);
    // }

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            `/search/connectedPeople?id=${fileName}${
              entry?.title ? "&name=" + entry?.title : ""
            }`
        )
        .then((res) => {
          if (res?.status === 200) {
            setConnectedPeople(res?.data);
          }
        })
        .catch((error) => {
          console.log("API request Error: ", error);
        }),
      "connected-people"
    );
  }, [fileName, entry?.title, promiseInProgress, isInitialRender]);

  const { promiseInProgress: connectedPeoplePromiseInProgress } =
    usePromiseTracker({
      area: "connected-people",
    });

  const [NameVariationsSource, setNameVariationsSource] = useState(1);

  const [openNameVariationsModal, setOpenNameVariationsModal] = useState(false);
  const { promiseInProgress: nameVariationsSourcePromiseInProgress } =
    usePromiseTracker({
      area: "name-variations-modal",
    });

  const [Occupations, setOccupations] = useState([]);

  const [openOccupationModal, setOpenOccupationModal] = useState(false);
  const { promiseInProgress: occupationPromiseInProgress } = usePromiseTracker({
    area: "occupations-modal",
  });

  useEffect(() => {
    if (!fileName) return;
    // load Occupations
    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            `/book/${fileName}/occupations`
        )
        .then((res) => {
          console.log(res?.data);
          if (res?.status === 200) setOccupations(res?.data);
        })
        .catch((error) => {
          console.log("API request Error: ", error);
        }),
      "occupations-modal"
    );
  }, [fileName]);

  const handleOccupationModalOpen = (e) => {
    e.preventDefault();
    setOpenOccupationModal(true);
  };
  const handleOccupationModalClose = () => {
    setOpenOccupationModal(false);
  };
  const occupationModal = (
    <Dialog
      fullScreen={fullScreen}
      scroll="body"
      open={openOccupationModal}
      className="cite-container"
      onClose={handleOccupationModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle sx={{ padding: dialogPadding }}>
        <div className="close-div">
          <IconButton
            aria-label="close"
            onClick={handleOccupationModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers={fullScreen} sx={{ padding: dialogPadding }}>
        <div className="occupation-modal">
          <div className={`${classes.paddingHorizontal0}`}>
            {occupationPromiseInProgress ? (
              <Spinner show />
            ) : Occupations && Occupations?.length > 0 ? (
              Occupations.map((occupation, index) => (
                <div key={"oc" + index} className={classes.body}>
                  <Typography
                    variant="body1"
                    className={`gm format ${classes.format}`}
                  >
                    {!isEnglish ? occupation.deName : occupation.enName}
                  </Typography>

                  <div className={classes.clipboardArea} id="clipboardArea">
                    <Typography
                      variant="body1"
                      className={classes.clipboardAreaText}
                    >
                      {t("Description")}: {occupation.description}
                    </Typography>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.body}>
                <Typography
                  variant="body1"
                  className={`gm format ${classes.format}`}
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <Trans i18nKey="noData">No data found</Trans>
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );

  const handleNameVariationsViewSourceClick = (e) => {
    e.preventDefault();
    console.log(
      process.env.REACT_APP_SERVER_HOST_NAME +
        `/book/${fileName}/name-variations-source`
    );
    if (entry?.nameVariations?.length > 0)
      trackPromise(
        axios
          .get(
            process.env.REACT_APP_SERVER_HOST_NAME +
              `/book/${fileName}/name-variations-source`
          )
          .then((res) => {
            if (res?.status === 200) setNameVariationsSource(res?.data);
          })
          .catch((error) => {
            console.log("API request Error: ", error);
          }),
        "name-variations-modal"
      );
    setOpenNameVariationsModal(true);
  };
  const handleNameVariationsModalClose = () => {
    setOpenNameVariationsModal(false);
  };

  const clickedNameVariation = (nameVariation) => {
    setNameVariationsSource(nameVariation);
    setOpenNameVariationsModal(true);
  };
  const nameVariationsModal = (
    <Dialog
      fullScreen={fullScreen}
      scroll="body"
      open={openNameVariationsModal}
      className="cite-container"
      onClose={handleNameVariationsModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      {/*<DialogTitle sx={{ padding: dialogPadding }}>
        <div
          className={`occupation-modal-title-div ${
            fullScreen ? "dialog-fullscreen-title" : ""
          }`}
        >
           <Typography
            variant="body1"
            className="gb header-title"
            color="inherit"
            style={{
              lineHeight: "30px",
              textAlign: "center",
            }}
          >
            <Trans i18nKey="nameVariationModalTitle1">
              Name Variations for
            </Trans>
          </Typography>
          {!fullScreen ? (
            <Typography
              variant="h2"
              className="gm"
              color="inherit"
              style={{ marginLeft: "10px" }}
            >
              {entry?.title}
            </Typography>
          ) : null}
        </div> 
      </DialogTitle>*/}
      <div className="close-div">
        <IconButton
          aria-label="close"
          onClick={handleNameVariationsModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon sx={{ width: "1.5em", height: "1.5em" }} />
        </IconButton>
      </div>
      <DialogContent dividers={fullScreen} sx={{ padding: dialogPadding }}>
        <div className="occupation-modal">
          {fullScreen ? (
            <div className="modal-header">
              <div className="occupation-modal-title-div">
                <Typography
                  align="center"
                  variant="h2"
                  className="gm"
                  color="inherit"
                >
                  {entry?.title}
                </Typography>
              </div>
            </div>
          ) : null}

          {isNewFormat ? (
            <div className={`${classes.paddingHorizontal0}`}>
              <div className={classes.body}>
                <Typography
                  variant="body1"
                  className={`gm format ${classes.format}`}
                >
                  {NameVariationsSource.name}
                </Typography>
                <div className={classes.clipboardArea} id="clipboardArea">
                  <Typography
                    variant="body1"
                    className={classes.clipboardAreaText}
                  >
                    {t("Source(s)")}
                    {": "}
                    {NameVariationsSource.sources?.map((s, idx) => (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: s }}></div>
                        {idx < NameVariationsSource.sources?.length - 1
                          ? "; "
                          : null}
                      </>
                    ))}
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <div className={`${classes.paddingHorizontal0}`}>
              {nameVariationsSourcePromiseInProgress ? (
                <Spinner show />
              ) : NameVariationsSource && !isObjEmpty(NameVariationsSource) ? (
                Object.keys(NameVariationsSource).map((name, index) => (
                  <div key={"nv" + index} className={classes.body}>
                    <Typography
                      variant="body1"
                      className={`gm format ${classes.format}`}
                    >
                      {name}
                    </Typography>

                    <div className={classes.clipboardArea} id="clipboardArea">
                      <Typography
                        variant="body1"
                        className={classes.clipboardAreaText}
                      >
                        {t("Source(s)")}
                        {": "}
                        {NameVariationsSource[name]}
                      </Typography>
                    </div>
                  </div>
                ))
              ) : (
                <div className={classes.body}>
                  <Typography
                    variant="body1"
                    className={`gm format ${classes.format}`}
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <Trans i18nKey="noData">No data found</Trans>
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );

  const newNameVariations = isNewFormat
    ? entry?.nameVariations?.map((n, idx) => {
        let name = n.name;
        if (n.type !== null && n.type !== undefined) {
          name += ` [${t("NAME-TYPE-" + n.type)}]`;
        }
        let element = <span>{name}</span>;
        if (n.sources?.length) {
          element = (
            <span class="text-link" onClick={(e) => clickedNameVariation(n)}>
              {name}
            </span>
          );
        }
        if (idx < entry?.nameVariations?.length - 1) {
          element = <>{element}; </>;
        }
        return element;
      })
    : null;

  const overviewData = keywords ? (
    <div className={classes.overviewDataDiv}>
      <Typography
        variant="h2"
        gutterBottom
        style={{
          fontWeight: 500,
          fontSize: "26px",
          marginBottom: "18px",
        }}
      >
        {t("Overview")}
      </Typography>
      {keywords.dateOfBirth || keywords.placeOfBirth ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Born")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {keywords.placeOfBirth}
            {keywords.dateOfBirth && keywords.placeOfBirth ? ", " : null}
            {keywords.dateOfBirth ? formateDate(keywords.dateOfBirth) : null}
          </Typography>
        </>
      ) : null}
      {keywords.dateOfDeath || keywords.placeOfDeath ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Died")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {keywords.placeOfDeath}
            {keywords.dateOfDeath && keywords.placeOfDeath ? ", " : null}
            {keywords.dateOfDeath ? formateDate(keywords.dateOfDeath) : null}
          </Typography>
        </>
      ) : null}

      {entry?.nameVariations?.length > 0 ||
      isNotNullOrEmptyArr(keywords.furtherNames) ||
      keywords.thiemeBeckerName ||
      keywords.vollmerName ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Name Variations")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {/* new name variations format */}
            {isNewFormat ? (
              newNameVariations
            ) : (
              <>
                {/* the old name variations format (pre-nest-parser time) here */}
                {keywords.furtherNames?.join("; ") ?? null}
                {keywords.thiemeBeckerName
                  ? (isNotNullOrEmptyArr(keywords.furtherNames) ? "; " : "") +
                    keywords.thiemeBeckerName
                  : null}
                {keywords.vollmerName
                  ? (isNotNullOrEmptyArr(keywords.furtherNames) ||
                    keywords.thiemeBeckerName
                      ? "; "
                      : null) + keywords.vollmerName
                  : null}
                <br />
                <Typography
                  variant="body1"
                  className={classes.ovdata + " text-link"}
                  onClick={(e) => {
                    handleNameVariationsViewSourceClick(e);
                  }}
                >
                  {t("View Source")}
                </Typography>
              </>
            )}
          </Typography>
          {nameVariationsModal}
        </>
      ) : null}
      {keywords.gender ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Gender")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {capitalizeStr(keywords.gender)}
          </Typography>
        </>
      ) : null}

      {
        // new format occupations
        isNewFormat && isNotNullOrEmptyArr(Occupations) && (
          <>
            <Typography variant="h6" className={classes.ovdataTitle}>
              {t("Occupation")}
            </Typography>

            <Typography
              variant="body1"
              className={classes.ovdata + " text-link"}
              onClick={handleOccupationModalOpen}
            >
              {Occupations?.map((occu) =>
                !isEnglish ? occu.deName : occu.enName
              ).join(", ")}
            </Typography>

            {occupationModal}
          </>
        )
      }

      {
        //old format occupations
        !isNewFormat && isNotNullOrEmptyArr(keywords.occupation) ? (
          <>
            <Typography variant="h6" className={classes.ovdataTitle}>
              {t("Occupation")}
            </Typography>

            <Typography
              variant="body1"
              className={classes.ovdata + " text-link"}
              onClick={handleOccupationModalOpen}
            >
              {keywords.occupation?.map((occu) => occu).join(", ")}
            </Typography>

            {occupationModal}
          </>
        ) : null
      }
      {isNotNullOrEmptyArr(keywords.geographicData) ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Places of Activity")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {keywords.geographicData.join(", ")}.{" "}
            <a href="#connected-places" className="text-link">
              {t("View Map")}
            </a>
          </Typography>
        </>
      ) : null}

      {isNotNullOrEmptyArr(keywords.country) ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Country")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {keywords.country.join("; ")}
          </Typography>
        </>
      ) : null}

      {entry?.authors && entry.authors.length ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {entry.authors.length < 2 ? t("Author") : t("Authors")}
          </Typography>
          {entry.authors.map((author, index) => (
            <Typography
              key={"author" + index}
              variant="body1"
              className={classes.ovdata}
            >
              {capitalizeStr(author)}
            </Typography>
          ))}
        </>
      ) : null}

      {isNotNullOrEmptyArr(keywords.location) ? (
        <>
          <Typography variant="h6" className={classes.ovdataTitle}>
            {t("Location")}
          </Typography>
          <Typography variant="body1" className={classes.ovdata}>
            {keywords.location.join("; ")}
          </Typography>
        </>
      ) : null}
    </div>
  ) : null;

  return (
    <div className="body-container">
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      <Navbar isHomepage={false} />
      <div className="main">
        <section className="section">
          <Container
            className={clsx(
              isDesktop
                ? showLineNumbers
                  ? "showLineNumbers"
                  : "hideLineNumbers"
                : "",
              "container"
            )}
          >
            <div className={classes.toparea + " " + classes.sectionDesktop}>
              <div className={classes.sectionDesktop + " space-between"}>
                <div className={classes.navigate}>
                  {canGoBackToSearchResult() ? (
                    <span className={classes.backbtn}>
                      <Button
                        onClick={backToResults}
                        square="true"
                        variant="outlined"
                      >
                        <KeyboardBackspaceIcon />
                        &nbsp;&nbsp;
                        <Trans i18nKey="searchResults">Search Results</Trans>
                      </Button>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {!promiseInProgress ? (
              entry?.title ? (
                entry?.isFreeAccess || (isLoggedIn && isUserAuthorized) ? (
                  <Grid container spacing={4}>
                    <Grid item md={9}>
                      <div className={classes.titlearea}>
                        <div className="results">
                          <Typography
                            variant="h1"
                            className={classes.results}
                            style={{
                              fontSize: "38px",
                              wordWrap: "anywhere",
                              paddingRight: "50px",
                            }}
                          >
                            {entry?.title ? entry?.title : t("Not found")}
                          </Typography>
                        </div>
                      </div>
                      {groupsData.length || familiesData.length ? (
                        <div className={classes.artistInfo}>
                          <Typography className={classes.artistDetail}>
                            {t("Member of")}&nbsp;
                          </Typography>
                          {groupsData.map((group, i) => (
                            <>
                              <Link
                                to={`/entry/${group.id}`}
                                className="text-link"
                              >
                                <Typography className={classes.artistDetail}>
                                  {group.name}&nbsp;({t(group.type)})
                                </Typography>
                              </Link>
                              {i < groupsData.length - 1 ? ", " : null}
                            </>
                          ))}

                          {groupsData.length > 0 && familiesData.length > 0
                            ? " " + t("and") + " "
                            : null}

                          {familiesData.map((group, i) => (
                            <>
                              <Link
                                to={`/entry/${group.id}`}
                                className="text-link"
                              >
                                <Typography className={classes.artistDetail}>
                                  {group.name}&nbsp;({t(group.type)})
                                </Typography>
                              </Link>
                              {i < groupsData.length - 1 ? ", " : null}
                            </>
                          ))}
                        </div>
                      ) : null}
                      {(isNotNullOrEmptyArr(entry?.enKeywords?.pndId) ||
                        gndIDs.length > 0) && (
                        <Typography className={classes.artistDetail}>
                          {t("GND-ID")}:{" "}
                          {entry?.enKeywords?.pndId?.map((element, i) => {
                            let id = element?.trim()?.substring(3);
                            return isNotNullOrEmptyStr(id) ? (
                              <>
                                {i !== 0 ? ", " : null}
                                <a
                                  href={`https://d-nb.info/gnd/${id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-link"
                                >
                                  {id}
                                </a>
                              </>
                            ) : null;
                          })}
                          {gndIDs?.map((element, i) => {
                            let id = element?.id?.trim();
                            return isNotNullOrEmptyStr(id) ? (
                              <>
                                {i !== 0 ? ", " : null}
                                <a
                                  href={`https://d-nb.info/gnd/${id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-link"
                                >
                                  {id}
                                </a>
                              </>
                            ) : null;
                          })}
                        </Typography>
                      )}
                      {entry?.id && (
                        <Typography className={classes.artistDetail}>
                          {t("AOW ID")}: {entry.id}
                        </Typography>
                      )}

                      <div className={classes.buttonGroup}>
                        <div className={classes.btn}>
                          <DownloadButton url={pdfUrl}></DownloadButton>
                          {/* <DownloadButton fileName={fileName}></DownloadButton> */}
                        </div>

                        {citeData && (
                          <div className={classes.btn}>
                            <CiteButton
                              artistId={entry?.id}
                              apaString={citeData?.APA}
                              mlaString={citeData?.MLA}
                              chicagoString={citeData?.Chicago}
                              harvardString={citeData?.Harvard}
                              vancouverString={citeData?.Vancouver}
                            ></CiteButton>
                          </div>
                        )}

                        <div className={classes.btn}>
                          <ShareButton></ShareButton>
                        </div>
                      </div>

                      {overviewData}
                      {ldata}

                      {isNotNullOrEmptyArr(keywords.geographicData) ? (
                        <div style={{ marginTop: "90px" }}>
                          <Typography
                            id="connected-places"
                            variant="h2"
                            gutterBottom
                            style={{
                              fontWeight: 500,
                              fontSize: "26px",
                              marginBottom: "30px",
                            }}
                          >
                            {t("Places of Activity")}
                          </Typography>
                          <ConnectedPlaces places={keywords.geographicData} />
                        </div>
                      ) : null}

                      {connectedPeoplePromiseInProgress ? (
                        <Spinner show />
                      ) : ConnectedPeople && ConnectedPeople?.length > 0 ? (
                        <div style={{ marginTop: "90px" }}>
                          <Typography
                            variant="h2"
                            gutterBottom
                            style={{
                              fontWeight: 500,
                              fontSize: "26px",
                              marginBottom: "30px",
                            }}
                          >
                            {t("Connected People")}
                          </Typography>
                          <Grid container spacing={4}>
                            {ConnectedPeople?.map((person, i) =>
                              person.name ? (
                                <Grid key={"person" + i} item md={6}>
                                  <ArtistName
                                    id={person.id}
                                    name={person.name}
                                    isArtist
                                  />
                                  <Divider sx={{ borderBottomWidth: 2 }} />
                                </Grid>
                              ) : null
                            )}
                          </Grid>
                        </div>
                      ) : null}
                      {/* <EntryTabs
                      onTabChange={(index) => {
                        setCurrentTab(index);
                      }}
                      citdata={entry?.newCitList}
                      lemmadata={ldata}
                      keywords={
                        i18n.language === "de"
                          ? entry?.deKeywords
                          : entry?.enKeywords
                      }
                      lemma={entry?.lemma ? entry.lemma[0] : ""}
                      ref={entryTabsRef}
                    /> */}
                    </Grid>

                    <Grid item md={3}>
                      <div className={classes.container}>
                        {isNotNullOrUndefined(entry?.imageURL) &&
                        entry?.imageURL !== "" ? (
                          <div className={classes.imgbox}>
                            <img
                              className="image"
                              alt={t("Artist Image")}
                              src={entry?.imageURL}
                              style={{
                                width: "380px",
                              }}
                            />
                          </div>
                        ) : null}

                        {entry.bodyMap?.sec?.length > 0 && contentTable}
                      </div>
                      <div className={classes.bottomStickyGroup}>
                        <div className={classes.bottomStickyGroupDiv}>
                          <BackToTop></BackToTop>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <div className={classes.titlearea}>
                      <div className="results">
                        <Typography
                          variant="h1"
                          className={classes.results}
                          style={{
                            fontSize: "32px",
                            wordWrap: "anywhere",
                            paddingRight: "50px",
                          }}
                        >
                          {entry?.title ? entry?.title : t("Not found")}
                        </Typography>
                      </div>
                    </div>
                    <PayWall />
                  </>
                )
              ) : (
                <Typography variant="h1" className={classes.mesBox}>
                  {t("Artist") + " " + t("Not found")}
                </Typography>
              )
            ) : null}
          </Container>
        </section>
      </div>

      <OuterSpinner />
      <Footer />
    </div>
  );
}
export default Artist;
