import { Typography } from "@material-ui/core";
import React from "react";
import ExpandableContent from "react-expandable-content";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  sectionsContainer: {
    // display: "inline-flex",
    flexDirection: "column",
    gap: "1rem",
  },
  mainSectionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
  },
  subTitle: {
    // fontSize: "20px",
    // fontWeight: "bold",
    color: "#000",
    marginBottom: "1rem",
  },
  inlineSubTitle: {
    display: "inline-block",
    marginRight: "0.75rem",
  },
  marginRight: {
    marginRight: "0.75rem",
  },
}));

export function ContentTable({ classes, entry }) {
  const { t } = useTranslation();

  return (
    <Box className={classes.boxDesign}>
      <Typography className={classes.ContentTitle}>{t("Contents")}</Typography>

      {entry.bodyMap?.sec?.length > 0 &&
        entry.bodyMap?.sec?.map((element, i) => (
          <div key={"entrybody" + i} className={classes.mainBox}>
            <div className="mainTitle1">
              <a href={"#" + element.secType}>{t(element.secType)}</a>
            </div>
            <div>
              {element?.sec?.map((sec, i) => (
                <div key={"sub" + i} className={classes.boxSubTitle}>
                  <a href={"#" + sec.secType} target="_self">
                    {t(sec.secType)}
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
    </Box>
  );
}

export function ArtistBodySection({ element, level, classes }) {
  const { t } = useTranslation();
  const classes1 = useStyles();

  const titleClasses = {
    1: classes1.subTitle,
    2: classes1.inlineSubTitle,
  };

  const titleVariants = {
    1: "h2",
    2: "h4",
  };

  const contentComponent = {
    1: "p",
    2: "span",
  };

  const cleanedContent = element.content
    ?.replace("<title/>", "")
    ?.replaceAll("<p", "<span")
    ?.replaceAll("</p>", "</span>")
    ?.trim();

  const content = (
    <>
      {
        //text content
        cleanedContent.length > 0 && (
          <Typography
            variant="body1"
            component={contentComponent[level]}
            className={classes.content + " " + classes1.marginRight}
            dangerouslySetInnerHTML={{
              __html: cleanedContent,
            }}
          ></Typography>
        )
      }

      {element?.sec?.length > 0 && (
        <section className={classes1.sectionsContainer}>
          {element?.sec?.map((element, index) => (
            <ArtistBodySection
              key={`sbmap-${level}-${index}`}
              element={element}
              level={level + 1}
              classes={classes}
            />
          ))}
        </section>
      )}
    </>
  );

  return (
    <>
      <Typography
        variant={titleVariants[level]}
        id={element.secType}
        className={titleClasses[level]}
      >
        {t(element.secType)}
      </Typography>

      {level === 1 ? (
        <ExpandableContent
          className={classes.textExpand}
          maxHeight={250}
          collapseText={t("View less")}
          expandText={t("View more")}
        >
          {content}
        </ExpandableContent>
      ) : (
        content
      )}
    </>
  );
}

export function BodyNewFormat({ classes, entry, tableData }) {
  const { t } = useTranslation();
  const classes1 = useStyles();

  return (
    <div className={classes.contentDiv + " artistContent"}>
      {entry?.bodyMap?.sec?.length > 0 && (
        <>
          <div
            style={{ marginTop: "30px" }}
            className={classes1.mainSectionsContainer}
          >
            {entry.bodyMap?.sec?.map((element, index) => (
              <section key={"bmap" + index}>
                <ArtistBodySection
                  element={element}
                  level={1}
                  classes={classes}
                />
              </section>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
