import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

const CollectionTile = ({ collection }) => {
  const { t } = useTranslation();
  const language = i18next.language;
  const en = language === "en";

  return (
    <Link to={"/collection/" + collection?.id}>
      <img
        className={"image"}
        alt={t("Collection Image")}
        src={collection?.imageURL}
        style={{ aspectRatio: "1/0.6", width: "100%", height: "auto" }}
      />
      <Typography
        variant="body1"
        align="right"
        style={{
          fontSize: "12px",
          fontWeight: 400,
          display: "block",
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        {en
          ? collection?.imageCredit
          : collection?.imageCreditDE || collection?.imageCredit}
      </Typography>
      <Typography
        style={{
          fontSize: "26px",
          fontWeight: "bolder",
          marginTop: "15px",
        }}
      >
        {en ? collection?.name : collection?.nameDE || collection?.name}
      </Typography>
      <Typography
        style={{
          fontFamily: "Times",
          fontWeight: "200",
          fontSize: "18px",
          marginTop: "10px",
        }}
      >
        {en
          ? collection?.description
          : collection?.descriptionDE || collection?.description}
      </Typography>
    </Link>
  );
};
export default CollectionTile;
