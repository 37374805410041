import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./OuterSpinner.css";

export default function OuterSpinner(props) {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="outer-spinner">
        <div className="lds-dual-ring"></div>
      </div>
    )
  );
}
