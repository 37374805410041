import React from "react";
import { makeStyles } from "@material-ui/styles";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "165px",
    paddingLeft: "300px",
    fontFamily: "Gotham-Medium",
    fontSize: "30px",
    justifyContent: "center",
  },
}));
const PageNotFound = (props) => {
  const classes = useStyles();
  return (
    <div class="body-container">
      <Navbar isHomepage={false} />
      <div class="section">
        <div className={classes.root}>404 - Page Not Found</div>
      </div>
      <Footer />
    </div>
  );
};
export default PageNotFound;
