import React from "react";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import Logo from "./images/DG_Logo.svg";
import DarkLogo from "./images/DG_dark.svg";
import MobileLogo from "./images/mobilelogodark.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
//import Paper from '@mui/material/Paper';
import Popper from "@mui/material/Popper";
//import MenuItem from '@mui/material/MenuItem';
import MenuList from "@mui/material/MenuList";
import { useLocation } from "react-router-dom";
import Popover from "@mui/material/Popover";
//import {render} from 'react-dom';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  List,
  Paper,
  InputBase,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import Toast from "./Toast";
import {
  checkIfLanguageIs,
  isJWTPresent,
  isAuthorized,
} from "../utils/helper_functions";
import LanguageIcon from "@mui/icons-material/Language";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import { render } from "@testing-library/react";
import { Divider, Grid } from "@mui/material";
import AdvancedSearch from "./SearchTab/AdvancedSearch";
import useAuth from "../utils/hooks/useAuth";
import { a } from "aws-amplify";
// import Button from '@mui/material/Button';
const useStyles = makeStyles((theme) => ({
  grow: {
    // flexGrow: 1,
    display: "flex",
    position: "sticky",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "white",
    top: 0,
    zIndex: 99,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up("md")]: {
      top: "0px",
    },
  },
  separator: {
    [theme.breakpoints.up("md")]: {
      borderBottom: "5px solid #005D78",
    },
  },

  appBar: {
    color: "#ffffff",
    padding: "0 5px",
    [theme.breakpoints.up("md")]: {
      padding: "0 90px",
    },
    "& .logomobile img": {
      height: "15px",
      width: "auto",
    },
  },

  toolbar: {
    padding: "0px",
    width: "100%",
    minHeight: "70px !important",
    "&. active": {
      backgroundColor: "red",
    },
  },
  appBarTransparent: {
    color: "#ffffff",
    "& .logomobile img": {
      filter: "invert()",
    },
    "& .mobileDrawerIcon svg path": {
      stroke: "#fff",
    },
    "& .user-icon svg circle": {
      stroke: "#000000",
    },
    "& .user-icon svg path": {
      fill: "#000000",
    },
    "& .user-login-icon svg circle": {
      stroke: "#007596",
      fill: "#007596",
    },
  },
  appBarWhite: {
    "& .logo img": {
      filter: "invert()",
    },
    color: "#000F1B",
    "& .user-icon svg circle": {
      stroke: "#3c3c3c",
    },
    "& .user-icon svg path": {
      fill: "#3c3c3c",
    },
  },
  navbarSearchBtn: {
    backgroundColor: "#007596",
    margin: "4px",
    height: "38px",
    width: "38px",
    marginLeft: "35px",
    "&:hover": {
      backgroundColor: "#00495D !important",
    },
  },
  input: {
    alignSelf: "center",
    padding: "0.25rem 1rem",
    width: "82%",
  },
  searchgrow: {
    border: "1px solid rgba(0, 0, 0, 0.4)",
    marginLeft: "60px",
    marginRight: "0px",
    width: "450px",
  },
  navMenuItem: {
    fontFamily: "Gotham-Medium",
    fontSize: "16px",
    lineHeight: "25px",
    "&:hover": { backgroundColor: "#DDE9EE" },
    "&.Mui-disabled": { color: "#007596", opacity: "1" },
  },
  arrowDownIcon: {
    alignItems: "center",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "32px",
      marginRight: "25px",
    },
  },
  loginText: {
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
  },
  sectionDesktopSearch: {},
  sectionDesktop: {
    display: "none",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      marginRight: "90px",
      marginLeft: "90px",
      justifyContent: "space-between",
    },
  },
  navbarLeft: {
    display: "flex",
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  navLinks: {
    display: "flex",
    alignItems: "center",
  },
  navLinksSearch: {
    display: "flex",
    alignItems: "center",
    marginRight: "90px",
  },
  navbtn: {
    marginLeft: "25px",
    "& button": {
      padding: "0px",
    },
  },
  navitem: {
    marginLeft: "150px",
  },
  navitem1: {
    marginLeft: "20px",
  },
  navitemLanguage: {
    color: "black",
    "&.onclick": {},
  },
  title: {
    color: "black",
    textAlign: "center",
  },
  icon: {
    margin: 0,
    padding: 0,
  },
  navMenu: {
    marginLeft: "20px",
    border: "1px solid rgba(0, 0, 0, 0.4)",
    "&:hover": {
      backgroundColor: "#DDE9EE !important",
    },
  },
  navMenuLine: {
    margin: "5px",
  },
  navSearchIcon: {
    color: "white",
  },
  navAccessBtn: {
    width: "155px",
    height: "20%",
    display: "block",
    textAlign: "center",
    border: "none",
    marginLeft: "20px",
    backgroundColor: "#007596",
    color: "white",
    padding: "10px 10px",
    fontSize: "16px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    fontFamily: "Gotham",
    "&:hover": { backgroundColor: "#00495D" },
  },
  mainTitle: {
    color: "#ffffff",
    marginBottom: "50px",
  },
  list: {
    backgroundColor: "#f6f6f8",
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#e1e1e1",
    display: "block",
  },
  whitelinks: {
    paddingBottom: "0!important",
    paddingTop: "0!important",
    backgroundColor: "#ffffff",
    color: "#000000",
  },
  menuItemMobile: {
    fontFamily: "'Gotham-Medium'!important",
    fontSize: "17px!important",
    lineHeight: "20px",
    color: "#000000",
    "& .MuiListItemText-primary": {
      fontFamily: "'Gotham-Medium'!important",
      fontSize: "17px!important",
      lineHeight: "20px",
      color: "#000000",
    },
  },
  menuIconMobile: {
    minWidth: "32px!important",
  },
  menubtn: {
    marginBottom: "20px",
    marginTop: "27px",
    paddingLeft: "24px",
    "& span": {
      fontSize: "17px",
      fontFamily: '"Gotham-Medium"',
    },
  },
  buttons: {
    marginRight: "10px",
    height: "55px",
    width: "130px",
  },
  closedrawer: {
    marginTop: "10px",
    marginRight: "10px",
    padding: "5px",
    display: "flex",
    position: "fixed",
    right: "0",
    top: "0",
    color: "#ffffff",
    backgroundColor: "#DDE9EE",
  },
  profilemenu: {
    marginTop: "70px",
    "&  .MuiPopover-paper": {
      overflow: "visible!important",
    },
    "&  .MuiPopover-paper:before": {
      content: '" "',
      width: "0",
      height: "0",
      position: "absolute",
      borderLeft: "30px solid transparent",
      borderRight: "30px solid transparent",
      borderBottom: "40px solid #ffffff",
      zIndex: "-1",
      left: "50%",
      transform: "translate(-50%,-50%)",
      marginTop: "15px",
    },
  },
  menuListItems: {
    height: "60px",
    marginLeft: "15px",
  },
  langButton: {
    padding: "10px !important",
  },
  dropbtn: {
    backgroundColor: "#3498DB",
    color: "white",
    padding: "16px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
  },
  dropdown: {
    position: "relative",
    display: "inline-block",
  },
  dropdownContent: {
    display: "none",
    position: "absolute",
    backgroundColor: "#f1f1f1",
    minWidth: "160px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
  },
  show: { display: "block" },
  profileBtn: {
    // backgroundColor: "#007596",
    // color: "black",
    // boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    // "&:hover": { backgroundColor: "#00495D" },
  },
  langDiv: {
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
  },
  langBtn: {
    background: "none",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  langMenuItemText: {
    fontSize: "16px",
    "&:hover": { backgroundColor: "#DDE9EE" },
    "&.Mui-disabled": { color: "#007596", opacity: "1" },
  },

  drawer: {
    padding: "8px 90px",
    justifyContent: "center",
  },
  drawerLogo: {
    display: "flex",
    alignItems: "center",

    "& img": {
      width: "32px",
      marginRight: "25px",
    },
  },
  drawerTabs: {
    padding: "31px !important",
    display: "flex",
    justifyContent: "center",
  },
  drawercont: {
    overflow: "visible!important",
    "& .MuiDrawer-paper": {
      overflowY: "visible!important",
    },
  },
  drawerMidText: {
    display: "flex",
  },
  drawerMidText1: {
    fontFamily: ["Gotham-Medium", "tllmedium", "sans-serif"].join(","),
    fontWeight: 500,
    lineHeight: "30px",
    fontSize: 18,
  },
  drawerText: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between !important",
  },
  searchbtn: {
    display: "flex",
  },
  mobileNavbar: {
    marginLeft: "20px",
  },
  closeBtn: {
    marginTop: "2px",
    fontFamily: "Gotham-Medium",
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
  },
}));
function Navbar({ isHomepage, values }) {
  const navigate = useNavigate();

  const { logout } = useAuth();

  const redirectUrl = encodeURIComponent(
    window.location.pathname + window.location.search
  );

  const fvalue = ["LEMMA", "FULL_TEXT", "CITATION", "INDEX_LIBRORUM"];
  const fname = ["Lemma", "Full Text", "Citation", "Index Librorum"];
  const { t, i18n } = useTranslation();

  const getSelectedTextFromValue = (selectedValue) => {
    for (let index = 0; index < fvalue.length; index++) {
      const element = fvalue[index];
      if (element === selectedValue) {
        return fname[index];
      }
    }
    return "";
  };

  const getFirstSearchTerm = () => {
    // get current search params
    const searchParams = new URLSearchParams(window.location.search);
    // get the first search term that starts with "val" (val0, val1, val2)
    const searchTerm = searchParams.get("val0");
    // return the search term or an empty string
    return searchTerm || "";
  };

  var valuedata = values?.search ? values?.search[0]?.value : "";

  const field = getSelectedTextFromValue(
    values?.search ? values?.search[0]?.field ?? "LEMMA" : "LEMMA"
  );
  const classes = useStyles();
  const [drawerMenu, setDrawerMenu] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerMenu(!drawerMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const doLogout = () => {
    handleProfileMenuClose();
    logoutAndClearLocalStorage();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [showSearch, setShowSearch] = React.useState(true);
  const changeVisibility = () => {
    var el = window.innerWidth < 600;

    if (window.scrollY >= 50 && el) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  };
  window.addEventListener("scroll", changeVisibility);
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openDrawerMoreMenu, setOpenDrawerMoreMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const drawerMoreMenuAnchorRef = React.useRef(null);
  const handleToggleMenu = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  };
  const handleToggleDrawerMoreMenu = () => {
    setOpenDrawerMoreMenu((prevOpenMenu) => !prevOpenMenu);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };
  const handleCloseDrawerMoreMenu = (event) => {
    if (
      drawerMoreMenuAnchorRef?.current &&
      drawerMoreMenuAnchorRef?.current?.contains(event.target)
    ) {
      return;
    }

    setOpenDrawerMoreMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    } else if (event.key === "Escape") {
      setOpenMenu(false);
    }
  }
  function handleListKeyDownForDrawerMoreMenu(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenDrawerMoreMenu(false);
    } else if (event.key === "Escape") {
      setOpenDrawerMoreMenu(false);
    }
  }

  // return focus to the button when we transitioned from !openMenu -> openMenu
  const prevOpenMenu = React.useRef(openMenu);
  React.useEffect(() => {
    if (prevOpenMenu.current === true && openMenu === false) {
      anchorRef?.current?.focus();
    }

    prevOpenMenu.current = openMenu;
  }, [openMenu]);
  const prevOpenDrawerMoreMenu = React.useRef(openDrawerMoreMenu);
  React.useEffect(() => {
    if (
      prevOpenDrawerMoreMenu.current === true &&
      openDrawerMoreMenu === false
    ) {
      drawerMoreMenuAnchorRef?.current?.focus();
    }

    prevOpenDrawerMoreMenu.current = openDrawerMoreMenu;
  }, [openDrawerMoreMenu]);

  const renderDrawer = (
    <Drawer anchor="left" open={drawerMenu} onClose={toggleDrawer}>
      <div className={classes.list}>
        <div className={classes.closedrawer}>
          <IconButton style={{ padding: "unset" }} onClick={toggleDrawer}>
            <svg
              width="22"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6055 10.6094L31.8187 31.8226"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M31.8203 10.6094L10.6071 31.8226"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          </IconButton>
          <Typography className={classes.closeBtn} onClick={toggleDrawer}>
            {t("Close")}
          </Typography>
        </div>
        <Typography
          variant="h6"
          style={{ marginTop: "10px" }}
          className={classes.mobileNavbar}
        >
          {t("AOW")}
        </Typography>
        {localStorage.getItem("jwtToken") === undefined ||
        localStorage.getItem("jwtToken") === null ? (
          <List disablePadding>
            <Link to="/">
              <ListItem className={classes.menuListItems}>
                <Typography variant="h4" className="gm">
                  <Trans i18nKey="home">Home</Trans>
                </Typography>
              </ListItem>
            </Link>
            <Link to="/about">
              <ListItem className={classes.menuListItems}>
                <Typography variant="h4" className="gm">
                  <Trans i18nKey="about">About</Trans>
                </Typography>
              </ListItem>
            </Link>
            {/* <Link to="/HowToUse">
              <ListItem
                className={classes.menuListItems}

              >
                <Typography variant="h4" className="gm">
                  <Trans i18nKey="Help using AOW">Help using AOW</Trans>
                </Typography>
              </ListItem>
            </Link> */}

            {isJWTPresent() ? (
              <>
                <IconButton
                  className={classes.profileBtn}
                  onClick={handleProfileBtnClick}
                >
                  <svg
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="#007596"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="22.5"
                      cy="22.5"
                      r="21.75"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                      fill="white"
                    />
                  </svg>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={profileAnchorEl}
                  open={profileMenuOpen}
                  onClose={handleProfileMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className={"default-font"}
                >
                  {localStorage.getItem("user_name") !== undefined &&
                    localStorage.getItem("user_name") !== null && (
                      <MenuItem
                        disabled
                        className={classes.langMenuItemText + " default-font"}
                      >
                        {localStorage.getItem("user_name")}
                      </MenuItem>
                    )}
                  {!localStorage.getItem("isAuthorized") && (
                    <MenuItem>
                      <Link to={`/validate?redirect_url=${redirectUrl}`}>
                        <Typography
                          variant="h4"
                          style={{
                            marginLeft: "30px",
                            height: "40px",
                            marginTop: "18px",
                          }}
                          className="gm"
                        >
                          {t("Alternate Login")}
                        </Typography>
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem
                    className={classes.langMenuItemText + " default-font"}
                    onClick={doLogout}
                  >
                    {t("Logout")}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Link to={`/validate?redirect_url=${redirectUrl}`}>
                <Typography
                  variant="h4"
                  style={{
                    marginLeft: "30px",
                    height: "40px",
                    marginTop: "18px",
                  }}
                  className="gm"
                >
                  {t("Login")}
                </Typography>
              </Link>
            )}
          </List>
        ) : (
          <List disablePadding>
            <Link to="/">
              <ListItem className={classes.menuListItems}>
                <Typography variant="h4" className="gm">
                  <Trans i18nKey="home">Home</Trans>
                </Typography>
              </ListItem>
            </Link>
            {localStorage.getItem("user_name") !== undefined &&
            localStorage.getItem("user_name") !== null ? (
              <ListItem className={classes.menuListItems}>
                <Typography variant="h4" className="gm">
                  {localStorage.getItem("user_name")}
                </Typography>
              </ListItem>
            ) : (
              ""
            )}
            <Link to="/about">
              <ListItem className={classes.menuListItems}>
                <Typography variant="h4" className="gm">
                  <Trans i18nKey="about">About</Trans>
                </Typography>
              </ListItem>
            </Link>
            <ListItem
              onClick={(e) => {
                logoutAndClearLocalStorage();
                handleMenuClose();
                toggleDrawer();
              }}
              style={{ cursor: "pointer" }}
              className={classes.menuListItems}
            >
              <ListItemIcon className={classes.menuIconMobile}>
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 19 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                    fill="black"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={<Trans i18nkey="">Sign out</Trans>}
                className={classes.menuItemMobile}
              />
            </ListItem>
          </List>
        )}
        <div className={classes.menubtn}>
          <Button
            disableElevation
            variant="contained"
            className={classes.buttons}
            color={checkIfLanguageIs("de") ? "primary" : "inherit"}
            onClick={() => handleLanguageChange("de")}
          >
            Deutsch
          </Button>
          <Button
            disableElevation
            variant="contained"
            className={classes.buttons}
            color={
              !checkIfLanguageIs("de") || i18n.language === undefined
                ? "primary"
                : "inherit"
            }
            onClick={() => handleLanguageChange("en")}
          >
            English
          </Button>
        </div>
        <ListItem className={classes.menuListItems}>
          <Typography
            variant="body1"
            className={classes.menuItemMobile + " gm"}
          >
            <Trans i18nKey="contact">
              <a
                href={
                  "https://www.degruyter.com/cms/pages/contacts?lang=" +
                  (checkIfLanguageIs("de") ? "de" : "en")
                }
                target="_blank"
              >
                Contact
              </a>
            </Trans>
          </Typography>
        </ListItem>
        {/* <span className={classes.divider}></span> */}
        <ListItem className={classes.menuListItems}>
          <Typography
            variant="body1"
            className={classes.menuItemMobile + " gm"}
          >
            <Trans i18nKey="privacy">
              <a
                href={
                  "https://www.degruyter.com/cms/pages/privacy-policy?lang=" +
                  (checkIfLanguageIs("de") ? "de" : "en")
                }
                target="_blank"
              >
                Privacy Policy
              </a>
            </Trans>
          </Typography>
        </ListItem>
        {/* <span className={classes.divider}></span> */}
        <ListItem className={classes.menuListItems}>
          <Typography
            variant="body1"
            className={classes.menuItemMobile + " gm"}
          >
            <Trans i18nKey="termsCondition">
              <a
                href={
                  "https://www.degruyter.com/cms/pages/terms-and-conditions?lang=" +
                  (checkIfLanguageIs("de") ? "de" : "en")
                }
                target="_blank"
              >
                Terms &amp; Conditions
              </a>
            </Trans>
          </Typography>
        </ListItem>
        {/* <span className={classes.divider}></span> */}
        <ListItem className={classes.menuListItems}>
          <Typography
            variant="body1"
            className={classes.menuItemMobile + " gm"}
          >
            <Trans i18nKey="imprint">
              <a
                href={
                  "https://www.degruyter.com/cms/pages/imprint?lang=" +
                  (checkIfLanguageIs("de") ? "de" : "en")
                }
                target="_blank"
              >
                Imprint
              </a>
            </Trans>
          </Typography>
        </ListItem>
        <ListItem className={classes.menuListItems}>
          <Typography
            variant="body1"
            className={classes.menuItemMobile + " gm"}
          >
            © 2020 De Gruyter
          </Typography>
        </ListItem>
      </div>
    </Drawer>
  );
  const [snack, setSnack] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const profileMenuOpen = Boolean(profileAnchorEl);
  const handleProfileBtnClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const logoutAndClearLocalStorage = () => {
    logout();
    setSnack(true);
    navigate(0);
  };
  const menuId = "primary-search-account-menu";
  const [searchInside, setSearchInside] = React.useState(false);
  const searchClickHandler = () => {
    setSearchInside(!searchInside);
  };

  const renderMenuItem = () => {
    if (
      localStorage.getItem("jwtToken") !== undefined &&
      localStorage.getItem("jwtToken") !== null
    ) {
      return (
        <Menu
          className={classes.profilemenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          disableAutoFocus
          transitionDuration={0}
          TransitionProps={{ timeout: 0 }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open1={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} className="gm fs-16">
            {localStorage.getItem("user_name") !== undefined &&
            localStorage.getItem("user_name") !== null
              ? localStorage.getItem("user_name")
              : ""}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleMenuClose();
              logoutAndClearLocalStorage();
            }}
            className="gm fs-16"
          >
            {" "}
            <Trans i18nkey="">Sign out")</Trans>
          </MenuItem>
        </Menu>
      );
    } else {
      return (
        <Menu
          className={classes.profilemenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          transitionDuration={0}
          TransitionProps={{ timeout: 0 }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        ></Menu>
      );
    }
  };
  const navbarMenuLogo = (
    <>
      <NavLink className={classes.drawerLogo + " logo"} to="/">
        <img src={DarkLogo} alt="website logo" />
        <Typography variant="h6" className={classes.logoTitle}>
          {t("Artists of the World")}
        </Typography>
      </NavLink>
    </>
  );
  const getAccessButton =
    isJWTPresent() && isAuthorized() ? null : (
      <div>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            className={classes.navAccessBtn}
            onClick={() => {
              window.open("https://www.degruyter.com/database/AOW/html");
            }}
          >
            {t("Get Access")}
          </Button>
        </Stack>
      </div>
    );

  const userOptions = (
    <div className={classes.loginText}>
      {isJWTPresent() ? (
        <>
          <IconButton
            className={classes.profileBtn}
            onClick={handleProfileBtnClick}
          >
            <svg
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="#007596"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="22.5"
                cy="22.5"
                r="21.75"
                stroke="white"
                strokeWidth="1.5"
              />
              <path
                d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                fill="white"
              />
            </svg>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={profileAnchorEl}
            open={profileMenuOpen}
            onClose={handleProfileMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className={"default-font"}
          >
            {localStorage.getItem("user_name") !== undefined &&
              localStorage.getItem("user_name") !== null && (
                <MenuItem
                  disabled
                  className={classes.langMenuItemText + " default-font"}
                >
                  {localStorage.getItem("user_name")}
                </MenuItem>
              )}
            {localStorage.getItem("isAuthorized") === "false" && (
              <MenuItem className={classes.langMenuItemText + " default-font"}>
                <Link to={`/validate?redirect_url=${redirectUrl}`}>
                  {t("Alternate Login")}
                </Link>
              </MenuItem>
            )}
            <MenuItem
              className={classes.langMenuItemText + " default-font"}
              onClick={doLogout}
            >
              {t("Logout")}
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <IconButton
            className={classes.profileBtn}
            onClick={handleProfileBtnClick}
          >
            <svg
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="22.5"
                cy="22.5"
                r="21.75"
                stroke="black"
                strokeWidth="1.5"
              />
              <path
                d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                fill="black"
              />
            </svg>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={profileAnchorEl}
            open={profileMenuOpen}
            onClose={handleProfileMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className={"default-font"}
          >
            <MenuItem
              className={classes.langMenuItemText + " default-font"}
              onClick={() => {
                handleProfileMenuClose();
              }}
            >
              <Link to={`/validate?redirect_url=${redirectUrl}`}>
                {t("Sign in")} / {t("Sign up")}
              </Link>
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );

  return (
    <>
      <div
        className={clsx(
          classes.grow,
          window.location.pathname === "/" ? null : classes.separator
        )}
      >
        {snack ? (
          <Toast
            status={true}
            type="success"
            message="Logged Out Successfully"
          />
        ) : (
          ""
        )}
        {/* <AppBar
        elevation={0}
        position="static"
        className={clsx(
          classes.appBar,
          showSearch ? classes.appBarTransparent : classes.appBarWhite
        )}
      > */}
        <Toolbar className={`${classes.toolbar} ${classes.active}`}>
          <div className={classes.sectionDesktop}>
            <NavLink className={classes.logo + " logo"} to="/">
              <img src={DarkLogo} alt="website logo" />
              <Typography variant="h6" className={classes.logoTitle}>
                {t("Artists of the World")}
              </Typography>
            </NavLink>

            {isHomepage ? (
              <div className={classes.navLinks}>
                <div className={classes.navitem}>
                  <Link to="/About">
                    <Typography variant="h6" className={classes.title}>
                      <Trans i18nKey="about">About</Trans>
                    </Typography>
                  </Link>
                </div>
                {/* <div className={classes.navitem1}>
                  <Link to="/HowToUse">
                    <Typography variant="h6" className={classes.title}>
                      <Trans i18nKey="Help using AOW">Help using AOW</Trans>
                    </Typography>
                  </Link>
                </div> */}
                {/* {getAccessButton} */}
                {userOptions}
                <div className={classes.langDiv}>
                  {/* <LanguageIcon /> */}
                  <Button
                    className={classes.langBtn}
                    onClick={() => {
                      handleLanguageChange(
                        checkIfLanguageIs("de") ? "en" : "de"
                      );
                    }}
                  >
                    <Typography
                      variant="h6"
                      className={`${classes.title} ${classes.navitemLanguage}`}
                    >
                      {checkIfLanguageIs("de") ? "English" : "Deutsch"}
                    </Typography>
                    {/* <KeyboardArrowDownIcon className={classes.arrowDownIcon} /> */}
                  </Button>
                  {/* <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={"default-font"}
                  >
                    <MenuItem
                      className={classes.langMenuItemText + " default-font"}
                      onClick={() => {
                        handleClose();
                        handleLanguageChange("en");
                      }}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      className={classes.langMenuItemText + " default-font"}
                      onClick={() => {
                        handleClose();
                        handleLanguageChange("de");
                      }}
                    >
                      Deutsch
                    </MenuItem>
                  </Menu> */}
                </div>
              </div>
            ) : (
              <>
                <div className={classes.searchgrow}>
                  <div className={classes.sectionDesktopSearch}>
                    {searchInside !== true ? (
                      <Paper elevation={0} className={classes.searchbtn}>
                        <InputBase
                          className={classes.input}
                          autoComplete="off"
                          value={getFirstSearchTerm()}
                          placeholder={t("Search Term")}
                          inputProps={{ "aria-label": "search term" }}
                          onFocus={(e) => {
                            e.preventDefault();
                            searchClickHandler();
                          }}
                        />

                        <IconButton
                          className={classes.navbarSearchBtn}
                          onClick={searchClickHandler}
                        >
                          <SearchIcon className={classes.navSearchIcon} />
                        </IconButton>
                      </Paper>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classes.inside}>
                    <Drawer
                      anchor="top"
                      open={searchInside}
                      onClose={searchClickHandler}
                      className={classes.drawercont}
                    >
                      {" "}
                      <div>
                        <div className={classes.drawer}>
                          <div className={classes.drawerText}>
                            <div className={classes.drawerMidText}>
                              {navbarMenuLogo}
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography className={classes.drawerMidText1}>
                                {t("Your Search")}
                              </Typography>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className={classes.navbarLeft}>
                                {/* {getAccessButton} */}
                                {/* {userOptions} */}
                                <div>
                                  {/* <Stack direction="row" spacing={2}>
                                    <div>
                                      <Button
                                        ref={drawerMoreMenuAnchorRef}
                                        id="composition-button"
                                        aria-controls={
                                          openDrawerMoreMenu
                                            ? "composition-menu"
                                            : undefined
                                        }
                                        aria-expanded={
                                          openDrawerMoreMenu
                                            ? "true"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                        className={classes.navMenu}
                                        onClick={handleToggleDrawerMoreMenu}
                                      >
                                        <MenuIcon
                                          className={classes.navMenuLine}
                                        />
                                        {t("More")}
                                      </Button>
                                      <Popper
                                        open={openDrawerMoreMenu}
                                        anchorEl={
                                          drawerMoreMenuAnchorRef.current
                                        }
                                        role={undefined}
                                        placement="bottom-start"
                                        transition
                                        disablePortal
                                      >
                                        {({ TransitionProps, placement }) => (
                                          <Grow
                                            {...TransitionProps}
                                            style={{
                                              transformOrigin:
                                                placement === "bottom-start"
                                                  ? "left top"
                                                  : "left bottom",
                                            }}
                                          >
                                            <Paper>
                                              <ClickAwayListener
                                                onClickAway={
                                                  handleCloseDrawerMoreMenu
                                                }
                                              >
                                                <MenuList
                                                  autoFocusItem={
                                                    openDrawerMoreMenu
                                                  }
                                                  id="composition-menu"
                                                  aria-labelledby="composition-button"
                                                  onKeyDown={
                                                    handleListKeyDownForDrawerMoreMenu
                                                  }
                                                >
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      handleCloseDrawerMoreMenu(
                                                        e
                                                      );
                                                      navigate("/About");
                                                    }}
                                                    className={
                                                      classes.navMenuItem
                                                    }
                                                  >
                                                    {t("About")}
                                                  </MenuItem>
                                                  {/* <MenuItem
                                                    onClick={(e) => {
                                                      handleCloseDrawerMoreMenu(
                                                        e
                                                      );
                                                      navigate("/HowToUse");
                                                    }}
                                                    className={
                                                      classes.navMenuItem
                                                    }
                                                  >
                                                    {t("Help using AOW")}
                                                  </MenuItem> 
                                                  <MenuItem
                                                    onClick={(e) => {
                                                      handleCloseDrawerMoreMenu(
                                                        e
                                                      );
                                                      handleLanguageChange(
                                                        i18n.language === "en"
                                                          ? "de"
                                                          : "en"
                                                      );
                                                    }}
                                                    className={
                                                      classes.navMenuItem
                                                    }
                                                  >
                                                    {i18n.language === "en"
                                                      ? "Deutsche"
                                                      : "English"}{" "}
                                                    {t("Version")}
                                                  </MenuItem>
                                                </MenuList>
                                              </ClickAwayListener>
                                            </Paper>
                                          </Grow>
                                        )}
                                      </Popper>
                                    </div>
                                  </Stack> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={classes.drawerTabs}>
                            <AdvancedSearch
                              isNavbar={true}
                              values={values}
                              close={searchClickHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
                <div className={classes.navbarLeft}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/About">
                      <Typography variant="h6" className={classes.title}>
                        <Trans i18nKey="about">About</Trans>
                      </Typography>
                    </Link>
                  </div>
                  {/* {getAccessButton} */}
                  {userOptions}
                  <div className={classes.langDiv}>
                    {/* <LanguageIcon /> */}
                    <Button
                      className={classes.langBtn}
                      onClick={() => {
                        handleLanguageChange(
                          checkIfLanguageIs("de") ? "en" : "de"
                        );
                      }}
                    >
                      <Typography
                        variant="h6"
                        className={`${classes.title} ${classes.navitemLanguage}`}
                      >
                        {checkIfLanguageIs("de") ? "English" : "Deutsch"}
                      </Typography>
                      {/* <KeyboardArrowDownIcon className={classes.arrowDownIcon} /> */}
                    </Button>
                  </div>
                  <div>
                    <Stack direction="row" spacing={2}>
                      <div>
                        {/* <Button
                          ref={anchorRef}
                          id="composition-button"
                          aria-controls={
                            openMenu ? "composition-menu" : undefined
                          }
                          aria-expanded={openMenu ? "true" : undefined}
                          aria-haspopup="true"
                          className={classes.navMenu}
                          onClick={handleToggleMenu}
                        >
                          <MenuIcon className={classes.navMenuLine} />
                          {t("More")}
                        </Button> */}
                        <Popper
                          open={openMenu}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={handleCloseMenu}
                                >
                                  <MenuList
                                    autoFocusItem={openMenu}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        handleCloseMenu(e);
                                        navigate("/About");
                                      }}
                                      className={classes.navMenuItem}
                                    >
                                      {t("About")}
                                    </MenuItem>
                                    {/* <MenuItem
                                      onClick={(e) => {
                                        handleCloseMenu(e);
                                        navigate("/HowToUse");
                                      }}
                                      className={classes.navMenuItem}
                                    >
                                      {t("Help using AOW")}
                                    </MenuItem> */}
                                    <MenuItem
                                      onClick={(e) => {
                                        handleCloseMenu(e);
                                        handleLanguageChange(
                                          i18n.language === "en" ? "de" : "en"
                                        );
                                      }}
                                      className={classes.navMenuItem}
                                    >
                                      {i18n.language === "en"
                                        ? "Deutsche"
                                        : "English"}{" "}
                                      {t("Version")}
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </Stack>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <NavLink className={classes.logo + " logomobile"} to="/">
              <Typography variant="h6" className={classes.mobileNavbar}>
                {t("AOW")}
              </Typography>
            </NavLink>
            {!drawerMenu ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                className="mobileDrawerIcon"
              >
                <svg
                  width="25"
                  height="19"
                  viewBox="0 0 25 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1H25" stroke="black" strokeWidth="2" />
                  <path d="M0 9.5H25" stroke="black" strokeWidth="2" />
                  <path d="M0 18H25" stroke="black" strokeWidth="2" />
                </svg>
              </IconButton>
            ) : null}
          </div>
          {renderDrawer}
        </Toolbar>
      </div>
    </>
  );
}

export default Navbar;
