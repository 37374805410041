import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Select,
  Button,
  MenuItem,
  Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "../components/Spinner/Spinner";
import axios from "axios";
import * as constants from "../utils/Constants";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  fetchFileName,
  getSearchFiltersFromQuery,
  isNotNullOrUndefined,
  onValidateUserSuccessResponse,
  checkIfLanguageIs,
  isNotNullOrEmptyStr,
  isNotEmptyDOBFilter,
  isNotNullOrEmptyArr,
} from "../utils/helper_functions";
import { useTranslation, Trans } from "react-i18next";
import Toast from "../components/Toast";
import usePrevious from "../utils/hooks/usePrevious";
import SearchValues from "../components/Results/SearchValues";
import { ReactSession } from "react-client-session";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchFilter from "../components/SearchFilter/SearchFilter";
import AppliedFilters from "../components/SearchFilter/AppliedFilters";
import jsesc from "jsesc";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { useStyles } from "./useStyles";

function Results(props) {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const {
    pageNumber,
    prefixFilter,
    searchType,
    size,
    querySortBy,
    sortBy,
    sortType,
    searchForm,
    accessType,
    alphabetSearch,
    queryField,
    alphabetSearchField,
    volume,
    paragraph,
    line,
    page,
  } = getSearchFiltersFromQuery(query);

  var login = query.get("login");

  const [loginSnack, setLoginSnack] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      setLoginSnack(true);
    }
  };

  const [pageCount, setPageCount] = useState(0);
  const [searchResults, setSearchResults] = React.useState();
  const [alphabetDisable, setAlphabetDisable] = React.useState(false);
  const [lastSearch, setLastSearch] = React.useState("");
  const [availableAlphabets, setAvailableAlphabets] = useState([]);
  const prevQuery = usePrevious(query);
  const navigate = useNavigate();

  function setResults(setPageCount, tempResults, size, setSearchResults) {
    if (tempResults !== null && tempResults !== undefined) {
      setPageCount(Math.ceil((tempResults.totalHits ?? 0) / size));
      if (tempResults?.totalHits === 10000) {
        tempResults.totalHits = "10000+";
      }
      setSearchResults(tempResults);
    }
  }

  const emptyAppliedFilters = {
    country: [],
    occupation: [],
    place: [],
    gender: [],
    entryType: [],
    dob: { from: "", to: "" },
    onlyFree: false,
    accessType: [],
  };

  const [appliedFilters, setAppliedFilters] = useState(emptyAppliedFilters);

  // let appliedFilters = { ...emptyAppliedFilters };
  // useEffect(() => {
  //   setAppliedFiltersFromUrlParams();
  // }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const setUrlParamsFromAppliedFilters = (filters) => {
    let newSearchParams = new URLSearchParams(searchParams);

    Object.keys(emptyAppliedFilters).forEach((key) => {
      if (key === "dob") {
        if (
          isNotNullOrEmptyStr(filters?.dob?.from) ||
          isNotNullOrEmptyStr(filters?.dob?.to)
        ) {
          newSearchParams.set(key, filters?.dob?.from + "-" + filters?.dob?.to);
        } else {
          newSearchParams.delete(key);
        }
      } else if (filters[key].length > 0) {
        newSearchParams.set(key, filters[key]);
      } else {
        newSearchParams.delete(key);
      }
    });

    setSearchParams(newSearchParams);
  };

  const setAppliedFiltersFromUrlParams = () => {
    const newAppliedFilters = { ...emptyAppliedFilters };

    Object.keys(newAppliedFilters).forEach((key) => {
      if (false === isNotNullOrUndefined(searchParams.get(key))) return;

      if (key === "dob") {
        let range = searchParams.get(key).split("-");
        let from = parseInt(range[0]);
        let to = parseInt(range[1]);
        if (
          range[0]?.length === 4 &&
          range[1]?.length === 4 &&
          !isNaN(from) &&
          !isNaN(to) &&
          from <= to
        ) {
          newAppliedFilters[key].from = range[0];
          newAppliedFilters[key].to = range[1];
        }
      } else {
        newAppliedFilters[key] = searchParams.get(key).split(",");
      }
    });

    setAppliedFilters({ ...newAppliedFilters });
  };

  const applyDOBFilter = (from, to) => {
    setUrlParamsFromAppliedFilters({
      ...appliedFilters,
      dob: { from, to },
    });
  };

  const handleFilterToggle = (id, value) => () => {
    const currentIndex = appliedFilters[id].indexOf(value);
    const newChecked = [...appliedFilters[id]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setUrlParamsFromAppliedFilters({ ...appliedFilters, [id]: newChecked });
    // setAppliedFilters({ ...appliedFilters, [id]: newChecked });
  };

  const handleFilterRemove = (id, value) => () => {
    if (id === "dob") {
      setUrlParamsFromAppliedFilters({
        ...appliedFilters,
        [id]: { from: "", to: "" },
      });
    } else {
      const currentIndex = appliedFilters[id].indexOf(value);
      const newChecked = [...appliedFilters[id]];

      if (currentIndex !== -1) {
        newChecked.splice(currentIndex, 1);
      }
      setUrlParamsFromAppliedFilters({ ...appliedFilters, [id]: newChecked });
      // setAppliedFilters({ ...appliedFilters, [id]: newChecked });
    }
  };

  const clearAllFilters = () => () => {
    const newAppliedFilters = { ...appliedFilters };
    Object.keys(newAppliedFilters).forEach((key) => {
      newAppliedFilters[key] = key === "dob" ? { from: "", to: "" } : [];
    });
    setUrlParamsFromAppliedFilters(newAppliedFilters);
    // setAppliedFilters(newAppliedFilters);
  };
  // console.log("this is appliedFilters: ", appliedFilters);

  const sortBySelectMenu = {
    relevance: t("Relevance"),
    asc: "A-Z",
    desc: "Z-A",
    // "author-asc": t("Author Ascending"),
    // "author-desc": t("Author Descending"),
  };

  const fieldValue = {
    ALL_FIELDS: "All fields",
    NAME: "Title", // EN: "Artist"  DE: "Künstler*in"
    FURTHER_NAMES: "Person",
    AUTHOR: "Author",
    COUNTRY: "Country",
    OCCUPATION: "Occupation",
  };

  const sizeArray = [10, 50, 100];

  const navigateToValidate = () => {
    // uHistory.replace({
    //   pathname: "/validate",
    //   search: `redirect_url=${encodeURIComponent(
    //     window.location.pathname + window.location.search
    //   )}`,
    // });
    // navigate(
    //   "/validate" +
    //     `redirect_url=${encodeURIComponent(
    //       window.location.pathname + window.location.search
    //     )}`
    // );
    navigate({
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });
  };

  // useEffect(() => {
  //   setAppliedFiltersFromUrlParams();
  // }, [searchParams]);

  useEffect(() => {
    checklogin();

    const onlySearchIdChanged = onlyThisFieldChanged("searchId");
    const onlyPageNoChanged = onlyThisFieldChanged("pageNo");

    if (!(onlySearchIdChanged || onlyPageNoChanged)) {
      logTrackerSearchEvent();
    }

    setAppliedFiltersFromUrlParams();

    if (!onlySearchIdChanged) {
      loadData();
    }

    function onlyThisFieldChanged(field) {
      var onlyFieldChanged = true;
      if (prevQuery) {
        query.forEach(function (_, key) {
          if (key !== field && query.get(key) !== prevQuery.get(key)) {
            onlyFieldChanged = false;
          }
        });
        prevQuery.forEach(function (_, key) {
          if (key !== field && query.get(key) !== prevQuery.get(key)) {
            onlyFieldChanged = false;
          }
        });
      } else {
        onlyFieldChanged = false;
      }
      return onlyFieldChanged;
    }
  }, [query.toString()]);

  // useEffect(() => loadData(), [appliedFilters]);

  const loadData = () => {
    var jtoken = localStorage.getItem("jwtToken");

    const params = new URLSearchParams();

    params.append("size", size);
    params.append("page", pageNumber);
    params.append("accessType", accessType);

    if (sortBy !== null) {
      params.append("sortBy", sortBy);
      if (sortType === true) {
        params.append("ascending", true);
      } else {
        params.append("ascending", false);
      }
    }

    if (searchType === constants.SEARCH_TYPE.ALPHABETICAL_SEARCH) {
      if (alphabetSearch !== null || alphabetSearch !== undefined) {
        setLastSearch("alphabet");
        // url += "&prefix=" + alphabetSearch;
        // url += "&fieldType=" + alphabetSearchField;
        params.append("prefix", alphabetSearch);
        params.append("fieldType", alphabetSearchField);
        setAlphabetDisable(true);
        const urlType =
          queryField === constants.SEARCH_FIELDS.INDEX_LIBRORUM
            ? "index"
            : "article";
        const headers = { Authorization: `Token ${jtoken}` };

        const apiUrl =
          process.env.REACT_APP_SERVER_HOST_NAME +
          `/search/${urlType}/alphabetical?` +
          params.toString();

        console.log("-apiUrl: ", apiUrl);
        trackPromise(
          axios
            .get(apiUrl, {
              headers,
            })
            .then((res1) => {
              const tempResults = res1.data;
              setResults(setPageCount, tempResults, size, setSearchResults);
            })
            .catch((error) => {
              if (error.response.status === 403) {
                navigateToValidate();
              }
              console.log("failed");
            })
        );
      }
    } else {
      setAlphabetDisable(false);
      setLastSearch("article");
      const headers = {
        Authorization: `Token ${jtoken}`,
      };

      let reqFilters = [];
      const newAppliedFilters = { ...emptyAppliedFilters };

      Object.keys(newAppliedFilters).forEach((key) => {
        if (isNotNullOrUndefined(searchParams.get(key))) {
          if (key === "dob") {
            let range = searchParams.get(key).split("-");
            let from = parseInt(range[0]);
            let to = parseInt(range[1]);
            if (
              range[0]?.length === 4 &&
              range[1]?.length === 4 &&
              !isNaN(from) &&
              !isNaN(to) &&
              from <= to
            ) {
              newAppliedFilters[key].from = range[0];
              newAppliedFilters[key].to = range[1];
            }
          } else {
            newAppliedFilters[key] = searchParams.get(key).split(",");
          }
        }
      });
      newAppliedFilters.country.forEach((value) =>
        reqFilters.push({ field: "COUNTRY", value: value })
      );
      newAppliedFilters.occupation.forEach((value) =>
        reqFilters.push({ field: "OCCUPATION", value: value })
      );
      newAppliedFilters.place.forEach((value) =>
        reqFilters.push({ field: "GEOGRAPHIC_DATA", value: value })
      );
      newAppliedFilters.gender.forEach((value) =>
        reqFilters.push({ field: "GENDER", value: value })
      );
      newAppliedFilters.entryType.forEach((value) =>
        reqFilters.push({ field: "ENTRY_TYPE", value: value })
      );
      if (isNotEmptyDOBFilter(newAppliedFilters.dob))
        reqFilters.push({ field: "FILTER_DOB", value: newAppliedFilters.dob });

      let reqBody = { ...searchForm, filters: reqFilters };
      let apiUrl =
        process.env.REACT_APP_SERVER_HOST_NAME + "/search?" + params.toString();

      // console.log("apiUrl: ", apiUrl);
      // console.log(JSON.stringify(reqBody, null, 2));

      trackPromise(
        axios
          .post(apiUrl, reqBody, {
            headers,
          })
          .then((res1) => {
            const tempResults = res1.data;
            if (tempResults == null || tempResults === undefined) return;

            setResults(setPageCount, tempResults, size, setSearchResults);

            if (!query.get("searchId")) {
              const available = getAvailableAlphabets(res1.data);
              setAvailableAlphabets(available);
              const searchId = available.join("");
              if (searchId && searchId.length !== 0) {
                // uHistory.replace({
                //   pathname: "/results",
                //   search: query.toString() + `&searchId=${searchId}`,
                // });
                navigate({
                  pathname: "/results",
                  search: query.toString() + `&searchId=${searchId}`,
                });
              }
            } else {
              const available = query.get("searchId").split("");
              setAvailableAlphabets(available);
            }
          })
          .catch((error) => {
            if (error.response.status === 403) {
              navigateToValidate();
            }
            console.log("failed");
          })
      );
    }
  };

  const getAvailableAlphabets = (data) => {
    if (data?.aggregations?.asMap?.alphaFilter?.buckets) {
      const alphaList = data?.aggregations?.asMap?.alphaFilter?.buckets.map(
        (e) => e?.key
      );
      alphaList.sort();
      const set = new Set(alphaList);
      return Array.from(set).filter((e) => e !== "#");
    }
    return [];
  };

  const selectSize = (e) => {
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", "0");
    uParams.set("size", e.target.value.toString());
    // uHistory.replace({
    //   pathname: "/results",
    //   search: uParams.toString(),
    // });
    navigate({
      pathname: "/results",
      search: uParams.toString(),
    });
  };

  const selectSortby = (e) => {
    const value = e.target.value;
    window.scrollTo(0, 0);
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", "0");
    uParams.set("sortBy", value);
    // uHistory.replace({
    //   pathname: "/results",
    //   search: uParams.toString(),
    // });
    navigate({
      pathname: "/results",
      search: uParams.toString(),
    });
  };

  const handlePageClick = (event, value) => {
    window.scrollTo(0, 0);
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", (value - 1).toString());
    // uHistory.replace({
    //   pathname: "/results",
    //   search: uParams.toString(),
    // });
    navigate({
      pathname: "/results",
      search: uParams.toString(),
    });
  };

  function logTrackerSearchEvent() {
    const aid = localStorage.getItem("aid") ?? "";
    const aname = localStorage.getItem("aname") ?? "";
    const script = document.createElement("script");

    // don't track unauthenticated users
    if (aid === "") return;

    script.type = "text/javascript";
    if (login !== "success") {
      script.innerHTML = `var _ll = _ll || [];
        _ll.push(["trackSearch", {
          "aid": '${aid}',
          "aname": '${aname}',
          "sid": '${ReactSession.get("sid")}',
          "url": '${window.location.href}',
          "sf": false
        }]);
        `;

      document.body.appendChild(script);
    }
  }

  function logTrackerInvestigationEvent(id, name) {
    const aid = localStorage.getItem("aid") ?? "";
    const aname = localStorage.getItem("aname") ?? "";
    const script = document.createElement("script");

    // don't track unauthenticated users
    if (aid === "") return;

    script.type = "text/javascript";
    if (login !== "success") {
      script.innerHTML = `var _ll = _ll || [];
      _ll.push(["trackItemInvestigation", {
        "aid": '${aid}',
        "aname": '${aname}',
        "url": '${window.location.href}',
        "sid": '${ReactSession.get("sid")}',
        "at": "controlled",
        "am": "regular",
        "im": {
          "title": '${jsesc(name)}',
          "dtype": "article",
          "id": 'AOW_${id ?? ""}',
        }
        }]);
        `;

      document.body.appendChild(script);
    }
  }

  const onMoreBtnClick = (e, id, name) => {
    e.preventDefault();
    let element = document.getElementById("paper-" + id);
    element.classList.toggle("accordion-open");
    if (element.classList.contains("accordion-open"))
      logTrackerInvestigationEvent(id, name);
  };

  const tiles = () => {
    const extraUrlParams = new URLSearchParams();

    extraUrlParams.set(
      "search_url",
      encodeURIComponent(window.location.pathname + window.location.search)
    );

    const articleDatapackageType = "Article";
    const indexDatapackageType = "Index";

    const offset = parseInt(pageNumber) * parseInt(size);

    var resultData = searchResults?.searchHits;

    var resultTile = resultData?.map((result, index) => {
      const packageTypeUrlParam = new URLSearchParams();

      packageTypeUrlParam.append("currentIndex", offset + index + 1);

      if (index !== 0) {
        const prev = searchResults?.searchHits[index - 1]?.content;
        const isPrevArticle = prev?.lemma !== null && prev?.lemma !== undefined;
        const prevPackageType = isPrevArticle
          ? articleDatapackageType
          : indexDatapackageType;
        packageTypeUrlParam.append("prevPackageType", prevPackageType);
        packageTypeUrlParam.append(
          "prevPackageFileName",
          fetchFileName(prev?.fileName)
        );
      }
      if (index !== searchResults.searchHits.length - 1) {
        const next = searchResults?.searchHits[index + 1]?.content;
        const isNextArticle = next?.lemma !== null && next?.lemma !== undefined;
        const nextPackageType = isNextArticle
          ? articleDatapackageType
          : indexDatapackageType;
        packageTypeUrlParam.append("nextPackageType", nextPackageType);
        packageTypeUrlParam.append(
          "nextPackageFileName",
          fetchFileName(next?.fileName)
        );
      }

      let abstractContent =
        isNotNullOrUndefined(result?.highlightFields?.body) &&
        result?.highlightFields?.body?.length > 0
          ? result?.highlightFields?.body[0]
          : result?.content?.body?.split("Biogramm")[1]?.split("</p>")[0];

      let keywords =
        i18n.language === "de"
          ? result?.content?.deKeywords
          : result?.content?.enKeywords;

      let bornIn = keywords?.placeOfBirth?.split(";")[0];
      let bornAt = keywords?.dateOfBirth?.split(".")[0];
      // extract year from dateOfBirth (4 digits)
      let bornYear = bornAt?.match(/\d{4}/g);

      let born = [bornIn, bornYear].filter(Boolean).join(", ");

      let pageUrl =
        result?.content?.entryType === "Person" ? "/artist" : "/entry";
      pageUrl += "/" + result?.content?.id;

      return (
        <React.Fragment key={result.content.id}>
          <Grid item md={12} xs={12}>
            <Link
              to={{
                pathname: pageUrl,
                search:
                  packageTypeUrlParam.toString() +
                  "&" +
                  extraUrlParams.toString(),
              }}
              color="inherit"
            >
              <Paper
                id={"paper-" + result.content.id}
                variant="outlined"
                elevation={0}
                className={classes.resultpaper + " accordion "}
              >
                <div className={classes.resultPaperContainer}>
                  <div className={classes.resultTileLeftContent}>
                    <div className={classes.resultTileTitle}>
                      <Typography variant="h2">
                        {result?.content?.name ? result?.content?.name : ""}
                      </Typography>
                      {result?.content?.name &&
                      result?.content?.entryType !== "Person" ? (
                        <Typography variant="h6">
                          {t(result?.content?.entryType)}
                        </Typography>
                      ) : null}
                    </div>

                    {keywords ? (
                      <>
                        {keywords.dateOfBirth || keywords.placeOfBirth ? (
                          <Typography
                            variant="body"
                            className={classes.bodycontent}
                            gutterBottom
                          >
                            <Trans i18nkey="">Born</Trans>{" "}
                            <Trans i18nkey="">in</Trans> {born}
                          </Typography>
                        ) : null}

                        {keywords.dateOfDeath || keywords.placeOfDeath ? (
                          <Typography
                            variant="body"
                            className={classes.bodycontent}
                            gutterBottom
                          >
                            <Trans i18nkey="">Died</Trans>{" "}
                            <Trans i18nkey="">in</Trans>{" "}
                            {keywords.placeOfDeath?.split(";")[0]}
                            {keywords.dateOfDeath && keywords.placeOfDeath
                              ? ", "
                              : null}
                            {keywords.dateOfDeath?.split(".")[0]}
                          </Typography>
                        ) : null}

                        {isNotNullOrEmptyArr(keywords.occupation) ? (
                          <Typography
                            variant="body"
                            className={classes.bodycontent}
                            gutterBottom
                          >
                            {keywords.occupation.map((occu) => occu).join(", ")}
                          </Typography>
                        ) : null}
                      </>
                    ) : null}

                    {result?.content?.author ? (
                      <Typography
                        variant="body"
                        className={classes.bodycontent}
                        gutterBottom
                      >
                        <Trans i18nkey="">Author</Trans>:{" "}
                        {result?.content?.author}
                      </Typography>
                    ) : null}
                  </div>
                  <div className={classes.resultTileRightContent}>
                    <Button
                      variant="outlined"
                      className={classes.moreBtn}
                      onClick={(e) => {
                        onMoreBtnClick(
                          e,
                          result.content.id,
                          result.content.name
                        );
                      }}
                    >
                      <KeyboardArrowDownIcon className={"moreIcon"} />
                    </Button>
                  </div>
                </div>
                <div className={classes.abstractContent + " abstractContent"}>
                  <Typography
                    variant="body"
                    className={classes.abstractContentText}
                    dangerouslySetInnerHTML={{ __html: abstractContent }}
                    gutterBottom
                  />
                </div>
              </Paper>
            </Link>
          </Grid>
        </React.Fragment>
      );
    });

    return resultTile;
  };

  const classes = useStyles();

  let resultCount = (
    <>
      {parseInt(pageNumber) * size + 1}-
      {parseInt(pageNumber) * size + size < parseInt(searchResults?.totalHits)
        ? parseInt(pageNumber) * size + size
        : searchResults?.totalHits}
      {" " + t("of") + " "}
    </>
  );

  return (
    <div className="body-container">
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      <Navbar isHomepage={false} />
      <div className="main">
        <section className="section">
          <Container className="container">
            <Box display="flex" justifyContent="left" alignItems="center">
              <Link
                to={{
                  pathname: `/`,
                }}
                color="inherit"
                className={classes.backToHome}
              >
                <ArrowBackIcon />
                <Typography className={classes.backToHomeText}>
                  <Trans i18nkey="">Home</Trans>
                </Typography>
              </Link>
            </Box>
            <div className={"results " + classes.toparea}>
              {searchForm.search.filter((value) => value.value != null)
                .length !== 0 ? (
                <>
                  <Typography className={classes.resultSearch}>
                    {t("searchResultsFor")}
                  </Typography>
                  {searchForm.search.map((e, idx) => (
                    <SearchValues
                      key={"sv" + idx}
                      searchFieldI18nKey={fieldValue[e?.field]}
                      searchField={fieldValue[e?.field]}
                      searchValue={e?.value}
                      searchQualifier={e?.searchQualifier}
                    />
                  ))}
                </>
              ) : null}
            </div>
            <Grid container spacing={3}>
              <Grid item md={3}>
                <SearchFilter
                  data={{
                    ...(checkIfLanguageIs("de")
                      ? searchResults?.deAggregations
                      : searchResults?.enAggregations),
                    dateOfBirthAggregation:
                      searchResults?.dateOfBirthAggregation,
                  }}
                  appliedFilters={appliedFilters}
                  handleFilterToggle={handleFilterToggle}
                  applyDOBFilter={applyDOBFilter}
                />
              </Grid>
              <Grid item md={9}>
                <Grid container spacing={2} className="resultdata">
                  <Grid item xs={12} md={12}>
                    <div className={classes.wrapper}>
                      <div className={classes.resCountWrapper}>
                        <Typography
                          variant="body2"
                          className={"gb " + classes.textres}
                        >
                          {searchResults?.totalHits ? resultCount : null}
                          {searchResults?.totalHits ?? "0"}{" "}
                          <Trans i18nKey="results">results</Trans>
                        </Typography>
                      </div>
                      <div className={classes.sectionMobile}>
                        <Paper
                          square="true"
                          elevation={0}
                          className={classes.filterpaper}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="h6"
                            className="gb"
                            style={{
                              minWidth: "max-content",
                              marginRight: "10px",
                            }}
                          >
                            <Trans i18nKey="sortBy">Sort By</Trans>:
                          </Typography>
                          <Select
                            native
                            fullWidth
                            name="sortby"
                            onChange={selectSortby}
                            value={
                              querySortBy === null || querySortBy === undefined
                                ? "relevance"
                                : querySortBy
                            }
                          >
                            {Object.keys(sortBySelectMenu).map((key) => {
                              return (
                                <option key={key} value={key}>
                                  {sortBySelectMenu[key]}
                                </option>
                              );
                            })}
                          </Select>
                        </Paper>
                      </div>
                      <div
                        className={classes.sectionDesktop}
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div style={{ marginRight: "30px" }}>
                          <Typography
                            variant="body2"
                            className={"gb " + classes.textres}
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Trans i18nKey="resultsPerPage">
                              Results per page
                            </Trans>
                            :
                            <Select
                              name="size"
                              value={size}
                              style={{ marginLeft: "10px" }}
                              onChange={selectSize}
                              className={classes.select + " gm"}
                            >
                              {sizeArray.map((sizeValue, idx) => (
                                <MenuItem
                                  key={"msv" + idx}
                                  value={sizeValue}
                                  selected
                                  classes={{ selected: classes.selected }}
                                  className={classes.menuItem + " gm"}
                                >
                                  {sizeValue}
                                </MenuItem>
                              ))}
                            </Select>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            className={"gb " + classes.textres}
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Trans i18nKey="sortBy">Sort By</Trans>:
                            <Select
                              name="sortby"
                              onChange={selectSortby}
                              className={classes.select + " gm"}
                              value={
                                querySortBy === null ||
                                querySortBy === undefined
                                  ? "relevance"
                                  : querySortBy
                              }
                            >
                              {Object.keys(sortBySelectMenu).map((key) => {
                                return (
                                  <MenuItem
                                    key={"mi" + key}
                                    value={key}
                                    selected
                                    classes={{ selected: classes.selected }}
                                    className={classes.menuItem + " gm"}
                                  >
                                    {sortBySelectMenu[key]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <AppliedFilters
                      appliedFilters={appliedFilters}
                      handleFilterRemove={handleFilterRemove}
                      clearAllFilters={clearAllFilters}
                    />
                  </Grid>
                  {tiles()}
                  <Grid item md={12} xs={12}>
                    <div className={classes.pagination}>
                      {isNotNullOrUndefined(searchResults) &&
                      searchResults?.totalHits !== 0 ? (
                        <Pagination
                          className={classes.pagebtn}
                          shape="rounded"
                          size="large"
                          count={pageCount}
                          page={pageNumber + 1}
                          onChange={handlePageClick}
                          color="primary"
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
      <OuterSpinner />
      <Footer />
    </div>
  );
}

export default Results;
