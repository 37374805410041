export const PDFstyles = {
  page: {
    fontFamily: "Montserrat",
    fontSize: 12,
    lineHeight: 1.45,
    paddingHorizontal: 50,
    paddingBottom: 65,
  },
  logo: {
    height: 300,
  },
  content: {
    "& > p": {
      // fontFamily: "times",
      fontSize: 18,
    },
  },
  header: {
    width: "100%",
    fontSize: 10,
    paddingTop: 15,
    paddingBottom: 5,
    marginTop: 10,
    marginBottom: 25,
    borderBottom: "1px solid #000",
  },
  hr: {
    width: "100%",
    height: "2px",
    background: "red",
    borderBottom: "1px solid #000",
  },
  footer: {
    fontSize: 10,
    borderTop: "1px solid #000",
    paddingTop: 10,
    paddingBottom: 10,
    position: "absolute",
    bottom: 20,
    left: 50,
    right: 50,
    textAlign: "center",
  },
  rowSpaceBetween: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  h1: {
    fontFamily: "Montserrat",
    fontWeight: "medium",
    fontSize: 30,
    wordWrap: "anywhere",
    paddingRight: 50,
  },
  h2: {
    fontFamily: "Montserrat",
    fontWeight: "medium",
    fontSize: 18,
    wordWrap: "anywhere",
  },
  h3: {
    display: "block",
    fontWeight: 500,
    fontSize: 18,
    width: "80%",
  },
  h6: {
    fontFamily: "Montserrat",
    fontWeight: "medium",
    fontSize: 16,
    marginTop: 10,
  },
  body1: {
    fontFamily: "Merriweather",
    fontSize: 11,
    lineHeight: 1.6,
    textAlign: "justify",
  },
  body: {
    paddingBottom: 30,
  },
  format: {
    fontWeight: 500,
    fontSize: 16,
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bold",
  },
  sc: {
    // smallcaps
    fontVariant: "small-caps",
  },

  link: {
    color: "#007596",
    // textDecoration: "underline",
    // textDecorationOffset: "2px",
  },

  textNew: {
    fontFamily: "Montserrat",
    fontWeight: "medium",
    color: "black",
    fontSize: 10,
    background: "#F7F7F7",
    border: "1px solid #F7F7F7",
    padding: 1,
    marginLeft: 1,
  },
  textFree: {
    fontFamily: "Montserrat",
    fontWeight: "medium",
    color: "white",
    fontSize: 10,
    background: "#007596",
    border: "1px solid #F7F7F7",
    padding: "0.5 0.8",
    marginLeft: 1,
  },

  section: {
    // padding: "30px",
  },
  toparea: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "50px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titlearea: {
    marginTop: 10,
    marginBottom: 10,
  },
  cover: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 60,
    marginTop: 50,
  },
  ovdataTitle: {
    marginTop: "16px",
  },
  icons: {
    borderLeft: "1px solid #dddddd",
    borderRadius: "0",
  },
  icon: {
    width: "39px",
    height: "39px",
    backgroundColor: "#005d781a !important",
    marginLeft: "3px!important",
  },
  artistDetail: {
    fontFamily: "Montserrat",
    fontSize: 12,
    marginTop: 5,
  },
  artistInfo: {
    fontFamily: "Montserrat",
    fontSize: 16,
    display: "flex",
  },
  lineNumberToggleButton: {
    height: "45px",
    marginRight: "15px",
    width: "153px !important",
    textTransform: "initial !important",
  },
  // abtn: {
  //     fontFamily: ['Gotham'],
  //     fontSize: '16px',
  //     margin: '4.5px 0px',
  //     '&.MuiButtonGroup-grouped': {
  //         minWidth: '30px',
  //         minHeight: '30px',
  //         maxWidth: '30px',
  //         maxHeight: '30px',
  //     },
  //     '&.MuiButtonGroup-groupedTextPrimary:not(:last-child)': {
  //         border: 'none!important',
  //     },
  // },
  // alphabetBtn: {
  //     display: 'flex',
  //     flexDirection: 'column',
  // },
  resultpaper: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    padding: 20,
  },
  displayContent: {
    marginTop: 25,
  },
  sectionMain: {
    marginTop: 30,
    wordWrap: "break-word",
  },
  overviewDataDiv: { marginTop: 20 },
  contentDiv: { marginTop: 60 },

  imgbox: {
    height: "50%",
  },
  bottomStickyGroupDiv: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "flex-end",
    transition: "opacity 0.4s",
  },
  hidden: {
    opacity: "0",
  },
  mesBox: {
    justifyContent: "center",
    margin: "65px",
    padding: "65px",
    display: "flex",
  },
  switchclick: {
    backgroundColor: "#00495D !important",
  },
  mainTitle: {
    display: "block",
    fontWeight: "medium",
    fontSize: 26,
    marginBottom: 20,
    // float: "right",
  },
  mainTitle1: {
    // color: "#007596",
    lineHeight: 1.6,
  },
  tableTextContent: {
    display: "flex",
    marginBottom: 10,
    width: "100%",
  },
  // switchContent: {
  //   justifyContent: "flex-end !important",
  // },
  // textExpand: {
  //   color: "#007596",
  //   display: "none",
  //   textAlign: "right !important",
  //   borderBottom: "0px solid white !important",
  //   justifyContent: "flex-start !important",
  //   // width: "120%",
  //   alignItems: "left",
  //   "& svg": {
  //     display: "none",
  //     justifyContent: "flex-start",
  //   },
  // },
  exaAbbr: {
    // width: "24%",
    color: "#007596",
  },
  subTitle: {
    display: "block",
    fontWeight: 500,
    fontSize: 20,
    width: "80%",
  },
  boxSubTitle: {
    // color: "#007596",
    marginLeft: "18px",
    // "&  a": {
    //   padding: "10px !important",
    //   color: "#007596",
    // },
  },
  ContentTitle: {
    fontFamily: "Montserrat",
    fontWeight: "medium",
  },
  mainBox: {
    width: "100%",
    // color: "#007596",
    // fontFamily: "Montserrat-Regular",
  },
  contents: {
    marginTop: 30,
    // borderTop: "1px solid rgba(0, 0, 0, 0.2)",
    // borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box",
    paddingVertical: 20,
    fontSize: 15,
    // height: "400px",
  },

  dialogContainer: {
    width: "100% !important",
  },
  dialogPaper: {
    maxWidth: "780px !important",
    borderRadius: "0px !important",
  },
  paddingHorizontal0: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
};
