import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  toparea: {
    marginTop: "40px",
    marginBottom: "15px",
    display: "flex",

    alignItems: "baseline",
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
  findpaper: {
    width: "100%",
    padding: "2px 3px 2px 25px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-around",
    border: "0.5px solid #A4A4A4",
    [theme.breakpoints.up("md")]: {
      minWidth: "355px",
      maxWidth: "355px",
    },
  },
  filterpaper: {
    width: "100%",
    padding: "12px 20px 13px 25px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#ffffff",
    border: "0.5px solid #A4A4A4",
    "& select": {
      fontSize: "16px",
      fontFamily: "Gotham-Book",
    },
  },
  icons: {
    borderLeft: "1px solid #dddddd",
    borderRadius: "0",
  },
  icon: {
    width: "39px",
    height: "39px",
    backgroundColor: "#005d781a !important",
    marginLeft: "3px!important",
  },
  alphabetBtn: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "25px",
  },
  abtn: {
    fontFamily: "'Gotham-Medium'",
    fontSize: "16px",

    minWidth: "33px",
    minHeight: "33px",
    maxWidth: "33px",
    maxHeight: "33px",
    border: "none!important",
  },
  btnContainer: {
    minWidth: "33px",
    minHeight: "33px",
    maxWidth: "33px",
    maxHeight: "33px",
    marginTop: "3px",
    backgroundColor: "#005d781a",
    "&:not(:last-child)": {
      marginRight: "3px",
    },
    "&:hover": {
      backgroundColor: "#007596!important",
      "& $abtn": {
        color: "#ffffff!important",
      },
    },
  },
  resultpaper: {
    background: "#FFFFFF",
    borderRadius: "0px",
    padding: "20px",
    marginBottom: "10px",
    "&:hover": {
      background: "#f0f0f0!important",
    },
  },
  resultPaperContainer: {
    display: "flex",
  },
  resultTileLeftContent: { flex: "1" },
  resultTileTitle: { marginBottom: "20px" },

  textres: {
    fontSize: "18px",
    marginBottom: "20px",
    fontFamily: "Gotham-Medium",
    color: "#000000",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "30px",
    "& select": {
      fontSize: "16px",
      fontFamily: "Gotham-Book",
      height: "30px",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "Gotham-Book",
      fontSize: "16px",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  bodycontent: {
    marginBottom: "10px",
    display: "-webkit-box",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      "-webkit-line-clamp": "1",
    },
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  },
  abstractContentText: {
    lineHeight: "1.5",
    marginBottom: "10px",
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
  },
  input: {
    marginRight: "10px",
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Gotham-Book","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
      opacity: "unset!important",
      textOverflow: "ellipsis",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  },

  sectionMobile: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  pagebtn: {
    "& button": {
      width: "40px !important",
      height: "40px !important",
      color: "#007596",
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#007596",
    },
  },
  select: {
    width: "max-content !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "26px !important",
    color: "#121212 !important",
    marginLeft: "10px",
    paddingLeft: "20px",
    paddingRight: "10px",
    border: "0.5px solid #000000;",
    "& .MuiSelect-select": {
      fontWeight: "500 !important",
      fontSize: "16px !important",
      lineHeight: "26px !important",
    },
    "& svg": {
      color: "#121212 !important",
      marginLeft: "10px",
    },
  },
  menuItem: {
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "26px !important",
  },
  selected: {
    backgroundColor: "transparent !important",
  },
  resCountWrapper: {
    "& p": {
      display: "inline-block",
      width: "max-content",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      textAlign: "center",
    },
  },
  wrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      alignItems: "center",
      width: "100%",
    },
  },
  searchPara: {
    display: "flex",
    justifyContent: "space-between",
  },
  fadeOut: {
    position: "relative",
    boxShadow: "none !important",
    "&:after": {
      content: '""',
      height: "60%",
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      background: "linear-gradient(to bottom, rgba(255,255,255,0.7), #f6f6f8)",
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        height: "70%",
      },
    },
  },
  resultSearch: {
    marginRight: "20px",
    display: "flex",
    fontSize: "16px",
  },
  backToHome: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  backToHomeText: {
    marginLeft: "10px",
    fontWeight: "600",
  },
  moreBtn: {
    marginRight: "10px",
    minWidth: "50px",
    width: "50px",
    height: "50px",
    color: "#007596",
    border: "1px solid #007596",
  },
  abstractContent: {
    marginTop: "10px",
  },
}));
