import * as React from "react";
import clsx from "clsx";
import { styled } from "@mui/system";
import { useSwitch } from "@mui/base/SwitchUnstyled";
import { Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const blue = {
  700: "#0059B2",
};
const grey = {
  400: "#DDE9EE",
  800: "#2F3A45",
};
const SwitchRoot = styled("span")`
  display: inline-block;
  position: relative;
  width: 70px;
  height: 36px;
  padding: 8px;
  color: "#DDE9EE";
  background-color: #dde9ee;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

const SwitchInput = styled("input")`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled("span")`
  position: absolute;
  display: block;
  background-color: #007596;
  width: 32px;
  height: 30px;
  border-radius: 3px;
  top: 3px;
  left: 3px;
  //padding:1px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &.focusVisible {
    background-color: #79b;
  }
  &.checked {
    transform: translateX(24px);
    left: 11px;
  }
`;

const SwitchTrack = styled("span")(
  ({ theme }) => `
  background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[400]};
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
`
);

export default function MUISwitch(props) {
  const useStyles = makeStyles((theme) => ({
    switch: { marginRight: "10px", marginLeft: "10px" },
    text: { fontFamily: "Gotham-Book, tllmedium, sans-serif" },

    text1: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };
  return (
    <div className={classes.text1}>
      <Typography variant="h6" align="center" className={classes.text}>
        {props.leftswitch}
      </Typography>
      <SwitchRoot className={clsx(stateClasses) + " " + classes.switch}>
        <SwitchTrack>
          <SwitchThumb className={clsx(stateClasses)} />
        </SwitchTrack>
        <SwitchInput
          {...getInputProps()}
          aria-label="Demo switch"
          onClick={(e) => {
            props.switchClick(e, props.name);
          }}
        />
      </SwitchRoot>
      <Typography variant="h6" align="center" className={classes.text}>
        {props.rightswitch}
      </Typography>
    </div>
  );
}
