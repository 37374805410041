import React from "react";
import parse, { domToReact } from "html-react-parser";
import { Text, StyleSheet } from "@react-pdf/renderer";
import { PDFstyles } from "./PDFstyles";

const options = {
  trim: false,
  replace: (domNode) => {
    if (!domNode) return null;
    var { attribs, children, name } = domNode;

    switch (name) {
      case "p":
        return children ? <Text>{domToReact(children, options)}</Text> : null;
      case "strong":
      case "b":
      case "bold":
        return (
          <Text style={{ fontWeight: "bold" }}>
            {domToReact(children, options)}
          </Text>
        );
      case "italic":
      case "i":
        return (
          <Text style={{ fontStyle: "italic" }}>
            {domToReact(children, options)}
          </Text>
        );
      // case "h1":
      //   return <Text style={classes.h1}>{children}</Text>;
      case "sc":
        return (
          <Text
            style={{
              fontVariant: "small-caps",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 9,
            }}
          >
            {domToReact(children, options)}
          </Text>
        );
      default:
        return children?.length ? (
          <Text>{domToReact(children, options)}</Text>
        ) : null;
    }
  },
};

export const Typography = (props) => {
  // const classes = StyleSheet.create(PDFstyles);
  if (props.children) {
    const children = props.children;
    const style = props.style;
    // clone props, remove children
    // props = { ...props };
    // delete props.children;
    // const nodes = domToReact(children, options);
    if (children) {
      // console.log("children", children);
      return <Text style={style}>{children}</Text>;
    } else return null;
  }
  // else {
  //   console.log("no children");
  //   return <Text></Text>;
  // }

  const parseHTML = (htmlString) => {
    if (!htmlString) return null;
    try {
      // if (typeof htmlString === "string") {
      return parse(htmlString, options);
      // } else {
      //   return htmlString;
      // }
    } catch (error) {
      console.error(error);
      return htmlString;
    }
  };

  if (props.dangerouslySetInnerHTML) {
    var html = props.dangerouslySetInnerHTML.__html;
    // replace whitespace and \n from html with single space
    html = html.replace(/\s+/g, " ");

    const parsedNodes = parseHTML(html);
    // console.log(parsedNodes);
    // console.info(html);

    // clone, delete props.dangerouslySetInnerHTML;
    props = { ...props };
    delete props.dangerouslySetInnerHTML;

    return <Text {...props}>{parsedNodes}</Text>;
  }
};
