import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { trackPromise } from "react-promise-tracker";
import ArtistName from "../components/ArtistName";
import BackToHome from "../components/Buttons/BackToHome";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { Spinner } from "../components/Spinner/Spinner";
const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: "35px",
    fontWeight: "400 !important",
    fontFamily: "Gotham-medium !important",
    marginBottom: "30px !important",
  },
  overx: {
    backgroundColor: "white !important",
  },
  nameGrid: {
    fontFamily: "Gotham-medium",
    fontSize: "30px",
  },
}));
function AddedUpdate() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [RecentRecords, setRecentRecords] = React.useState([]);
  useEffect(() => {
    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            "/recentEntry/recentEntryArray"
        )
        .then((response) => {
          setRecentRecords(response.data);
        })
        .catch((err) => {
          console.log("API request Error: ", err);
        })
    );
  }, []);

  let gridArtist = [];
  for (let index = 0; index < RecentRecords?.length; index += 2) {
    gridArtist.push(RecentRecords?.slice(index, index + 2));
  }
  gridArtist = gridArtist.map((arr) => {
    return arr.length > 0 ? (
      <>
        <Grid item md={6}>
          <ArtistName
            name={arr[0]?.name}
            id={arr[0]?.id}
            isNew={arr[0]?.isNew}
            isArtist={arr[0]?.type === "Person"}
          />
          <Divider sx={{ borderBottomWidth: 2 }} />
        </Grid>
        {arr.length > 1 ? (
          <Grid item md={6}>
            <ArtistName
              name={arr[1]?.name}
              id={arr[1]?.id}
              isNew={arr[1]?.isNew}
              isArtist={arr[1]?.type === "Person"}
            />
            <Divider sx={{ borderBottomWidth: 2 }} />
          </Grid>
        ) : null}
      </>
    ) : null;
  });

  return (
    <>
      <div className={"body-container " + classes.overx}>
        <Navbar isHomepage={false} />
        <section className="section">
          <Container className="container">
            <div>
              <BackToHome />
              <Typography variant="h5" className={classes.mainTitle}>
                <Trans i18nKey="recentPageTitle">
                  Recently added or updated entries
                </Trans>
              </Typography>
            </div>
            <Grid container spacing={4} className={classes.nameGrid}>
              {gridArtist}
            </Grid>
          </Container>
        </section>
        <OuterSpinner />
        <Footer />
      </div>
    </>
  );
}
export default AddedUpdate;
