import React from "react";
import {
  makeStyles,
  Paper,
  InputBase,
  FormControl,
  Select,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SearchButton from "./SearchButton";
import CustomDropDown from "./CustomDropDown";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  searchpaper: {
    marginBottom: "9px",
    display: "flex",
    alignItems: "center",
    width: 620,
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "0px",
  },
  searchbtn: {
    marginLeft: "43px !important",
  },
  qualifierDropdown: {
    fontFamily: "Gotham",
    paddingLeft: "20px",
    fontWeight: "300",
    lineHeight: "20px",
    textAlign: "center",
    padding: "35px",
    fontSize: "17px",
    paddingTop: "0px",
    //display:"flex",
    lineHeight: "42px",
    position: "absolute",
  },
  textsearchlabel: {
    fontFamily: "Gotham-book",
    marginLeft: "20px",
    padding: "10px",
    fontSize: "15px",
  },

  searchDiv: {
    display: "flex",
    width: "100%",
    height: "100%",
    "&:hover": {
      border: "1px solid #007596",
    },
    "&:focus-within": {
      backgroundColor: "#DDE9EE !important",
      border: "1px solid #007596;",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    },
  },
  input: {
    marginLeft: "30px",
    flex: 2,
    clear: "left",
  },
  category: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "21px",
    },
  },
  delete: {
    marginLeft: "10px",
    marginRight: "-50px",
    border: "1px solid rgba(32, 33, 34, 0.2)",
    color: "black",
    top: "12px",
    height: "50px",
    "&:hover": { backgroundColor: "#DDE9EE !important" },
  },
}));

const SearchList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const fvalue = ["NAME", "ALL_FIELDS", "AUTHOR", "COUNTRY", "OCCUPATION"];
  const fname = ["Title", "All fields", "Author", "Country", "Occupation"];
  const qvalue = ["AND", "OR", "NOT"];
  var bgcolor = "#000000";
  if (props.variant === "dark") {
    bgcolor = "#3c3c3c";
  } else {
    bgcolor = "#000000";
  }
  return props.searchDetails.map((val, idx) => {
    let content = "";
    if (val.index >= 0) {
      content = (
        <div className={classes.root} key={val.index}>
          <Paper elevation={0} className={classes.searchpaper}>
            <CustomDropDown
              label={t("Qualifier")}
              name="searchQualifier"
              row={idx}
              title={t("Operator")}
              optionsList={qvalue}
              onChange={(e) => props.handleChange(e, idx)}
              nameList={qvalue}
              defaultSelected={val.searchQualifier}
              className={"qualifierDropdown"}
            ></CustomDropDown>

            <CustomDropDown
              label={t("Field")}
              name="field"
              title={t("Category")}
              row={idx}
              optionsList={fvalue}
              onChange={(e) => props.handleChange(e, idx)}
              nameList={fname}
              defaultSelected={val.field ? val.field : "NAME"}
            ></CustomDropDown>

            <div className={classes.searchDiv}>
              <div className={classes.searchInput}>
                <label className={classes.textsearchlabel}>
                  {t("Search Term")}
                </label>
                <InputBase
                  className={classes.input}
                  data-id={idx}
                  autoComplete="off"
                  onChange={(e) => props.handleChange(e, idx)}
                  value={val.value}
                  name="value"
                  // placeholder={t("Search Term")}
                  // inputProps={{ "aria-label": "search term" }}
                />
              </div>
              {props.searchDetails.length - 1 === idx ? (
                <div className="searchbtn">
                  <SearchButton close={props.close} />
                </div>
              ) : (
                ""
              )}
            </div>
          </Paper>
          <IconButton
            className={classes.delete + " delete-searchrow-btn"}
            disableRipple
            onClick={() => props.delete(val)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      );
    }
    return content;
  });
};
export default SearchList;
