import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { isNotNullOrEmptyStr } from "../../utils/helper_functions";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

import { PDFstyles } from "./PDFstyles";

const ArtistName = ({ id, name, isNew, isFree, isArtist, subtext }) => {
  // get base url
  const baseUrl = window.location.origin;
  const { t } = useTranslation();

  const itemUrl = baseUrl + (isArtist ? "/artist/" : "/entry/") + id;

  const classes = StyleSheet.create(PDFstyles);

  return isNotNullOrEmptyStr(name) ? (
    <>
      <View style={classes.artistNameDiv}>
        <Text href={itemUrl} style={classes.link}>
          <View style={classes.nameBox}>
            <Text style={classes.body1}>
              {name}
              {/* {isNew && (
                <View style={classes.textNew}>
                  <Text>{t("New")}</Text>
                </View>
              )}
              {isFree && (
                <View style={classes.textFree}>
                  <Text>{t("Free")}</Text>
                </View>
              )} */}
            </Text>
          </View>
        </Text>
        <View style={classes.subtextBox}>
          <Text
            style={{
              ...classes.subtext,
              color: "#000000",
            }}
          >
            {subtext}
          </Text>
        </View>
      </View>

      {/* <div>
        <Link to={"/entry/" + id}>
          <Box className={classes.nameBox}>
            <Typography
              className={classes.nameText}
              style={{
                fontSize: "12px",
                color: "#007596",
                // fontWeight: "bold",
              }}
            >
              {name}
            </Typography>
          </Box>
        </Link>
      </div> */}
    </>
  ) : null;
};

export default ArtistName;

const NewLabel = () => {
  const { t } = useTranslation();
  const classes = StyleSheet.create(PDFstyles);
  return <Text style={classes.textNew}>{t("New")}</Text>;
};

const FreeLabel = () => {
  const { t } = useTranslation();
  const classes = StyleSheet.create(PDFstyles);
  return <Text style={classes.textFree}>{t("Free")}</Text>;
};
