import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { toBePartiallyChecked } from "@testing-library/jest-dom/dist/matchers";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ExpandableContent from "react-expandable-content/dist/ExpandableContent";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import ArtistName from "../components/ArtistName";
import BackToHome from "../components/Buttons/BackToHome";

import CiteButton from "../components/Buttons/CiteButton";
import DownloadButton from "../components/Buttons/DownloadButton";
import ShareButton from "../components/Buttons/ShareButton";

import ConnectedPlaces from "../components/ConnectedPlaces";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PayWall from "../components/Paywall";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { Spinner } from "../components/Spinner/Spinner";
import MUISwitch from "../components/UseSwitchesCustom";
import { ArticleTitles } from "../utils/Constants";
import {
  getPlacesFromGeoRegions,
  isNotNullOrEmptyArr,
  isNotNullOrEmptyStr,
} from "../utils/helper_functions";
import useAuth from "../utils/hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    display: "flex",
    width: "100%",
    "& .iconButton": {
      minHeight: "50px",
      minWidth: "50px",
      border: "1px solid #005D78",
      background: "#E5EFF1",
    },
    "& .iconButton svg": {
      height: "50px",
    },
  },
  artistDetail: {
    fontFamily: "Gotham-book",
    fontSize: "16px",
    marginTop: "5px",
  },
  mainTitle: {
    lineHeight: "50px",
    fontSize: "40px",
    fontFamily: "Gotham-medium",
    fontWeight: "500",
  },
  grpTitle: {
    fontSize: "20px",
    fontFamily: "Gotham-medium",
    fontWeight: "500",
    lineHeight: "40px",
    marginLeft: "10px",
  },
  btn: {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50px",
      maxHeight: "50px",
      minWidth: "50px",
      minHeight: "50px",
    },

    "& button.iconButton": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
      minWidth: "100% !important",
      minHeight: "100% !important",
    },
  },
  boxDesign: {
    background: "rgba(0, 0, 0, 0.03)",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box",
    padding: "10px",
    color: "#007596",
    lineHeight: "27px",
    fontWeight: "400",
    fontSize: "15px",
    height: "250px",
    width: "73%",
  },
  subtitle: {
    marginLeft: "20px",
  },
  container: {
    marginLeft: "3px",
  },
  ovdataTitle: {
    marginTop: "16px",
  },
  mesBox: {
    justifyContent: "center",
    margin: "65px",
    padding: "65px",
    display: "flex",
  },
  ContentTitle: {
    color: "black !important",
    fontSize: "20px",
  },

  overx: {
    marginLeft: "10px",
  },

  //Article Content
  contentDiv: { marginTop: "60px" },
  articleTitle: {
    display: "block",
    fontWeight: "600",
    fontSize: "26px",
    marginBottom: "30px",
  },
  tableTextContent: {
    display: "flex",
    marginBottom: "15px",
    width: "100%",
  },
  subTitle: {
    display: "block",
    fontWeight: "500",
    fontSize: "20px",
    width: "80%",
  },
  textExpand: {
    color: "#007596",
    display: "none",
    textAlign: "right !important",
    borderBottom: "0px solid white !important",
    justifyContent: "flex-start !important",
    alignItems: "left",
    "& svg": {
      display: "none",
      justifyContent: "flex-start",
    },
  },
  content: {
    fontFamily: "times",
    fontSize: "18px",
    lineHeight: "26px",
  },
  nameGrid: {
    marginLeft: 0,
  },
}));
function Entry() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const { promiseInProgress } = usePromiseTracker();
  const currentUrl = new URL(window.location.href);
  const currentUrlParams = currentUrl.searchParams;

  const { isLoggedIn, isUserAuthorized } = useAuth();

  const [EntryData, setEntryData] = React.useState({});
  const isFamily = EntryData?.entryType === "Family";
  const [ConnectedPlacesData, setConnectedPlacesData] = React.useState([]);

  // current url with /pdf added after /artist
  const pdfUrl = currentUrl.href.replace("/entry", "/entry/pdf");

  const [citeData, setCiteData] = React.useState();
  //   const [RecentRecords, setRecentRecords] = React.useState();
  const onCollapse = (e) => {
    let elements = document.getElementsByClassName("sc-dkzDqf");
  };
  useEffect(() => {
    trackPromise(
      axios
        .get(process.env.REACT_APP_SERVER_HOST_NAME + "/entry/" + id)
        .then((response) => {
          setEntryData(response.data);
        })
        .catch((error) => {
          console.log("API request Error: ", error);
        })
    );

    const citationUrl =
      process.env.REACT_APP_SERVER_HOST_NAME + "/entry/" + id + "/cite";
    axios
      .get(citationUrl)
      .then((response) => {
        setCiteData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (isFamily && !isNotNullOrEmptyArr(ConnectedPlacesData))
      trackPromise(
        axios
          .get(
            process.env.REACT_APP_SERVER_HOST_NAME +
              "/entry/" +
              id +
              "/connected-places"
          )
          .then((response) => {
            let placesSet = new Set();

            response.data?.forEach((element) => {
              element.split("; ").forEach((place) => {
                placesSet.add(place);
              });
            });
            setConnectedPlacesData([...placesSet]);
          })
          .catch((error) => {
            console.log("API request Error: ", error);
          }),
        "connected-places"
      );
  }, [isFamily]);

  const { promiseInProgress: connectedPlacesPromiseInProgress } =
    usePromiseTracker({
      area: "connected-places",
    });

  let members = EntryData?.artists?.length && (
    <Grid container spacing={2}>
      {EntryData?.artists?.map((artist, index) => (
        <Grid item md={6} xs={12} key={index}>
          <ArtistName id={artist.id} name={artist.name} isArtist />
          <Divider sx={{ borderBottomWidth: 2 }} />
        </Grid>
      ))}
    </Grid>
  );

  const aowID = EntryData?.id && (
    <Typography className={classes.artistDetail}>
      {t("AOW ID")}: {EntryData.id}
    </Typography>
  );
  // let gridEntry = [];
  // for (let index = 0; index < EntryData?.artists?.length; index += 2) {
  //   gridEntry.push(EntryData?.artists?.slice(index, index + 2));
  // }

  // gridEntry = gridEntry.map((arr) => {
  //   return arr.length > 0 ? (
  //     <>
  //       <Grid item md={6}>
  //         <ArtistName id={arr[0].id} name={arr[0].name} isArtist />
  //         <Divider sx={{ borderBottomWidth: 2 }} />
  //       </Grid>
  //       {arr.length > 1 ? (
  //         <Grid item md={6}>
  //           <ArtistName id={arr[1].id} name={arr[1].name} isArtist />
  //           <Divider sx={{ borderBottomWidth: 2 }} />
  //         </Grid>
  //       ) : null}
  //     </>
  //   ) : null;
  // });
  const showConnectedPlaces = (data) => (
    <>
      <Typography variant="h6" className={classes.ovdataTitle}>
        {t("Places of Activity")}
      </Typography>
      <Typography variant="body1">
        {data.join(", ")}.{" "}
        <a href="#connected-places" className="text-link">
          {t("View Map")}
        </a>
      </Typography>
    </>
  );
  const overviewData =
    EntryData?.foundingDate ||
    EntryData?.closingDate ||
    (EntryData?.geoRegion && EntryData?.geoRegion?.length > 0) ? (
      <div style={{ marginTop: "36px" }}>
        <Typography
          variant="h2"
          gutterBottom
          style={{
            fontWeight: 500,
            fontSize: "26px",
            marginBottom: "18px",
          }}
        >
          {t("Overview")}
        </Typography>
        {EntryData?.foundingDate ||
        EntryData?.closingDate ||
        EntryData?.foundingLocation ||
        EntryData?.closingLocation ? (
          <>
            <Typography variant="h6" className={classes.ovdataTitle}>
              {t("Active")}
            </Typography>
            <Typography variant="body1">
              {t("From")}: {EntryData?.foundingLocation}
              {isNotNullOrEmptyStr(EntryData?.foundingLocation) ? ", " : null}
              {isNotNullOrEmptyStr(EntryData?.foundingDate)
                ? EntryData?.foundingDate
                : "?"}
            </Typography>
            <br />
            <Typography variant="body1">
              {t("To")}: {EntryData?.closingLocation}
              {isNotNullOrEmptyStr(EntryData?.closingLocation) ? ", " : null}
              {isNotNullOrEmptyStr(EntryData?.closingDate)
                ? EntryData?.closingDate
                : "?"}
            </Typography>
          </>
        ) : null}
        {/* <Typography variant="h6" className={classes.ovdataTitle}>
          Prominent members
        </Typography> */}
        {connectedPlacesPromiseInProgress ? (
          <Spinner show />
        ) : isFamily && isNotNullOrEmptyArr(ConnectedPlacesData) ? (
          showConnectedPlaces(ConnectedPlacesData)
        ) : null}
        {EntryData?.geoRegion && EntryData?.geoRegion?.length > 0
          ? showConnectedPlaces(getPlacesFromGeoRegions(EntryData?.geoRegion))
          : null}
      </div>
    ) : null;

  const getArticleContent = () => {
    let articleContentArr = [];
    if (
      isFamily &&
      EntryData?.richText &&
      Object.keys(EntryData?.richText).length > 0
    ) {
      Object.keys(EntryData?.richText).forEach((element) => {
        if (
          isNotNullOrEmptyStr(ArticleTitles[element]) &&
          !EntryData?.richText[element]?.endsWith("/>")
        )
          articleContentArr.push({
            title: ArticleTitles[element],
            content: EntryData?.richText[element],
          });
      });
    }
    return articleContentArr;
  };
  let articleContent = useMemo(() => getArticleContent(), [EntryData]);

  return (
    <>
      <div className={"body-container " + classes.overx}>
        <OuterSpinner />
        <Navbar isHomepage={false} />
        <section className="section">
          <Container className="container">
            <BackToHome />
            {!promiseInProgress ? (
              EntryData?.name ? (
                !isLoggedIn || !isUserAuthorized ? (
                  <>
                    <div className={classes.titlearea}>
                      <div className="results">
                        <div className="title-div">
                          <Typography
                            variant="h5"
                            className={classes.mainTitle}
                          >
                            {EntryData?.name}
                            <span className={classes.grpTitle}>
                              ({t(EntryData?.entryType)})
                            </span>
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <PayWall />
                  </>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item md={9}>
                      <div className="title-div">
                        <Typography variant="h5" className={classes.mainTitle}>
                          {EntryData?.name}
                          <span className={classes.grpTitle}>
                            ({t(EntryData?.entryType)})
                          </span>
                        </Typography>
                      </div>
                      {aowID}
                      <div className={classes.buttonGroup}>
                        <div className={classes.btn}>
                          <DownloadButton url={pdfUrl}></DownloadButton>
                        </div>
                        {citeData && (
                          <div className={classes.btn}>
                            <CiteButton
                              entryId={EntryData?.id}
                              apaString={citeData?.APA}
                              mlaString={citeData?.MLA}
                              chicagoString={citeData?.Chicago}
                              harvardString={citeData?.Harvard}
                              vancouverString={citeData?.Vancouver}
                            ></CiteButton>
                          </div>
                        )}

                        <div className={classes.btn}>
                          <ShareButton></ShareButton>
                        </div>
                      </div>
                      {overviewData}
                      {members && (
                        <div style={{ marginTop: "60px" }}>
                          <Typography
                            variant="h2"
                            gutterBottom
                            style={{
                              fontWeight: 500,
                              fontSize: "26px",
                              marginBottom: "18px",
                            }}
                          >
                            {t("Members")}
                          </Typography>
                          <Grid
                            container
                            spacing={4}
                            className={classes.nameGrid}
                          >
                            {members}
                          </Grid>
                        </div>
                      )}
                      {isFamily ? (
                        <>
                          <div
                            className={classes.contentDiv + " artistContent"}
                          >
                            <div style={{ marginTop: "30px" }}>
                              <Typography
                                variant="body1"
                                className={classes.articleTitle}
                              >
                                {t("Article")}
                              </Typography>
                              <div
                                style={{
                                  marginBottom: "0px",
                                }}
                              >
                                {articleContent.length > 0 &&
                                  articleContent.map((element1, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <div
                                          className={classes.tableTextContent}
                                          style={{
                                            marginTop: `${
                                              i !== 0 ? "60px" : "30px"
                                            }`,
                                          }}
                                        >
                                          <Typography
                                            id={element1.title}
                                            variant="h3"
                                            className={classes.subTitle}
                                          >
                                            {element1.title}
                                          </Typography>
                                        </div>
                                        <ExpandableContent
                                          className={classes.textExpand}
                                          maxHeight={250}
                                          collapseText={t("View less")}
                                          expandText={t("View more")}
                                        >
                                          <Typography
                                            variant="body1"
                                            className={classes.content}
                                            dangerouslySetInnerHTML={{
                                              __html: element1.content,
                                            }}
                                          ></Typography>
                                        </ExpandableContent>
                                      </React.Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          {connectedPlacesPromiseInProgress ? (
                            <div style={{ marginTop: "60px" }}>
                              <Spinner show />
                            </div>
                          ) : ConnectedPlacesData?.length > 0 ? (
                            <div style={{ marginTop: "60px" }}>
                              <Typography
                                id="connected-places"
                                variant="h2"
                                gutterBottom
                                style={{
                                  fontWeight: 500,
                                  fontSize: "26px",
                                  marginBottom: "30px",
                                }}
                              >
                                {t("Places of Activity")}
                              </Typography>
                              <ConnectedPlaces places={ConnectedPlacesData} />
                            </div>
                          ) : null}
                        </>
                      ) : null}

                      {EntryData?.geoRegion &&
                      EntryData?.geoRegion?.length > 0 ? (
                        <div style={{ marginTop: "60px" }}>
                          <Typography
                            id="connected-places"
                            variant="h2"
                            gutterBottom
                            style={{
                              fontWeight: 500,
                              fontSize: "26px",
                              marginBottom: "30px",
                            }}
                          >
                            {t("Places of Activity")}
                          </Typography>
                          <ConnectedPlaces
                            places={getPlacesFromGeoRegions(
                              EntryData?.geoRegion
                            )}
                          />
                        </div>
                      ) : null}

                      {/* <div>
                        <Typography
                          variant="h6"
                          className={classes.MemberTitle}
                        >
                          AKL Article
                        </Typography>
                        {/* <Typography
                          variant="h6"
                          className={classes.displayContent}
                        >
                          Display Text:
                        </Typography>
                        <MUISwitch
                          leftswitch="Abbreviated"
                          rightswitch="Expanded"
                          className={classes.switchclick}
                          switchClick={onCollapse}
                        /> 
                      </div> */}
                      {/* <div>
                        <Typography
                          variant="h6"
                          id="abc"
                          className={classes.MemberTitle}
                        >
                          Sub-heading
                        </Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Quam diam quisque nam aliquam enim. Rhoncus sit est
                        ultrices lacus vivamus in. Tortor, praesent maecenas
                        posuere dictum euismod faucibus nec.
                        <Typography
                          variant="h6"
                          className={classes.MemberTitle}
                        >
                          Sub-heading
                        </Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Semper amet mauris cum in. Est, tempor diam commodo
                        vestibulum. Aliquam facilisis amet vel turpis nec nulla
                        aenean. Dui ornare vitae, commodo nunc. Blandit egestas
                        adipiscing sollicitudin proin massa. Dui, ac ipsum leo
                        diam enim, sit urna. Cras sodales faucibus ornare amet
                        rhoncus turpis sagittis. Elit lectus amet potenti sed
                        purus nisi, at luctus enim. Vel, amet consectetur odio
                        morbi id cursus dapibus. Risus nunc velit ac, viverra
                        sed. Ipsum augue scelerisque imperdiet proin amet eu.
                        Risus vitae sed viverra diam ultricies imperdiet
                        praesent penatibus euismod. Et in donec ornare enim,
                        orci rhoncus, eget facilisis cursus.Tristique tristique
                        quis egestas amet, lorem sollicitudin et. Posuere mauris
                        enim nunc arcu magna. Elementum scelerisque elit
                        tincidunt rhoncus. Risus nibh risus placerat ornare
                        <Typography
                          variant="h6"
                          className={classes.MemberTitle}
                        >
                          Places of Activity
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.MemberTitle}
                        >
                          Connected People
                        </Typography>
                      </div> */}
                    </Grid>
                    {/* <Grid item md={3}>
                      <Box className={classes.boxDesign}>
                        <Typography className={classes.ContentTitle}>
                          <Trans i18nkey="">Contents</Trans>
                        </Typography>
                        <div>
                          <a href="#Members">Members</a>
                        </div>
                        <div>
                          <a href="">AKL Artist</a>
                        </div>
                        <div>
                          <div>
                            <a href="#abc">Sub-heading</a>
                          </div>
                          <a href="#Sub-heading">Sub-heading</a>
                        </div>
                        <div>
                          <a href="">Places of Activity</a>
                        </div>
                        <div>
                          <a href="">Connected People</a>
                        </div>
                        <div>
                          <a href="">Artist Information</a>
                        </div>
                      </Box>
                    </Grid> */}
                  </Grid>
                )
              ) : (
                <Typography variant="h1" className={classes.mesBox}>
                  {t("Entry Not Found")}
                </Typography>
              )
            ) : null}

            {/* <Grid container spacing={4} className={classes.nameGrid}></Grid> */}
          </Container>
        </section>

        <Footer />
      </div>
    </>
  );
}
export default Entry;
