import React, { useState } from "react";
import { makeStyles, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles({
  root: {
    zIndex: "99999",
  },
});

function Toast(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.status ?? false);

  const handleCloseToast = (event, reason) => {
    setOpen(false);
  };

  return (
    <Snackbar
      className={classes.root}
      open={open}
      autoHideDuration={props.autoHideDuration ?? 6000}
      onClose={handleCloseToast}
    >
      <Alert onClose={handleCloseToast} severity={props.type}>
        {t(props.message)}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
