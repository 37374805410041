import {
  Button,
  Container,
  Grid,
  Typography as MatTypography,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import * as constants from "../utils/Constants";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ArtistName from "../components/ArtistName";
import ConnectedPlaces from "../components/ConnectedPlaces";
import { Spinner } from "../components/Spinner/Spinner";
import commonStyles from "../utils/commonStyles";
import {
  capitalizeStr,
  formateDate,
  getSearchFiltersFromQuery,
  isNotNullOrEmptyArr,
  isNotNullOrEmptyStr,
  isNotNullOrUndefined,
  isObjEmpty,
  prepareSearchFormListForHighlight,
} from "../utils/helper_functions";
import useFindOnPage from "../utils/hooks/useFindOnPage";
import { useInitialRender } from "../utils/hooks/useInitialRender";

import { DGLogoPDF } from "../components/DGLogo/DGLogo";
import { PDFLoader } from "../components/PDF/PDFLoader";
import { saveAs } from "file-saver";
import { registerFonts } from "../components/PDF/registerFonts";
import { PDFstyles } from "../components/PDF/PDFstyles";
import { Typography } from "../components/PDF/PDFTypography";
import { PDFHeader } from "../components/PDF/Header";
import { PDFFooter } from "../components/PDF/Footer";
import { PDFCover } from "../components/PDF/Cover";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  ReactPDF,
  Image,
  BlobProvider,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

function ArtistPDF(props) {
  registerFonts();

  const { t, i18n } = useTranslation();
  const isInitialRender = useInitialRender();
  const currentUrl = new URL(window.location.href);
  const currentUrlParams = currentUrl.searchParams;
  const [showLineNumbers, setShowLineNumbers] = useState(false);
  const [tabDataLoaded, setTabDataLoaded] = useState([]);
  const classes = StyleSheet.create(PDFstyles);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);

  var token = localStorage.getItem("jwtToken");
  const currentIndex = currentUrlParams.get("currentIndex");
  const { fileName } = useParams();
  let location = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  const locationHash = location.hash;
  const searchURL = useRef(currentUrlParams.get("search_url"));
  const mobileFindOnPageRef = useRef(null);
  const { alphabetSearch, searchForm } = getSearchFiltersFromQuery(
    new URLSearchParams(decodeURIComponent(searchURL.current ?? ""))
  );
  const entryTabsRef = useRef(null);

  const addSortHref = (sortParams) => {
    if (searchURL.current) {
      sortParams.set("search_url", searchURL.current);
    }
  };

  const login = currentUrlParams.get("login");
  const checklogin = () => {
    if (login === "success") {
      // remove login param from url
      currentUrlParams.delete("login");
      currentUrl.search = currentUrlParams.toString();
      window.history.replaceState({}, "", currentUrl.toString());
    }
  };

  // const history = useHistory();
  const navigate = useNavigate();
  const [entry, setEntry] = React.useState({});
  const [citeData, setCiteData] = React.useState();
  const [currentTab, setCurrentTab] = useState(constants.EntryTabs.LEMMA_TAB);

  let tableData = ["Werke", "Ausstellungen", "Bibliographie"];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let dialogPadding = fullScreen ? "15px" : "40px";

  const navigateToValidate = () => {
    navigate({
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);
      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );
    }
  }, [token]);

  useEffect(() => {
    checklogin();
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);

      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );

      setTabDataLoaded(() => []);
    }
  }, [fileName]);

  const loadData = () => {
    const tempCurrentTab = currentTab; // TODO: If tab changes in between API call;

    trackPromise(
      axios
        .get(process.env.REACT_APP_SERVER_HOST_NAME + `/book/${fileName}`)
        .then((response) => {
          const uParams = new URLSearchParams();
          if (currentIndex && currentIndex !== "null")
            uParams.append("currentIndex", currentIndex);

          addSortHref(uParams);

          setEntry(response.data);
          setTabDataLoaded((prevData) => [...prevData, tempCurrentTab]);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigateToValidate();
          }
        })
    );
  };

  const lemmaprop = {
    search: [{ value: "" }],
  };
  lemmaprop.search[0].value = entry?.lemma ? entry.lemma[0] : "";

  let keywords = i18n.language === "de" ? entry?.deKeywords : entry?.enKeywords;

  let found = entry.entries?.find(function (entriesD) {
    return entriesD.type === "Group";
  });
  let foundData = entry.entries?.find(function (entriesData) {
    return entriesData.type === "Family";
  });

  useEffect(() => {
    if (!tabDataLoaded.length) {
      const searchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "FULL_TEXT")
      );

      const citSearchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "CITATION")
      );

      if (citSearchValues.length && !searchValues.length) {
        // load cite only
        if (currentTab === constants.EntryTabs.CITE_TAB) {
          loadData();
        } else if (entryTabsRef?.current?.changeTab) {
          entryTabsRef.current.changeTab(constants.EntryTabs.CITE_TAB);
        }
      } else {
        loadData();
      }
    } else if (!tabDataLoaded.includes(currentTab)) {
      loadData();
    }
  }, [currentTab, tabDataLoaded?.length]);

  const [ConnectedPeople, setConnectedPeople] = useState([]);
  useEffect(() => {
    if (isInitialRender || promiseInProgress) return;

    // try {
    //   const response = await axios.get(
    //     process.env.REACT_APP_SERVER_HOST_NAME +
    //     `/search/connectedPeople?id=${fileName}${entry?.title ? "&name=" + entry?.title : ""
    //     }`
    //   ).toPromise()
    //   if (response?.status === 200) {
    //     setConnectedPeople(res?.data);
    //   }
    // } catch (error) {
    //   console.log("API request Error: ", error);
    // }

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            `/search/connectedPeople?id=${fileName}${
              entry?.title ? "&name=" + entry?.title : ""
            }`
        )
        .then((res) => {
          if (res?.status === 200) {
            setConnectedPeople(res?.data);
          }
        })
        .catch((error) => {
          console.log("API request Error: ", error);
        }),
      "connected-people"
    );
  }, [fileName, entry?.title, promiseInProgress, isInitialRender]);

  const { promiseInProgress: connectedPeoplePromiseInProgress } =
    usePromiseTracker({
      area: "connected-people",
    });

  const [NameVariationsSource, setNameVariationsSource] = useState(1);

  const [openNameVariationsModal, setOpenNameVariationsModal] = useState(false);
  const { promiseInProgress: nameVariationsSourcePromiseInProgress } =
    usePromiseTracker({
      area: "name-variations-modal",
    });

  const [Occupations, setOccupations] = useState([]);

  const [openOccupationModal, setOpenOccupationModal] = useState(false);
  const { promiseInProgress: occupationPromiseInProgress } = usePromiseTracker({
    area: "occupations-modal",
  });
  const handleOccupationModalOpen = (e) => {
    console.log(
      process.env.REACT_APP_SERVER_HOST_NAME + `/book/${fileName}/occupations`
    );
    e.preventDefault();
    if (entry?.occupations?.length > 0)
      trackPromise(
        axios
          .get(
            process.env.REACT_APP_SERVER_HOST_NAME +
              `/book/${fileName}/occupations`
          )
          .then((res) => {
            if (res?.status === 200) setOccupations(res?.data);
          })
          .catch((error) => {
            console.log("API request Error: ", error);
          }),
        "occupations-modal"
      );
    setOpenOccupationModal(true);
  };
  const handleOccupationModalClose = () => {
    setOpenOccupationModal(false);
  };
  const occupationModal = (
    <Dialog
      fullScreen={fullScreen}
      scroll="body"
      open={openOccupationModal}
      className="cite-container"
      onClose={handleOccupationModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle sx={{ padding: dialogPadding }}>
        <div
          className={`occupation-modal-title-div ${
            fullScreen ? "dialog-fullscreen-title" : ""
          }`}
        >
          <Typography
            className="gb header-title"
            color="inherit"
            style={classes.body1}
          >
            <Trans i18nKey="occupationModalTitle1">
              Translations and Explanations for
            </Trans>
          </Typography>
          {!fullScreen ? (
            <Typography className="gm" color="inherit" style={classes.h2}>
              <Trans i18nKey="occupationModalTitle2">Occupational Titles</Trans>
            </Typography>
          ) : null}
        </div>
        <div className="close-div">
          <IconButton
            aria-label="close"
            onClick={handleOccupationModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers={fullScreen} sx={{ padding: dialogPadding }}>
        <div className="occupation-modal">
          {fullScreen ? (
            <div className="modal-header">
              <div className="occupation-modal-title-div">
                <Typography
                  align="center"
                  variant="h2"
                  className="gm"
                  color="inherit"
                >
                  <Trans i18nKey="occupationModalTitle2">
                    Occupational Titles
                  </Trans>
                </Typography>
              </div>
            </div>
          ) : null}

          <div className={`${classes.paddingHorizontal0}`}>
            {occupationPromiseInProgress ? (
              <Spinner show />
            ) : Occupations && Occupations?.length > 0 ? (
              Occupations.map((occupation, index) => (
                <div key={"oc" + index} className={classes.body}>
                  <Typography
                    style={classes.body1}
                    className={`gm format ${classes.format}`}
                  >
                    {i18n.language === "de"
                      ? occupation.deName
                      : occupation.enName}
                  </Typography>

                  <div className={classes.clipboardArea} id="clipboardArea">
                    <Typography
                      variant="body1"
                      className={classes.clipboardAreaText}
                    >
                      {t("Description")}: {occupation.description}
                    </Typography>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.body}>
                <Typography
                  className={`gm format ${classes.format}`}
                  style={classes.body1}
                >
                  <Trans i18nKey="noData">No data found</Trans>
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );

  const handleNameVariationsModalOpen = (e) => {
    e.preventDefault();
    console.log(
      process.env.REACT_APP_SERVER_HOST_NAME +
        `/book/${fileName}/name-variations-source`
    );
    if (entry?.nameVariations?.length > 0)
      trackPromise(
        axios
          .get(
            process.env.REACT_APP_SERVER_HOST_NAME +
              `/book/${fileName}/name-variations-source`
          )
          .then((res) => {
            if (res?.status === 200) setNameVariationsSource(res?.data);
          })
          .catch((error) => {
            console.log("API request Error: ", error);
          }),
        "name-variations-modal"
      );
    setOpenNameVariationsModal(true);
  };
  const handleNameVariationsModalClose = () => {
    setOpenNameVariationsModal(false);
  };
  const nameVariationsModal = (
    <Dialog
      fullScreen={fullScreen}
      scroll="body"
      open={openNameVariationsModal}
      className="cite-container"
      onClose={handleNameVariationsModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle sx={{ padding: dialogPadding }}>
        <div
          className={`occupation-modal-title-div ${
            fullScreen ? "dialog-fullscreen-title" : ""
          }`}
        >
          <Typography
            variant="body1"
            className="gb header-title"
            color="inherit"
            style={{
              lineHeight: "30px",
              textAlign: "center",
            }}
          >
            <Trans i18nKey="nameVariationModalTitle1">
              Name Variations for
            </Trans>
          </Typography>
          {!fullScreen ? (
            <Typography
              variant="h2"
              className="gm"
              color="inherit"
              style={{ marginLeft: "10px" }}
            >
              {entry?.title}
            </Typography>
          ) : null}
        </div>
        <div className="close-div">
          <IconButton
            aria-label="close"
            onClick={handleNameVariationsModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers={fullScreen} sx={{ padding: dialogPadding }}>
        <div className="occupation-modal">
          {fullScreen ? (
            <div className="modal-header">
              <div className="occupation-modal-title-div">
                <Typography
                  align="center"
                  variant="h2"
                  className="gm"
                  color="inherit"
                >
                  {entry?.title}
                </Typography>
              </div>
            </div>
          ) : null}
          <div className={`${classes.paddingHorizontal0}`}>
            {nameVariationsSourcePromiseInProgress ? (
              <Spinner show />
            ) : NameVariationsSource && !isObjEmpty(NameVariationsSource) ? (
              Object.keys(NameVariationsSource).map((name, index) => (
                <div key={"nv" + index} className={classes.body}>
                  <Typography
                    variant="body1"
                    className={`gm format ${classes.format}`}
                  >
                    {name}
                  </Typography>

                  <div className={classes.clipboardArea} id="clipboardArea">
                    <Typography
                      variant="body1"
                      className={classes.clipboardAreaText}
                    >
                      {t("Source(s)")}: {NameVariationsSource[name]}
                    </Typography>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.body}>
                <Typography
                  variant="body1"
                  className={`gm format ${classes.format}`}
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <Trans i18nKey="noData">No data found</Trans>
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );

  const overviewData = keywords ? (
    <View style={classes.overviewDataDiv}>
      <Typography style={classes.mainTitle}>{t("Overview")}</Typography>
      {keywords.dateOfBirth || keywords.placeOfBirth ? (
        <>
          <Typography style={classes.h6}>{t("Born")}</Typography>
          <Typography style={classes.body1}>
            {keywords.placeOfBirth}
            {keywords.dateOfBirth && keywords.placeOfBirth ? ", " : null}
            {keywords.dateOfBirth ? formateDate(keywords.dateOfBirth) : null}
          </Typography>
        </>
      ) : null}
      {keywords.dateOfDeath || keywords.placeOfDeath ? (
        <>
          <Typography style={classes.h6}>{t("Died")}</Typography>
          <Typography style={classes.body1}>
            {keywords.placeOfDeath}
            {keywords.dateOfDeath && keywords.placeOfDeath ? ", " : null}
            {keywords.dateOfDeath ? formateDate(keywords.dateOfDeath) : null}
          </Typography>
        </>
      ) : null}
      {isNotNullOrEmptyArr(keywords.furtherNames) ||
      keywords.thiemeBeckerName ||
      keywords.vollmerName ? (
        <>
          <Typography style={classes.h6}>{t("Name Variations")}</Typography>
          <Typography style={classes.body1}>
            {keywords.furtherNames?.join("; ") ?? null}
            {keywords.thiemeBeckerName
              ? (isNotNullOrEmptyArr(keywords.furtherNames) ? "; " : "") +
                keywords.thiemeBeckerName
              : null}
            {keywords.vollmerName
              ? (isNotNullOrEmptyArr(keywords.furtherNames) ||
                keywords.thiemeBeckerName
                  ? "; "
                  : null) + keywords.vollmerName
              : null}
            <br />
            {/* <Typography
              variant="body1"
              className={classes.ovdata + " text-link"}
              onClick={(e) => {
                handleNameVariationsModalOpen(e);
              }}
            >
              {t("View Source")}
            </Typography> */}
          </Typography>
          {nameVariationsModal}
        </>
      ) : null}
      {keywords.gender ? (
        <>
          <Typography style={classes.h6}>{t("Gender")}</Typography>
          <Typography style={classes.body1}>
            {capitalizeStr(keywords.gender)}
          </Typography>
        </>
      ) : null}
      {isNotNullOrEmptyArr(keywords.occupation) ? (
        <>
          <Typography style={classes.h6}>{t("Occupation")}</Typography>

          <Typography
            style={classes.body1}
            // onClick={handleOccupationModalOpen}
          >
            {keywords.occupation?.map((occu) => occu).join(", ")}
          </Typography>

          {occupationModal}
        </>
      ) : null}
      {isNotNullOrEmptyArr(keywords.geographicData) ? (
        <>
          <Typography style={classes.h6}>{t("Places of Activity")}</Typography>
          <Typography style={classes.body1}>
            {keywords.geographicData.join(", ")}.{" "}
          </Typography>
        </>
      ) : null}

      {entry?.authors?.length ? (
        <>
          <Typography style={classes.h6}>
            {entry.authors.length === 1 ? t("Author") : t("Authors")}
          </Typography>
          {entry.authors.map((author, index) => (
            <Typography key={"author" + index} style={classes.body1}>
              {capitalizeStr(author)}
            </Typography>
          ))}
        </>
      ) : null}

      {isNotNullOrEmptyArr(keywords.location) ? (
        <>
          <Typography style={classes.h6}>{t("Location")}</Typography>
          <Typography style={classes.body1}>
            {keywords.location.join("; ")}
          </Typography>
        </>
      ) : null}

      {isNotNullOrEmptyArr(keywords.country) ? (
        <>
          <Typography style={classes.h6}>{t("Country")}</Typography>
          <Typography style={classes.body1}>
            {keywords.country.join("; ")}
          </Typography>
        </>
      ) : null}
    </View>
  ) : null;

  const ldata = (
    <View style={classes.contentDiv + " artistContent"}>
      {entry?.bodyMap?.sec?.length > 0 &&
        entry.bodyMap?.sec?.map((element, index) => (
          <View break key={"bmap" + index} style={{ marginBottom: 30 }}>
            <Text id={element.title} style={classes.mainTitle}>
              {element.title}
            </Text>

            <View>
              {entry.bodyMap?.sec?.length > 0 &&
                element.sec.map((sec, i) => {
                  let tableContent = sec.p;
                  if (tableData.includes(sec.title)) {
                    if (tableContent.startsWith("<p>")) {
                      tableContent = tableContent.substring(3);
                      if (tableContent.endsWith("</p>")) {
                        tableContent = tableContent.substring(
                          0,
                          tableContent.length - 4
                        );
                      }
                    }
                  }
                  return (
                    <View key={"tbl" + i}>
                      <View
                        style={{
                          ...classes.tableTextContent,
                          marginTop: `${i !== 0 ? 15 : 5}`,
                        }}
                      >
                        <Text id={element.title + sec.title} style={classes.h3}>
                          {sec.title}
                        </Text>
                      </View>
                      <View>
                        <Typography
                          // variant="body1"
                          style={classes.body1}
                          dangerouslySetInnerHTML={{
                            __html: sec.p,
                          }}
                        ></Typography>
                        {/* <Text>{element1.p}</Text> */}
                        {/* )} */}
                      </View>
                    </View>
                  );
                })}
            </View>
          </View>
        ))}
    </View>
  );

  const artistIDs = (found || foundData) && (
    <View style={classes.artistInfo}>
      <Typography style={classes.artistDetail}>
        {t("Member of")}&nbsp;
      </Typography>
      {found ? (
        // <Link to={`/entry/${found.id}`} className="text-link">
        <Typography style={classes.artistDetail}>
          {found.name}&nbsp;
          <span>(</span>
          {t(found.type)}
          <span>)</span>
        </Typography>
      ) : // </Link>
      null}
      {foundData ? (
        <>
          <Typography style={classes.artistDetail}>
            &nbsp;
            {found ? t("and") + " " : null}
            {foundData.name}&nbsp;
            <span>(</span>
            {foundData.type}
            <span>)</span>
          </Typography>
        </>
      ) : null}
    </View>
  );

  const keywordsSection = isNotNullOrEmptyArr(entry?.enKeywords?.pndId) && (
    <Typography style={classes.artistDetail}>
      {t("GND-ID")}:{" "}
      {entry?.enKeywords?.pndId?.map((element, i) => {
        let id = element?.trim()?.substring(3);
        return isNotNullOrEmptyStr(id) ? (
          <>
            {i !== 0 ? ", " : null}
            <a
              key={"gnd" + i}
              href={`https://d-nb.info/gnd/${id}`}
              target="_blank"
              rel="noreferrer"
              className="text-link"
            >
              {id}
            </a>
          </>
        ) : null;
      })}
    </Typography>
  );

  const aowID = entry?.id && (
    <Typography style={classes.artistDetail}>
      {t("AOW ID")}: {entry.id}
    </Typography>
  );

  const contents = entry?.bodyMap?.sec?.length > 0 && (
    <>
      <View style={classes.contents}>
        <Typography style={classes.h2}>{t("Contents")}</Typography>
        <Text> </Text>

        <View style={classes.mainTitle1}>
          <Text style={classes.link} src={"#Overview"}>
            {t("Overview")}
          </Text>
        </View>

        {entry.bodyMap?.sec?.length > 0 &&
          entry.bodyMap?.sec?.map((element, i) => (
            <View key={"entrybody" + i} style={classes.mainBox}>
              <View style={classes.mainTitle1}>
                <Text style={classes.link} src={"#" + element.title}>
                  {element.title}
                </Text>
              </View>
              <View>
                {element?.sec?.map((sec, i) => (
                  <View key={"sub" + i} style={classes.boxSubTitle}>
                    <Text
                      style={classes.link}
                      src={"#" + element.title + sec.title}
                    >
                      {sec.title}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
      </View>
    </>
  );
  const idWithoutLeadingUnderscore = entry?.id?.replace(/^_+/, "");
  const docName = "AOW_ID_" + idWithoutLeadingUnderscore;

  const doc = !promiseInProgress &&
    entry?.title &&
    (entry?.isFreeAccess || (isLoggedIn && isUserAuthorized)) && (
      <Document
        title={docName}
        creator="DeGruyter - Artists of the World"
        producer="tll.degruyter.com"
        language={i18n.language}
      >
        <Page size="A4" style={classes.page} wrap>
          <PDFCover entry={entry}></PDFCover>
          <Typography style={classes.h1}>{entry.title}</Typography>
          {contents}
          <PDFFooter></PDFFooter>
        </Page>

        {/* Second page */}
        <Page size="A4" style={classes.page} wrap>
          <PDFHeader entry={entry}></PDFHeader>
          <View wrap style={classes.section}>
            <View>
              <View id="Overview" style={classes.titlearea}>
                <View style={classes.results}>
                  <Typography style={classes.h1}>{entry.title}</Typography>
                </View>
              </View>
              {artistIDs}
              {keywordsSection}
              {aowID}

              {overviewData}

              {/* Third page */}
              {ldata}
            </View>
            <View item md={3}>
              <View style={classes.container}>
                {isNotNullOrUndefined(entry?.imageURL) &&
                entry?.imageURL !== "" ? (
                  <View style={classes.imgbox}>
                    <Image
                      alt={t("Artist Image")}
                      src={entry?.imageURL}
                      style={{
                        width: "380px",
                      }}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
          <PDFFooter></PDFFooter>
        </Page>
      </Document>
    );

  const downloaded = useRef(undefined);
  const savePDF = (blob) => {
    if (!downloaded.current) {
      saveAs(blob, docName + ".pdf");
      downloaded.current = blob;
      // in chrome, close the tab
      if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
        // window.close();
      }
    }
  };

  return (
    <div className="body-container">
      <div className="main">
        {/* show a spinner while the PDF is being generated  */}
        {/* Automatic download */}
        {doc && (
          <BlobProvider document={doc}>
            {({ blob, url, loading, error }) => {
              if (!loading && blob) {
                savePDF(blob);
                return <PDFLoader loaded={url} />;
              } else {
                return <PDFLoader loaded={false} />;
              }
            }}
          </BlobProvider>
        )}
        {/* Display in browser */}
        {/* {doc && (
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            {doc}
          </PDFViewer>
        )} */}
      </div>
    </div>
  );
}
export default ArtistPDF;
