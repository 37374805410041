import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  textfieldlabel: {
    fontFamily: "Gotham-book",
    color: "#101010",
    // lineHeight: "20px",
    textAlign: "center",
    padding: "29px",
    fontSize: "15px",
    marginBottom: "38px",
    //display:"flex",
    lineHeight: "42px",
    position: "absolute",
  },
  selectbox__current: {},
}));

const CustomDropDown = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={"select-box " + (props.className ? props.className : "")}>
        <div className="select-box__current" tabIndex="1">
          <label className={classes.textfieldlabel}>{props.label}</label>
          {props.optionsList?.map((e, index) => {
            return (
              <div key={"option" + index} className="select-box__value">
                <input
                  className="select-box__input"
                  type="radio"
                  id={index + props.name + props.row}
                  value={e}
                  name={props.name + props.row}
                  onChange={props.onChange}
                  checked={e === props.defaultSelected}
                />
                <div className="select-box__input-text ">
                  {/* <span className="select-title gm">{props.title}</span> */}
                  <p className="fw-500 select-value gm">
                    {t(props.nameList[index])}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <ul className="select-box__list">
          {props.nameList?.map((e, index) => {
            return (
              <li key={props.row + index} className="select-box__li">
                <label
                  className="select-box__option fw-500 gm"
                  htmlFor={index + props.name + props.row}
                  aria-hidden="aria-hidden"
                >
                  {t(e)}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ borderRightWidth: 1.5 }}
      />
    </>
  );
};

export default CustomDropDown;
