import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#007596",
    color: "white",
    fontSize: "13px",
  },
  arrow: {
    color: "#007596",
  },
}))(Tooltip);
export default CustomTooltip;
