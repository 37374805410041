import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import BackToHome from "../components/Buttons/BackToHome";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import styles from "../staticPages.module.css";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Uber = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === "en") {
      navigate("/about");
    }
  }, [i18n.language, navigate]);

  return (
    <div className={"body-container "}>
      <Navbar isHomepage={false} />

      <section className="section">
        <Container className="container">
          <Grid container spacing={0}>
            <Grid item xs={12} md={10} lg={8}>
              <BackToHome />

              <h1>Über Artists of the World</h1>

              <div className={styles["static-page"]}>
                <div>
                  <p>
                    <em>Artists of the World </em>(ehem. Allgemeines
                    Künstlerlexikon) ist das weltweit aktuellste
                    <strong>,</strong> zuverlässigste und umfangreichste
                    Nachschlagewerk zu Künstlerinnen und Künstlern mit mehr als
                    1,2 Millionen Einträgen. Es enthält biografische
                    Informationen zu Personen und Einträge zu Gruppen bildender
                    Künstler/-innen aus aller Welt und aus allen Epochen. Es
                    deckt alle Gattungen der bildenden Künste ab, Malerei,
                    Grafik, Bildhauerei, Architektur, Fotografie, Video- und
                    Installationskunst, u. v. m.
                  </p>
                  <p></p>
                  <h3>Autorinnen und Autoren (Beispiele):</h3>
                  <p>
                    Stephanie Buck (<a href="/artist/_00142956">Hans Holbein</a>
                    ), Vaughan Hart (<a href="/artist/_00083633">Inigo Jones</a>
                    ), Peter Humfrey (<a href="/artist/_00169362">Tizian</a>),
                    Christian Spies (
                    <a href="/artist/_00101536">Pablo Picasso</a>), Naoki Sato (
                    <a href="/artist/_00142819T">Helene Schjerfbeck</a>),
                    Martino Stierli (
                    <a href="/artist/_00802102">Rem Koolhaas</a>), Frank Zöllner
                    (<a href="/artist/_00085517">Leonardo da Vinci</a>)
                  </p>
                  <p></p>
                  <h3>Ihre Vorteile</h3>
                  <ul>
                    <li>
                      Über 1,2 Millionen redaktionell betreute Einträge zu
                      Künstler/-innen sowie Künstler/-innengruppen
                    </li>
                    <li>
                      Über 500.000 ausführliche, signierte biografische Artikel,
                      im Volltext durchsuchbar
                    </li>
                    <li>
                      Laufende Ergänzung neuer Künstlerinnen und Künstler (ca.
                      1.000 Einträge pro Jahr) und Aktualisierungen der
                      biografischen Stammdaten und weiterer Informationen (ca.
                      3.000 Änderungen pro Jahr)
                    </li>
                    <li>
                      Neue Artikel, verfasst von internationalen Expertinnen und
                      Experten, in thematischen Schwerpunkten (z.B. afrikanische
                      Künstler/-innen, Architekt/-innen der Gegenwart)
                    </li>
                    <li>
                      Eine Vielzahl von Suchkriterien und Filtermöglichkeiten
                      für professionelle und wissenschaftliche Recherchen auf
                      einer übersichtlichen, benutzerfreundlichen Oberfläche
                    </li>
                    <li>
                      Informationen zu Wirkungsstätten und
                      Künstler/-innennetzwerken in Form von Karten und Listen
                    </li>
                    <li>
                      Unbegrenzte Anzahl gleichzeitiger Nutzer/-innen an jeder
                      Universität/Institution
                    </li>
                  </ul>
                  <p></p>
                  <p>
                    Für weitere Informationen und Preise für einen{" "}
                    <strong>institutionellen Zugang</strong> wenden Sie sich
                    bitte an unseren{" "}
                    <a href="https://www.degruyter.com/publishing/ueber-uns/kontakt/kontakt-vertrieb">
                      Vertrieb.
                    </a>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <OuterSpinner />
      <Footer />
    </div>
  );
};

export default Uber;
