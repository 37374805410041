import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import artist from "./images/paywall-artist.png";
import { Button } from "@mui/material";
import { isNotNullOrUndefined } from "../utils/helper_functions";

const useStyles = makeStyles((theme) => ({
  loginImage: {
    top: "-6%",
    left: "41%",
    paddingTop: "30px",
    paddingLeft: "400px",
    "& img": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      left: "0%",
      top: "-20%",
      position: "static",
      padding: 0,
      "& img": {
        width: "100%",
        "min-height": "350px",
      },
    },
  },
  loginText: {
    position: "absolute",
    fontFamily: "times",
    width: "59%",
    top: "-10%",
    left: "0%",
    backgroundColor: "#F7F7F7",
    padding: "45px",
    zIndex: "1",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    "& a": {
      color: "#005D78",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      display: "block",
      alignItems: "center",
      width: "100%",
      left: "0%",
      top: "0%",
      // position: "relative",
      padding: "25px",
    },
    [theme.breakpoints.up("md")]: {
      "min-height": "400px",
    },
  },
  parentContainer: {
    width: "100%",
    height: "51%",
    display: "flex",
    position: "relative",
    marginTop: "125px",
    [theme.breakpoints.down("sm")]: {
      top: "12%",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  },
  blurTile: {
    position: "absolute",
    top: "384px",
    paddingTop: "145px",
    [theme.breakpoints.down("sm")]: {
      top: "21%",
      paddingTop: "303px",
    },
  },
  paywallText: {
    marginTop: "14px",
    fontWeight: "normal",
    fontFamily: "Times,serif",
    fontSize: "17px",
  },
  topText: {
    fontFamily: "Gotham-Medium, tllmedium,sans-serif",
  },
  getAccessText: {
    marginTop: "14px",
    fontWeight: "bold",
    fontSize: "23px",
    fontFamily: "Gotham-Medium, tllmedium,sans-serif",
  },
  accessBtn: { marginTop: "30px !important", width: "200px", height: "45px" },
  innerDiv: {
    position: "relative",
    marginTop: "-50px",
    "min-height": "400px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "-75px",
    },
  },
}));

const PayWall = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const redirectUrl = encodeURIComponent(
    window.location.pathname + window.location.search
  );

  let isAuthorized = isNotNullOrUndefined(localStorage.getItem("isAuthorized"))
    ? localStorage.getItem("isAuthorized") === "true"
    : false;
  let isLoggedIn = isNotNullOrUndefined(localStorage.getItem("jwtToken"));

  return (
    <div className={classes.parentContainer}>
      <div className={classes.innerDiv}>
        <div className={classes.loginText}>
          <Typography className={classes.paywallText + " " + classes.topText}>
            {isLoggedIn && !isAuthorized ? (
              <Trans i18nKey="payWallNotAuthorized">
                You don't have access to this content!
              </Trans>
            ) : (
              <Trans i18nKey="payWallLogin">
                Already have access?
                <a href={`/validate?redirect_url=${redirectUrl}`}>Login</a>
              </Trans>
            )}
          </Typography>
          <Typography
            className={classes.paywallText + " " + classes.getAccessText}
          >
            <Trans i18nKey="payWallTitle">
              Get access to Artists of the World (AOW)
            </Trans>
          </Typography>
          <Typography className={classes.paywallText}>
            <p>
              <Trans i18nKey="payWallContent"></Trans>
            </p>
            <br />
            <p dangerouslySetInnerHTML={{ __html: t("payWallContent2") }}></p>
            <br />
            <p
              dangerouslySetInnerHTML={{ __html: t("payWallContentContact") }}
            ></p>
          </Typography>
        </div>

        <div className={classes.loginImage}>
          <img src={artist} alt={t("Artist's Image")} />
        </div>
      </div>
    </div>
  );
};

export default PayWall;
