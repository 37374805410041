import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { PDFstyles } from "./PDFstyles";
import { DGLogoPDF } from "../DGLogo/DGLogo";

export const PDFCover = ({ entry }) => {
  const classes = StyleSheet.create(PDFstyles);
  const { t } = useTranslation();
  return (
    <View style={classes.cover}>
      {DGLogoPDF(72)}
      <Text style={classes.h2}>&nbsp;&nbsp;&nbsp;Artists of the World</Text>
    </View>
  );
};
