import { Button, Fab } from "@material-ui/core";
import BackToTopIcon from "@material-ui/icons/ArrowUpward";
import { makeStyles } from "@material-ui/styles";
import { Fragment } from "react";
import useScroll from "../utils/hooks/useScroll";

const useStyles = makeStyles((theme) => ({
  backToTop: {
    transition: "opacity 0.4s",
    opacity: "1",
    minWidth: "min-content",
    "& .MuiButton-startIcon": {
      margin: "0px !important",
    },
    "& svg": {
      color: "#000000",
    },
    padding: "15px",
    borderRadius: "50% !important",
    backgroundColor: "#eaeaea !important",
    border: "0px solid #000000 !important",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hide: {
    opacity: "0",
  },
  backToTopMobile: {
    position: "fixed",
    right: "34px",
    bottom: "82px",
    zIndex: "100000",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const BackToTop = () => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [scrollX, scrollY] = useScroll();

  const showScroll = scrollY > 100;

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Fragment>
      <Button
        onClick={scrollToTop}
        disabled={!showScroll}
        square="true"
        variant="outlined"
        className={classes.backToTop + (showScroll ? "" : " " + classes.hide)}
        startIcon={
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.999999 7L7.06114 1M7.06114 1L13.1223 7M7.06114 1L7.06113 16"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        }
      ></Button>

      <Fab
        disabled={!showScroll}
        onClick={scrollToTop}
        color="primary"
        aria-label="backtotop"
        variant="round"
        className={
          classes.backToTopMobile +
          " rounded " +
          (showScroll ? "" : " " + classes.hide)
        }
        style={{ borderRadius: "50% !important", color: "#005D78" }}
      >
        <BackToTopIcon shapeRendering="true" style={{ color: "#FFFFFF" }} />
      </Fab>
    </Fragment>
  );
};

export default BackToTop;
