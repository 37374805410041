import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { DGLogoPDF } from "../DGLogo/DGLogo";
import { PDFstyles } from "./PDFstyles";

export const PDFHeader = ({ entry }) => {
  const classes = StyleSheet.create(PDFstyles);
  const { t, i18n } = useTranslation();

  const currentDate = new Date();
  const dateSeparator = i18n.locale === "en" ? "-" : ".";
  const date =
    currentDate.getDate() +
    dateSeparator +
    (currentDate.getMonth() + 1) +
    dateSeparator +
    currentDate.getFullYear();

  return (
    <View fixed style={classes.header}>
      <View style={classes.rowSpaceBetween}>
        <View style={{ width: "77%", paddingRight: 5 }}>
          <Text>
            <Text style={{ fontWeight: "bold" }}>
              {entry.title || entry.name}
            </Text>
            {" — "}
            <Text>
              {t("Retrieved on")} {date}
            </Text>
          </Text>
        </View>
        <View
          style={{
            whiteSpace: "nowrap",
            flexDirection: "row",
            alignItems: "center",
            width: "23%",
          }}
        >
          <Text style={{ marginRight: 5 }}>Artists of the World </Text>
          {DGLogoPDF(19)}
        </View>
      </View>
    </View>
  );
};
