import { Font } from "@react-pdf/renderer";

const hyphenationCallback = (word) => {
  // Return word syllables in an array
  return [word];
};

export const registerFonts = () => {
  Font.registerHyphenationCallback(hyphenationCallback);
  Font.register({
    family: "Montserrat-Medium",
    // src: "/fonts/Gotham-Medium.otf",
    src: "/fonts/Montserrat-Medium.ttf",
    fontWeight: "medium",
  });
  Font.register({
    family: "Montserrat",
    // src: "/fonts/Montserrat-Regular.otf",
    src: "/fonts/Montserrat-Regular.ttf",
    fontWeight: "normal",
    fonts: [
      {
        src: "/fonts/Montserrat-Medium.ttf",
        fontWeight: "medium",
      },
      {
        src: "/fonts/Montserrat-Regular.ttf",
        fontWeight: "normal",
      },
      {
        src: "/fonts/Montserrat-Bold.ttf",
        fontWeight: "bold",
      },
    ],
  });
  Font.register({
    family: "Times",
    src: "/fonts/TimesLTStd-Roman.otf",
  });
  Font.register({
    family: "Merriweather",
    src: "/fonts/Merriweather/Merriweather-Regular.ttf",
    fontWeight: "normal",
    fontStyle: "normal",
    fonts: [
      {
        src: "/fonts/Merriweather/Merriweather-Regular.ttf",
        fontWeight: "normal",
        fontStyle: "normal",
      },
      {
        src: "/fonts/Merriweather/Merriweather-Italic.ttf",
        fontWeight: "normal",
        fontStyle: "italic",
      },
      {
        src: "/fonts/Merriweather/Merriweather-Bold.ttf",
        fontWeight: "bold",
        fontStyle: "normal",
      },
      {
        src: "/fonts/Merriweather/Merriweather-BoldItalic.ttf",
        fontWeight: "bold",
        fontStyle: "italic",
      },
      {
        src: "/fonts/Merriweather/Merriweather-Black.ttf",
        fontWeight: "ultrabold",
        fontStyle: "normal",
      },
      {
        src: "/fonts/Merriweather/Merriweather-BlackItalic.ttf",
        fontWeight: "ultrabold",
        fontStyle: "italic",
      },
    ],
  });
};
