import { ThemeProvider } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import { trackPromise } from "react-promise-tracker";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import CookieLoader from "./components/CookieLoader";
import GlobalStyles from "./components/GlobalStyles";
import Toast from "./components/Toast";
import store from "./redux/store";
import ScrollToTop from "./ScrollToTop";
import theme from "./theme";
import * as constants from "./utils/Constants";
import {
  isJWTPresent,
  isNotNullOrUndefined,
  onValidateUserSuccessResponse,
} from "./utils/helper_functions";
import ValidateUser from "./utils/ValidateUser";
import About from "./views/About";
import Uber from "./views/Uber";
import AddedUpdate from "./views/AddedUpdate";
import Artist from "./views/Artist";
import Collection from "./views/Collection";
import Collections from "./views/Collections";
import Entry from "./views/Entry";
import Homepage from "./views/Homepage";
import PageNotFound from "./views/PageNotFound";
import Results from "./views/Results";
import i18ninit from "./i18n";
import ArtistPDF from "./views/ArtistPDF";
import EntryPDF from "./views/EntryPDF";
// import HowToUse from "./views/HowToUse";
// import Practice from "./views/Practice";
// import Contact from "./views/Contact";
// import PrivacyPolicy from "./views/PrivacyPolicy";
// import TermCondition from "./views/TermCondition";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // google analytics
  useEffect(() => {
    const googleAnalytics = document.createElement("script");
    googleAnalytics.type = "text/javascript";
    googleAnalytics.src =
      "https://www.googletagmanager.com/gtag/js?id=G-0RB2JGZB31";
    googleAnalytics.async = true;
    document.head.appendChild(googleAnalytics);

    const googleAnalyticsScript = document.createElement("script");
    googleAnalyticsScript.type = "text/javascript";
    googleAnalyticsScript.innerHTML = ` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-0RB2JGZB31'); `;
    document.head.appendChild(googleAnalyticsScript);
  }, []);

  // liblynx
  useEffect(() => {
    ReactSession.set("sid", uuidv4());

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.innerHTML = `var _ll = _ll || [];
                        _ll.push(["setDefaults", {
                        "pid": '${process.env.REACT_APP_LIB_LYNX_PID}',
                        "sid": '${ReactSession.get("sid")}',
                        "url": '${window.location.href}',
                        "plid": "aow",
                        "plname":"Artist of the World",
                        "dbid": "aow",
                        "dbname": "Artists of the World"
                        }]);`;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.pathname !== "/validate" &&
      window.location.pathname !== "/validate/"
    ) {
      if (!isJWTPresent()) {
        // disabled automatic login
        trackPromise(
          axios
            .post(
              constants.VALIDATE_USER_API,
              constants.getValidateUserAPIData()
            )
            .then((response) => {
              if (isNotNullOrUndefined(response?.data?.jwtToken)) {
                setIsLoggedIn(true);
                onValidateUserSuccessResponse(response.data);
              }
            })
            .catch(() => console.log("failed"))
        );
      } else {
        setIsLoggedIn(true);
      }
    }
  }, []);

  const routes = (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route exact path="/results" element={<Results />} />
      <Route exact path="/artist/:fileName" element={<Artist />} />
      <Route exact path="/artist/pdf/:fileName" element={<ArtistPDF />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/uber" element={<Uber />} />
      <Route exact path="/validate" element={<ValidateUser />} />
      <Route exact path="/collections" element={<Collections />} />
      <Route exact path="/collection/:id" element={<Collection />} />
      {/* <Route exact path="/howtouse" element={<HowToUse />} /> */}
      {/* <Route exact path="/contact" element={<Contact />} /> */}
      {/* <Route exact path="/privacypolicy" element={<PrivacyPolicy />} /> */}
      {/* <Route exact path="/termCondition" element={<TermCondition />} /> */}
      <Route exact path="/addedUpdate" element={<AddedUpdate />} />
      <Route exact path="/entry/:id" element={<Entry />} />
      <Route exact path="/entry/pdf/:id" element={<EntryPDF />} />
      <Route exact path="*" element={<PageNotFound />} />
    </Routes>
  );

  const RoutingFunction = () => {
    return routes;
  };
  ReactSession.setStoreType("localStorage");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      await i18ninit();
      setLoading(false);
    };
    init();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ScrollToTop />
        {loading ? (
          <></>
        ) : (
          <>
            <CookieLoader />
            <RoutingFunction />
            {isLoggedIn ? (
              <Toast
                status={true}
                type="success"
                message="Logged In Successfully"
              />
            ) : null}
          </>
        )}
        {/* <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            top: 0,
            zIndex: 999990,
            backdropFilter:
              "hue-rotate(62deg) saturate(110%) brightness(0.83) contrast(1.3)",
            pointerEvents: "none",
          }}
        ></div> */}
        {/* <div
          style={{
            position: "fixed",
            bottom: "0",
            height: "50px",
            right: "30%",
            width: "100px",
            backgroundColor: "#5a3c91",
            zIndex: 999999,
          }}
        ></div> */}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
