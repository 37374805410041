import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import { getOrdinal, isNotNullOrUndefined } from "../../utils/helper_functions";
import { Link } from "react-router-dom";
const useStyles = (theme) => ({
  image: {},
});
const ArtistTile = ({ data }) => {
  const { t } = useTranslation();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateObj = new Date();
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, "0");

  return (
    <>
      <img
        className="image"
        alt={t("Artist Image")}
        src={
          isNotNullOrUndefined(data?.imageURL) && data?.imageURL !== ""
            ? data?.imageURL
            : "/images/default_artist.png"
        }
        style={{
          width: "380px",
          height: "400px",
        }}
      />
      <Typography
        style={{
          fontWeight: "200px",
          fontSize: "14px",
          // marginTop: "10px",
          // marginBottom: "150px",
          // fontStyle: "normal",
          // fontWeight: "400",
          lineHeight: "35px",
          textTransform: "uppercase",
        }}
      >
        {t("born")} {getOrdinal(day) + " " + month}{" "}
        {data?.dateOfBirth.split(".")[0]}
      </Typography>

      <Box sx={{ display: "flex" }}>
        <Link to={"/artist/" + data?.id}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              // marginTop: "10px",
              // marginBottom: "150px",
              // fontStyle: "normal",
              // fontWeight: "400",
              lineHeight: "35px",
              color: "#007596",
            }}
          >
            {data?.title}
          </Typography>
        </Link>
      </Box>
    </>
  );
};
export default withStyles(useStyles)(ArtistTile);
