// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { useState, useEffect } from "react";
import { Amplify, Auth, Geo } from "aws-amplify";
import ReactMapGL, { Layer, NavigationControl, Source } from "react-map-gl";
import { AmplifyMapLibreRequest } from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import { IDENTITY_POOL_ID, REGION, MAP, PLACE } from "../../AWSMapConfig";
import MarkersFeature from "./MarkersFeature";
import { Spinner } from "../Spinner/Spinner";

const amplifyConfig = {
  Auth: {
    identityPoolId: IDENTITY_POOL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
    region: REGION, // REQUIRED - Amazon Cognito Region
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          [MAP.NAME]: {
            // REQUIRED - Amazon Location Service Map resource name
            style: [MAP.STYLE], // REQUIRED - String representing the style of map resource
          },
        },
        default: [MAP.NAME], // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      search_indices: {
        items: [PLACE], // REQUIRED - Amazon Location Service Place Index name
        default: PLACE, // REQUIRED - Amazon Location Service Place Index name to set as default
      },
      region: REGION, // REQUIRED - Amazon Location Service Region
    },
  },
};

// Configuring Amplify Geo with existing Amazon Cognito and Amazon Location Service information
// Fill values in configuration.js
Amplify.configure(amplifyConfig);

Auth.configure(amplifyConfig);

// Properties for the country boundaries layer
const countryBoundariesLayer = {
  id: "country-boundaries-layer",
  type: "fill",
  source: "country-boundaries-source",
  "source-layer": "country_boundaries",
  filter: ["in", "iso_3166_1_alpha_3"],
  paint: {
    "fill-color": "#ababab",
    "fill-opacity": 0.6,
  },
};

const Map = ({ geoCodedPlaces, displayLabels }) => {
  const [credentials, setCredentials] = useState();
  const [transformRequest, setRequestTransformer] = useState();
  let initialViewport =
    geoCodedPlaces[0]?.data?.geometry?.point?.length === 2
      ? {
          longitude: geoCodedPlaces[0]?.data?.geometry?.point[0],
          latitude: geoCodedPlaces[0]?.data?.geometry?.point[1],
          zoom: 3,
        }
      : {
          zoom: 1,
        };
  const [viewport, setViewport] = useState(initialViewport);

  useEffect(() => {
    countryBoundariesLayer.filter = ["in", "iso_3166_1_alpha_3"];
    geoCodedPlaces.forEach((element) => {
      countryBoundariesLayer.filter.push(element.data.country);
    });
  }, [geoCodedPlaces]);

  //Fetch credentials when the app loads
  useEffect(() => {
    const fetchCredentials = async () => {
      // Fetch AWS credentials from Amazon Cognito using Amplify Auth and storing it in state
      setCredentials(await Auth.currentUserCredentials());
    };
    fetchCredentials();
  }, []);

  // Create a new transformRequest function whenever the credentials change
  useEffect(() => {
    if (credentials != null) {
      // transformRequest is used to sign the request with AWS Sigv4 Auth
      const { transformRequest } = new AmplifyMapLibreRequest(
        credentials,
        Geo.getDefaultMap().region
      );

      // Wrap the new value in an anonymous function to prevent React from recognizing it as a function and immediately calling it
      setRequestTransformer(() => transformRequest);
    }
  }, [credentials]);

  return transformRequest ? (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="80vh"
      transformRequest={transformRequest}
      mapStyle={MAP.NAME}
      onViewportChange={setViewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN} //MapBox api used for country boundary source
    >
      <Source
        id="country-boundaries-source"
        name="country-boundaries-source"
        type="vector"
        url="mapbox://mapbox.country-boundaries-v1" //MapBox TileSet url
      >
        {/* Create a map layer to display country boundaries*/}
        <Layer {...countryBoundariesLayer} />
      </Source>
      <NavigationControl showCompass={false} style={{ right: 8, bottom: 36 }} />
      <MarkersFeature
        geoCodedPlaces={geoCodedPlaces}
        displayLabels={displayLabels}
      />
    </ReactMapGL>
  ) : (
    <Spinner show />
  );
};

export default Map;
