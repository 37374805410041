import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { PDFstyles } from "./PDFstyles";

export const PDFFooter = ({ entry }) => {
  const classes = StyleSheet.create(PDFstyles);
  const { t } = useTranslation();
  return (
    <View fixed style={classes.footer}>
      <View style={classes.rowSpaceBetween}>
        <Text>© Walter DeGruyter GmbH, Berlin/Boston</Text>
        <Text
          render={({ pageNumber, totalPages }) => {
            return t("Page x of y", {
              pageNumber: pageNumber,
              totalPages: totalPages,
            });
          }}
          fixed
        />
      </View>
    </View>
  );
};
