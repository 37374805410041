import Mark from "mark.js";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS } from "../Constants";
import { navigation, search } from "../../redux/actions/findOnPageActions";
import { useDispatch } from "react-redux";
// dont remove * & ?. they are wildcard search
const punctuations = "$&+,:;=@#|'<>.^()%!-";

const removeSpecialCharacters = (str) => {
  // dont remove * & ?. they are wildcard search
  const regex = new RegExp([`[${punctuations}]`], "g");
  return str?.replaceAll(regex, "");
};

const useFindOnPage = (
  highlightClass,
  searchWithinClassName,
  onlyMark = false
) => {
  const dispatch = useDispatch();

  const currentClass = highlightClass ?? CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS;

  const markInstance = new Mark(
    document.querySelector(searchWithinClassName ?? ".resultdata")
  );

  const [searchResults, setSearchResults] = useState([]);

  const [currActiveIndex, setCurrActiveIndex] = useState(0);

  const [searchString, setSearchString] = useState("");

  const [searchResultCount, setSearchResultCount] = useState(0);

  // This list store where to jump in the searchResultList for the new Active index
  const [jumpIndexList, setJumpIndexList] = useState([]);

  useEffect(() => {
    if (!onlyMark) {
      setSearchResultCount(getSearchResultCount());
    } else {
      if (searchResults?.length) {
        searchResults[0]?.setAttribute("class", "first-highlight");
        searchResults[0].scrollIntoView();
      }
    }
  }, [searchResults]);

  useEffect(() => {
    if (!onlyMark) {
      jumpTo(0);
    }
  }, [jumpIndexList]);

  const getSearchResultCount = () => {
    if (!searchResults || !searchResults.length) {
      return 0;
    }
    var newJumpIndexList = [];
    var lastLengthPrefix = 0;
    const nonSpaceCharCount = searchString
      .replaceAll(" ", "")
      .replaceAll("&nbsp;", "")
      .trim().length;
    for (let i = 0; i < searchResults.length; i++) {
      const result = searchResults[i];
      const innerHTML = removeSpecialCharacters(result.innerHTML)
        .replaceAll(" ", "")
        .replaceAll("&nbsp;", "")
        .trim();
      const len = innerHTML.length;

      if (!len) {
        continue;
      }

      lastLengthPrefix %= nonSpaceCharCount;
      if (lastLengthPrefix === 0) {
        newJumpIndexList.push(i);
      }

      lastLengthPrefix += len;
    }
    console.log(newJumpIndexList);
    setJumpIndexList(newJumpIndexList);
    return newJumpIndexList.length;
  };
  // searchVal can be a list too. permenant searches use that
  const findString = (
    searchVal,
    isPermanent,
    wildcardSearchEnabled = false
  ) => {
    if (typeof searchVal === "string") {
      searchVal = removeSpecialCharacters(searchVal);
    } else if (typeof searchVal === "object") {
      searchVal = searchVal.map((e) => removeSpecialCharacters(e));
    }
    // console.log(searchVal)
    setSearchString(searchVal);
    dispatch(search(searchVal ?? "", onlyMark));
    isPermanent = isPermanent || false;
    markInstance.unmark({
      element: isPermanent ? "permanentmark" : "mark",
      done: function () {
        markInstance.mark(searchVal, {
          separateWordSearch: false,
          acrossElements: true,
          wildcards: wildcardSearchEnabled ? "enabled" : "disabled",
          exclude: [
            ".searchIgnore",
            ".searchIgnore *",
            ".english-heading",
            ".german-heading",
          ],
          ignorePunctuation: punctuations.split(""),
          className: isPermanent ? "permanentHighlight" : "",
          element: isPermanent ? "permanentmark" : "mark",
          done: function () {
            setSearchResults(
              document.querySelectorAll(`${searchWithinClassName}  mark`)
            );
          },
        });
      },
    });
  };

  const jumpTo = (newCurrentIndex) => {
    if (searchResults?.length) {
      unMarkCurrentSearchResult();
      markActiveSearchResult(newCurrentIndex);
      setCurrActiveIndex(newCurrentIndex);
      const actualCurrentIndex = jumpIndexList[newCurrentIndex];
      searchResults[actualCurrentIndex]?.scrollIntoView();
      dispatch(navigation(newCurrentIndex));
    }
  };

  const unMarkCurrentSearchResult = () => {
    const minActiveSearchresult = jumpIndexList[currActiveIndex];
    const maxActiveSearchResult =
      currActiveIndex + 1 === jumpIndexList.length
        ? searchResults.length
        : jumpIndexList[currActiveIndex + 1];
    for (
      let index = minActiveSearchresult;
      index < maxActiveSearchResult;
      index++
    ) {
      const element = searchResults[index];
      element.classList.remove(currentClass);
    }
  };

  const markActiveSearchResult = (newCurrentIndex) => {
    const minActiveSearchresult = jumpIndexList[newCurrentIndex];
    const maxActiveSearchResult =
      newCurrentIndex + 1 === jumpIndexList.length
        ? searchResults.length
        : jumpIndexList[newCurrentIndex + 1];
    console.log(searchString);
    console.log(minActiveSearchresult, maxActiveSearchResult);
    for (
      let index = minActiveSearchresult;
      index < maxActiveSearchResult;
      index++
    ) {
      const element = searchResults[index];
      element.classList.add(currentClass);
    }
  };

  const goToNext = () => {
    if (searchResults?.length) {
      const nextIndex = (currActiveIndex + 1) % searchResultCount;
      jumpTo(nextIndex);
    }
  };

  const goToPrevious = () => {
    if (searchResults?.length) {
      const prevIndex =
        (currActiveIndex - 1 + searchResultCount) % searchResultCount;
      jumpTo(prevIndex);
    }
  };

  return [
    findString,
    goToNext,
    goToPrevious,
    currActiveIndex,
    searchResultCount,
  ];
};

export default useFindOnPage;

useFindOnPage.propTypes = {
  highlightClass: PropTypes.string,
  searchWithinClassName: PropTypes.string,
};
