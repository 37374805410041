export const EntryTabs = {
  LEMMA_TAB: 0,
  CITE_TAB: 1,
};

export const SEARCH_TYPE = {
  STANDARD_SEARCH: 0,
  ADVANCE_SEARCH: 1,
  ALPHABETICAL_SEARCH: 2,
};

export const SEARCH_FIELDS = {
  ALL_FIELDS: 0,
  NAME: 1,
  FURTHER_NAMES: 2,
  AUTHOR: 3,
  COUNTRY: 4,
  OCCUPATION: 5,
};

export const SEARCH_QUOLIFIER = {
  AND: 0,
  OR: 1,
  NOT: 2,
};

export const CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS = "current";
export const SALES_URL = "https://www.degruyter.com/cms/pages/contacts?lang=en";

export const VALIDATE_USER_API =
  process.env.REACT_APP_SERVER_HOST_NAME + "/auth/validateUser";

export const getValidateUserAPIData = () => ({
  userAgent: window.navigator.userAgent,
  url: window.location.href,
});

export const ArticleTitles = {
  vitaZeile: "VitaZeile",
  biogramm: "Biogramm",
  werkstandorte: "Werkstandorte",
  selbstzeugnisse: "Selbstzeugnisse",
  einzelausstellungen: "Einzelausstellungen",
  gruppenausstellungen: "Gruppenausstellungen",
  qThBVoAKL: "QThBVoAKL",
  qLexika: "QLexika",
  qGedruckte: "QGedruckte",
};
