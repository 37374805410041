import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Grid, Button, Typography, Divider } from "@material-ui/core";

import { Trans, useTranslation } from "react-i18next";
import ArtistName from "../components/ArtistName";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { trackPromise } from "react-promise-tracker";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  section: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: "90px",
      paddingTop: "60px",
    },
  },
  // contentBox:{
  //   marginBottom:"50px !important",
  // },
  title: {
    fontSize: "40px",
    fontWeight: "700",
    color: "#000000",
    textAlign: "",
    lineHeight: "30px",
    "&:hover": {
      borderRadius: "50px !important",
    },
    [theme.breakpoints.up("md")]: {
      // marginBottom: "55px",
    },
  },
  backToText: {
    marginLeft: "10px",
  },
  midpart: {
    width: "680px",
    height: "400px !important",
    fontFamily: "Times",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    marginLeft: "50px !important",
  },
  image: {
    width: "100%",
    height: "50%",
  },
  btnstyle: {
    marginLeft: "70px !important",
    position: "absolute",
    padding: "20px !important",
  },
  coverarea: {
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
  },
  cover: {
    marginBottom: "20px",
  },
  videosection: {
    marginTop: "60px",
    marginBottom: "30px",
  },
  list: {
    padding: "20px 0 30px 15px",
    "& li": {
      fontFamily: '"Times"',
      fontSize: "20px",
      marginBottom: "15px",
    },
  },
  overx: {
    backgroundColor: "white !important",
  },
  artistwarp: {
    marginTop: "50px",
  },
  backGroundImageDiv: {
    // backgroundImage: "url('/images/europeana-gkTNaXo0keM-unsplash 1.png')",
  },
  mainDiv: {
    margin: "90px",
    marginTop: "0px",
    marginLeft: "100px",
  },
  topDiv: { position: "absolute", left: 90, top: 120 },
  img: { width: "100%", height: "625px", objectFit: "cover" },
  backBtn: { marginTop: "25px", color: "white", fontSize: "16px" },
  titleDiv: { marginTop: "380px", padding: "10px" },
  descriptionDiv: { marginTop: "60px", marginBottom: "60px" },
}));

function Collection(props) {
  const { t } = useTranslation();
  const language = i18next.language;
  const classes = useStyles();

  const { id } = useParams();
  const [Collection, setCollection] = useState({});
  const [Books, setBooks] = useState([]);

  useEffect(() => {
    trackPromise(
      axios
        .get(process.env.REACT_APP_SERVER_HOST_NAME + "/collection/" + id)
        .then((res) => {
          setCollection(res?.data);
          console.log(res?.data);
          setBooks(res?.data?.artists);
        })
        .catch((error) => {
          console.log(error.message);
        })
    );
  }, [id]);

  let gridBooks = [];
  for (let index = 0; index < Books?.length; index += 2) {
    gridBooks.push(Books?.slice(index, index + 2));
  }

  gridBooks = gridBooks.map((arr) => (
    <>
      {arr?.length !== 0 ? (
        <Grid container spacing={4} className={classes.nameGrid}>
          {arr?.length !== 0 ? (
            <Grid item md={6}>
              <ArtistName {...arr.splice(0, 1)[0]} isArtist />
              <Divider sx={{ borderBottomWidth: 2 }} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={6}>
              <ArtistName {...arr.splice(0, 1)[0]} isArtist />
              <Divider sx={{ borderBottomWidth: 2 }} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </>
  ));

  const cdata =
    language === "en"
      ? {
          name: Collection?.name,
          description: Collection?.description,
          imageCredit: Collection?.imageCredit,
        }
      : {
          name: Collection?.nameDE || Collection?.name,
          description: Collection?.descriptionDE || Collection?.description,
          imageCredit: Collection?.imageCreditDE || Collection?.imageCredit,
        };

  return (
    <div>
      <Navbar isHomepage={false} />
      <div className={classes.backGroundImageDiv}>
        <img
          className={"image " + classes.img}
          alt={cdata.name + " " + t("Collection Image")}
          src={Collection?.imageURL}
        />
        <Typography
          variant="body1"
          align="right"
          style={{
            fontSize: "12px",
            fontWeight: 400,
            display: "block",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
        >
          {cdata?.imageCredit}
        </Typography>
      </div>
      <div className={classes.topDiv}>
        <Link to="/collections">
          <Button variant="text" className={classes.backBtn}>
            <ArrowBackIcon />
            &nbsp; {t("Collections")}
          </Button>
        </Link>
        <div className={classes.titleDiv}>
          <Typography
            variant="h2"
            style={{
              fontSize: "14px",
              color: "white",
            }}
          >
            {Collection?.collectionType === "template"
              ? t("COLLECTION")
              : Collection?.collectionType === "free"
              ? t("FREE ACCESS COLLECTION")
              : null}
          </Typography>

          <Typography
            variant="h2"
            style={{
              fontSize: "40px",
              color: "white",
              marginTop: "10px",
            }}
          >
            {cdata?.name}
          </Typography>
        </div>
      </div>
      <div className={classes.mainDiv}>
        <div className={classes.descriptionDiv}>
          <Typography
            style={{
              fontFamily: "Times",
              fontSize: "18px",
            }}
          >
            {cdata?.description}
          </Typography>
        </div>
        {gridBooks}
      </div>
      <OuterSpinner />
      <Footer />
    </div>
  );
}
export default Collection;
