import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import BackToHome from "../components/Buttons/BackToHome";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import styles from "../staticPages.module.css";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const About = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === "de") {
      navigate("/uber");
    }
  }, [i18n.language, navigate]);

  return (
    <div className={"body-container "}>
      <Navbar isHomepage={false} />

      <section className="section">
        <Container className="container">
          <Grid container spacing={0}>
            <Grid item xs={12} md={10} lg={8}>
              <BackToHome />

              <h1>About Artists of the World</h1>

              <div className={styles["static-page"]}>
                <div>
                  <p>
                    The database <em>Artists of the World</em> is the world’s
                    most current, reliable, and extensive reference work on
                    artists. It contains authoritative, up-to-date biographical
                    information on more than 1.2 million artists – more than any
                    other database in the world. It contains biographical
                    information on artists and entries on communities of visual
                    artists from all over the world and from all eras. It covers
                    all genres of fine arts, painting, graphics, sculpture,
                    architecture, photography, video, installation art, and much
                    more.
                  </p>
                  <p></p>
                  <h3>Authors (examples):</h3>
                  <p>
                    Stephanie Buck (<a href="/artist/_00142956">Hans Holbein</a>
                    ), Vaughan Hart (<a href="/artist/_00083633">Inigo Jones</a>
                    ), Peter Humfrey (<a href="/artist/_00169362">Tizian</a>),
                    Christian Spies (
                    <a href="/artist/_00101536">Pablo Picasso</a>), Naoki Sato (
                    <a href="/artist/_00142819T">Helene Schjerfbeck</a>),
                    Martino Stierli (
                    <a href="/artist/_00802102">Rem Koolhaas</a>), Frank Zöllner
                    (<a href="/artist/_00085517">Leonardo da Vinci</a>)
                  </p>
                  <p></p>
                  <h3>Your Benefits</h3>
                  <ul>
                    <li>
                      More than 1.2 million editorially reviewed entries on
                      artists and artist groups
                    </li>
                    <li>
                      More than 500,000 detailed, signed biographical articles,
                      with searchable full text
                    </li>
                    <li>
                      Various search and filter criteria for professional and
                      scholarly search queries in a user-friendly interface
                    </li>
                    <li>
                      Information on places of activity and artists’ networks in
                      the form of maps and lists
                    </li>
                    <li>
                      Unlimited number of simultaneous users per campus or
                      institution
                    </li>
                  </ul>
                  <p></p>
                  <p>
                    For further information and prices for{" "}
                    <strong>institutional access,</strong> please contact our{" "}
                    <a
                      href="https://www.degruyter.com/publishing/about-us/contact/sales"
                      target="_blank"
                    >
                      Sales Team.
                    </a>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <OuterSpinner />
      <Footer />
    </div>
  );
};

export default About;
