import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./Spinner.css";

export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return props.show || promiseInProgress ? (
    <div className="spinner">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
};
