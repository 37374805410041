import { Button, Divider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useTranslation, Trans } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  button: {
    marginRight: "10px !important",
    marginBottom: "10px !important",
    border: "1px solid #007596 !important",
    color: "#007596 !important",
    "&:hover": {
      backgroundColor: "#DDE9EE !important",
    },
  },
  value: { marginLeft: "5px !important", fontWeight: "bold !important" },
});

const AppliedFilter = ({ id, name, value, handleFilterRemove }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Button
        variant="outlined"
        endIcon={<CloseIcon />}
        className={classes.button + " gm"}
        onClick={handleFilterRemove()}
      >
        <Typography>{name}:</Typography>
        <Typography className={classes.value}>{value}</Typography>
      </Button>
    </>
  );
};
export default AppliedFilter;
