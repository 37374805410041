import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import {
  capitalizeStr,
  isNotNullOrEmptyStr,
} from "../../utils/helper_functions";
import MUISwitch from "../UseSwitchesCustom";

const useStyles = makeStyles((theme) => ({
  filterName: {
    fontWeight: "600 !important",
    fontFamily: "Gotham-Book,tllmedium,Times !important",
  },
  accordion: {
    boxShadow: "none !important",
  },
  accordionSummary: {
    padding: "0px !important",
  },
  accordionDetails: {
    marginBottom: "20px",
  },
  checkBox: {
    "&.Mui-checked": { color: "#007596 !important" },
  },
}));

const FilterItem = ({
  id,
  filterChecked,
  handleFilterToggle,
  title,
  values,
  appliedRange,
  range,
  applyDOBFilter,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [fromInput, setFromInput] = useState(appliedRange?.from);
  const [toInput, setToInput] = useState(appliedRange?.to);

  useEffect(() => {
    setFromInput(appliedRange?.from);
    setToInput(appliedRange?.to);
    if (
      isNotNullOrEmptyStr(appliedRange?.from) &&
      isNotNullOrEmptyStr(appliedRange?.to)
    ) {
      if (
        parseInt(appliedRange?.from) >= parseInt(range?.from) &&
        parseInt(appliedRange?.to) <= parseInt(range?.to) &&
        parseInt(appliedRange?.from) <= parseInt(appliedRange?.to)
      ) {
        document.getElementById("message").style.visibility = "hidden";
      } else {
        document.getElementById("message").style.visibility = "visible";
      }
    }
  }, [appliedRange?.from, appliedRange?.to, range?.from, range?.to]);

  function handleChange(event) {
    console.log("From handleChange: " + event.target.value);
    if (event.target.name === "from-input") {
      setFromInput(event.target.value);
    } else if (event.target.name === "to-input") {
      setToInput(event.target.value);
    } else if (event.target.name === "year-input") {
      setFromInput(event.target.value);
      setToInput(event.target.value);
    }
  }

  function onApply(event) {
    if (
      parseInt(fromInput) >= parseInt(range?.from) &&
      parseInt(toInput) <= parseInt(range?.to) &&
      parseInt(fromInput) <= parseInt(toInput)
    ) {
      document.getElementById("message").style.visibility = "hidden";
      applyDOBFilter(fromInput, toInput);
    } else {
      document.getElementById("message").style.visibility = "visible";
    }
  }

  const [isExactYear, setIsExactYear] = useState(false);
  const switchClick = (e, name) => {
    setIsExactYear(e.target.checked);
  };

  const styleTextField = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007596",
      },
    },
  };

  const dobAccordion = id === "dob" && (
    <>
      <Typography className="gb filter-content">
        {t("Range")}: {range?.from} - {range?.to}
      </Typography>
      <MUISwitch
        leftswitch={t("Range")}
        rightswitch={t("Exact")}
        name="range-switch"
        switchClick={switchClick}
      />
      {isExactYear ? (
        <div className="filter-input-div">
          <Typography className="gm">{t("Year")}:</Typography>
          <TextField
            type="number"
            name="year-input"
            className="dob-input"
            sx={styleTextField}
            value={fromInput}
            placeholder={t("YYYY")}
            onChange={handleChange}
          />
        </div>
      ) : (
        <>
          <div className="filter-input-div">
            <Typography className="gm">{t("From")}:</Typography>
            <TextField
              type="number"
              name="from-input"
              className="dob-input"
              sx={styleTextField}
              value={fromInput}
              placeholder={t("YYYY")}
              onChange={handleChange}
            />
          </div>
          <div className="filter-input-div">
            <Typography className="gm">{t("To")}:</Typography>
            <TextField
              type="number"
              name="to-input"
              className="dob-input"
              sx={styleTextField}
              value={toInput}
              placeholder={t("YYYY")}
              onChange={handleChange}
            />
          </div>
        </>
      )}

      <Button variant="contained" className="apply-button" onClick={onApply}>
        {t("Apply")}
      </Button>
      <Typography id="message" className="gb validation-text">
        {t("Please enter between range!")}
      </Typography>
    </>
  );

  const accessTypeAccordion = id === "accessType" && (
    <>
      <ListItem
        key={"FilterListItem-" + title + "-" + 0}
        role="listitem"
        button
        onClick={handleFilterToggle(id, "free")}
        className={classes.listItem + " no-padding"}
      >
        <ListItemIcon>
          <Checkbox
            checked={filterChecked.indexOf("free") !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{
              "aria-labelledby": `list-item-free-label`,
            }}
            className={classes.checkBox}
          />
        </ListItemIcon>
        <ListItemText
          id={`list-item-free-label`}
          primary={t("Publicly available")}
        />
      </ListItem>

      <ListItem
        key={"FilterListItem-" + title + "-" + 1}
        role="listitem"
        button
        onClick={handleFilterToggle(id, "free")}
        className={classes.listItem + " no-padding"}
      >
        <ListItemIcon>
          <Checkbox
            checked={filterChecked.indexOf("free") === -1}
            tabIndex={-1}
            disableRipple
            inputProps={{
              "aria-labelledby": `list-item-free2-label`,
            }}
            className={classes.checkBox}
          />
        </ListItemIcon>
        <ListItemText id={`list-item-free2-label`} primary={t("All Content")} />
      </ListItem>
    </>
  );

  const othersAccordion =
    ["dob", "accessType"].includes(id) === false &&
    values?.map((value, i) => (
      <ListItem
        key={"FilterListItem-" + title + "-" + i}
        role="listitem"
        button
        onClick={handleFilterToggle(id, value.key)}
        className={classes.listItem + " no-padding"}
      >
        <ListItemIcon>
          <Checkbox
            checked={filterChecked.indexOf(value.key) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{
              "aria-labelledby": `list-item-${value.key}-label`,
            }}
            className={classes.checkBox}
          />
        </ListItemIcon>
        <ListItemText
          id={`list-item-${value.key}-label`}
          primary={
            capitalizeStr(value.key) +
            (value.docCount ? ` (${value.docCount})` : "")
          }
        />
      </ListItem>
    ));

  return (
    <>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.filterName}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails + " no-padding"}>
          {accessTypeAccordion}
          {dobAccordion}
          {othersAccordion}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FilterItem;
