import {
  Button,
  Container,
  Grid,
  Typography as MatTypography,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import ExpandableContent from "react-expandable-content/dist/ExpandableContent";

import { makeStyles, useTheme } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import * as constants from "../utils/Constants";
import PayWall from "../components/Paywall";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ConnectedPlaces from "../components/ConnectedPlaces";
import { Spinner } from "../components/Spinner/Spinner";
import commonStyles from "../utils/commonStyles";
import {
  capitalizeStr,
  formateDate,
  getSearchFiltersFromQuery,
  isNotNullOrEmptyArr,
  isNotNullOrEmptyStr,
  isNotNullOrUndefined,
  isObjEmpty,
  prepareSearchFormListForHighlight,
  getPlacesFromGeoRegions,
} from "../utils/helper_functions";
import useFindOnPage from "../utils/hooks/useFindOnPage";
import { useInitialRender } from "../utils/hooks/useInitialRender";

import { DGLogoPDF } from "../components/DGLogo/DGLogo";
import { PDFLoader } from "../components/PDF/PDFLoader";
import { saveAs } from "file-saver";
import { registerFonts } from "../components/PDF/registerFonts";
import { PDFstyles } from "../components/PDF/PDFstyles";
import { Typography } from "../components/PDF/PDFTypography";
import ArtistName from "../components/PDF/ArtistNamePDF";

import { PDFHeader } from "../components/PDF/Header";
import { PDFFooter } from "../components/PDF/Footer";
import { PDFCover } from "../components/PDF/Cover";
import { ArticleTitles } from "../utils/Constants";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  ReactPDF,
  Image,
  BlobProvider,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

function EntryPDF(props) {
  registerFonts();

  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const isInitialRender = useInitialRender();
  const currentUrl = new URL(window.location.href);
  const currentUrlParams = currentUrl.searchParams;
  const [showLineNumbers, setShowLineNumbers] = useState(false);
  const [tabDataLoaded, setTabDataLoaded] = useState([]);
  const classes = StyleSheet.create(PDFstyles);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [EntryData, setEntryData] = React.useState({});
  const isFamily = EntryData?.entryType === "Family";
  const [ConnectedPlacesData, setConnectedPlacesData] = React.useState([]);

  var token = localStorage.getItem("jwtToken");
  const currentIndex = currentUrlParams.get("currentIndex");
  let location = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  const locationHash = location.hash;
  const searchURL = useRef(currentUrlParams.get("search_url"));
  const mobileFindOnPageRef = useRef(null);
  const { alphabetSearch, searchForm } = getSearchFiltersFromQuery(
    new URLSearchParams(decodeURIComponent(searchURL.current ?? ""))
  );
  const entryTabsRef = useRef(null);

  const addSortHref = (sortParams) => {
    if (searchURL.current) {
      sortParams.set("search_url", searchURL.current);
    }
  };

  const login = currentUrlParams.get("login");
  const checklogin = () => {
    if (login === "success") {
      // remove login param from url
      currentUrlParams.delete("login");
      currentUrl.search = currentUrlParams.toString();
      window.history.replaceState({}, "", currentUrl.toString());
    }
  };

  // const history = useHistory();
  const navigate = useNavigate();
  const [entry, setEntry] = React.useState({});
  const [citeData, setCiteData] = React.useState();
  const [currentTab, setCurrentTab] = useState(constants.EntryTabs.LEMMA_TAB);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let dialogPadding = fullScreen ? "15px" : "40px";

  const navigateToValidate = () => {
    navigate({
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);
      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );
    }
  }, [token]);

  useEffect(() => {
    checklogin();
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      setIsLoggedIn(false);
      setIsUserAuthorized(false);
    } else {
      setIsLoggedIn(true);

      if (isNotNullOrUndefined(localStorage.getItem("isAuthorized")))
        setIsUserAuthorized(
          localStorage.getItem("isAuthorized") === "true" ? true : false
        );

      setTabDataLoaded(() => []);
    }
  }, [id]);

  useEffect(() => {
    if (isFamily && !isNotNullOrEmptyArr(ConnectedPlacesData))
      trackPromise(
        axios
          .get(
            process.env.REACT_APP_SERVER_HOST_NAME +
              "/entry/" +
              id +
              "/connected-places"
          )
          .then((response) => {
            let placesSet = new Set();

            response.data?.forEach((element) => {
              element.split("; ").forEach((place) => {
                placesSet.add(place);
              });
            });
            setConnectedPlacesData([...placesSet]);
          })
          .catch((error) => {
            console.log("API request Error: ", error);
          }),
        "connected-places"
      );
  }, [isFamily]);

  const loadData = () => {
    const tempCurrentTab = currentTab; // TODO: If tab changes in between API call;

    trackPromise(
      axios
        .get(process.env.REACT_APP_SERVER_HOST_NAME + "/entry/" + id)
        .then((response) => {
          setEntryData(response.data);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigateToValidate();
          } else {
            console.log("API request Error: ", error);
          }
        })
    );
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const { promiseInProgress: connectedPlacesPromiseInProgress } =
    usePromiseTracker({
      area: "connected-places",
    });

  const members = EntryData?.artists?.length && (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      {EntryData?.artists?.map((artist, index) => (
        <View key={"member" + index} style={{ width: "100%" }}>
          <ArtistName id={artist.id} name={artist.name} isArtist />
        </View>
      ))}
    </View>
  );

  const showConnectedPlaces = (data) => (
    <>
      <Typography style={{ ...classes.h2, marginTop: 20 }}>
        {t("Places of Activity")}
      </Typography>
      <Typography style={classes.body1}>{data.join(", ")}. </Typography>
    </>
  );

  const aowID = EntryData?.id && (
    <Typography style={classes.artistDetail}>
      {t("AOW ID")}: {EntryData.id}
    </Typography>
  );

  const overviewData =
    EntryData?.foundingDate ||
    EntryData?.closingDate ||
    (EntryData?.geoRegion && EntryData?.geoRegion?.length > 0) ? (
      <View style={{ paddingTop: 25 }}>
        <Typography style={classes.mainTitle}>{t("Overview")}</Typography>
        {EntryData?.foundingDate ||
        EntryData?.closingDate ||
        EntryData?.foundingLocation ||
        EntryData?.closingLocation ? (
          <>
            <Typography style={classes.h2}>{t("Active")}</Typography>
            <Typography style={classes.body1}>
              {t("From")}: {EntryData?.foundingLocation}
              {isNotNullOrEmptyStr(EntryData?.foundingLocation) ? ", " : null}
              {isNotNullOrEmptyStr(EntryData?.foundingDate)
                ? EntryData?.foundingDate
                : "?"}
            </Typography>
            <br />
            <Typography style={classes.body1}>
              {t("To")}: {EntryData?.closingLocation}
              {isNotNullOrEmptyStr(EntryData?.closingLocation) ? ", " : null}
              {isNotNullOrEmptyStr(EntryData?.closingDate)
                ? EntryData?.closingDate
                : "?"}
            </Typography>
          </>
        ) : null}

        {members && (
          <View>
            <Text style={{ ...classes.h2, marginTop: 20 }} id="Members">
              {t("Members")}
            </Text>
            <View spacing={4} className={classes.nameGrid}>
              {members}
            </View>
          </View>
        )}

        {isFamily &&
          isNotNullOrEmptyArr(ConnectedPlacesData) &&
          showConnectedPlaces(ConnectedPlacesData)}

        {EntryData?.geoRegion &&
          EntryData?.geoRegion?.length > 0 &&
          showConnectedPlaces(getPlacesFromGeoRegions(EntryData?.geoRegion))}
      </View>
    ) : null;

  const getArticleContent = () => {
    let articleContentArr = [];
    if (
      isFamily &&
      EntryData?.richText &&
      Object.keys(EntryData?.richText).length > 0
    ) {
      Object.keys(EntryData?.richText).forEach((element) => {
        if (
          isNotNullOrEmptyStr(ArticleTitles[element]) &&
          !EntryData?.richText[element]?.endsWith("/>")
        )
          articleContentArr.push({
            title: ArticleTitles[element],
            content: EntryData?.richText[element],
          });
      });
    }
    return articleContentArr;
  };
  let articleContent = useMemo(() => getArticleContent(), [EntryData]);

  const idWithoutLeadingUnderscore = EntryData?.id?.replace(/^_+/, "");
  const docName = "AOW_ID_" + idWithoutLeadingUnderscore;

  const downloaded = useRef(undefined);
  const savePDF = (blob) => {
    if (!downloaded.current) {
      saveAs(blob, docName + ".pdf");
      downloaded.current = blob;
      // don't close the window in  and Safari, as it cancels the download
      if (window.navigator.userAgent.indexOf("Chrome") === -1) {
        // window.close();
      } else {
        // window.close();
      }
    }
  };

  const contents = EntryData?.name && (
    <>
      <View style={classes.contents}>
        <Typography style={classes.h2}>{t("Contents")}</Typography>
        <Text> </Text>

        <View style={classes.mainTitle1}>
          <Text style={classes.link} src={"#Overview"}>
            {t("Overview")}
          </Text>
        </View>

        {isFamily && articleContent.length > 0 && (
          <View style={classes.mainBox}>
            <View style={classes.mainTitle1}>
              <Text style={classes.link} src={"#Article"}>
                {t("Article")}
              </Text>
            </View>
            {articleContent.map((sec, i) => (
              <View key={"sub" + i} style={classes.boxSubTitle}>
                <Text style={classes.link} src={"#Article:" + sec.title}>
                  {sec.title}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
    </>
  );

  const ldata = (
    <View style={classes.contentDiv + " artistContent"}>
      <Grid container spacing={4}>
        <Grid item md={9}>
          {isFamily && (
            <View style={classes.contentDiv + " artistContent"}>
              <View break style={{ marginBottom: 30 }}>
                <Text id="Article" style={classes.mainTitle}>
                  {t("Article")}
                </Text>
                <View>
                  {articleContent.length > 0 &&
                    articleContent.map((sec, i) => (
                      <View key={"tbl" + i}>
                        <View
                          style={{
                            ...classes.tableTextContent,
                            marginTop: `${i !== 0 ? 15 : 5}`,
                          }}
                        >
                          <Text id={"Article:" + sec.title} style={classes.h3}>
                            {sec.title}
                            {"\n"}
                          </Text>
                        </View>
                        <View>
                          <Typography
                            style={{ ...classes.body1, marginTop: 5 }}
                            dangerouslySetInnerHTML={{
                              __html: sec.content,
                            }}
                          ></Typography>
                        </View>
                      </View>
                    ))}
                </View>
              </View>
            </View>
          )}

          {EntryData?.geoRegion && EntryData?.geoRegion?.length > 0 ? (
            <View style={{ marginTop: 20 }}>
              <Text style={classes.h2} id="connected-places">
                {t("Places of Activity")}
              </Text>
              {/* <ConnectedPlaces
                places={getPlacesFromGeoRegions(EntryData?.geoRegion)}
              /> */}
            </View>
          ) : null}
        </Grid>
      </Grid>
    </View>
  );

  const doc = !promiseInProgress &&
    !connectedPlacesPromiseInProgress &&
    EntryData?.name &&
    (EntryData?.isFreeAccess || (isLoggedIn && isUserAuthorized)) && (
      <Document
        title={docName}
        creator="DeGruyter - Artists of the World"
        producer="tll.degruyter.com"
        language={i18n.language}
      >
        <Page size="A4" style={classes.page} wrap>
          <PDFCover entry={EntryData}></PDFCover>
          <Text style={classes.h1}>{EntryData.name}</Text>
          <Text style={classes.h2}>{t(EntryData?.entryType)}</Text>
          {contents}
          <PDFFooter></PDFFooter>
        </Page>

        {/* Second page */}
        <Page size="A4" style={classes.page} wrap>
          <PDFHeader entry={EntryData}></PDFHeader>
          <View wrap style={classes.section}>
            <View>
              <View id="Overview" style={classes.titlearea}>
                <View style={classes.results}>
                  <Text style={classes.h1}>{EntryData.name}</Text>
                  <Text style={classes.h2}>{t(EntryData?.entryType)}</Text>
                </View>
              </View>
              {/* {artistIDs} */}
              {/* {keywordsSection} */}
              {aowID}

              {overviewData}

              {/* Third page */}
              {ldata}
            </View>
            <View item md={3}>
              <View style={classes.container}>
                {isNotNullOrUndefined(entry?.imageURL) &&
                entry?.imageURL !== "" ? (
                  <View style={classes.imgbox}>
                    <Image
                      alt={t("Artist Image")}
                      src={entry?.imageURL}
                      style={{
                        width: "380px",
                      }}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
          <PDFFooter></PDFFooter>
        </Page>
      </Document>
    );

  return (
    <div className={"body-container"}>
      <div className="main">
        {/* show a spinner while the PDF is being generated  */}
        {/* Automatic download */}
        {doc && (
          <BlobProvider document={doc}>
            {({ blob, url, loading, error }) => {
              if (!loading && blob && url) {
                savePDF(blob);
                return <PDFLoader loaded={url} />;
              } else {
                return <PDFLoader loaded={false} />;
              }
            }}
          </BlobProvider>
        )}
        {/* Display in browser */}
        {/* {doc && (
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            {doc}
          </PDFViewer>
        )} */}
      </div>
    </div>
  );
}
export default EntryPDF;
