const common = (theme) => ({
  showLineNumbers: {
    "& .line-number span": {
      background: "rgba(0, 93, 120, 0.25)",
      fontWeight: "700",
      color: "#005D78",
      padding: "5px",
    },
  },
  hideLineNumbers: {
    "& .line-number > span": {
      display: "none",
    },
  },
});

export default common;