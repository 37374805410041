import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CustomTooltip from "../CustomTooltip";

const useStyles = makeStyles((theme) => ({
  format: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
  },
  body: {
    padding: "0px 30px",
  },
  paddingHorizontal0: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  selectContainer: {
    margin: "20px 0",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        width: "100% !important",
      },
    },
  },
  selectPaper: {
    height: "50px",

    background: "#FFFFFF",
    border: "1px solid #717173",
    borderRadius: "50px",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      width: "160px",
    },
  },
  select: {
    background: "#FFFFFF",
    width: "100% !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#121212 !important",
    borderRadius: "50px",
    "& .MuiSelect-select": {
      fontWeight: "500 !important",
      fontSize: "16px !important",
      paddingLeft: "18px",
      borderRadius: "50px",
    },
    "& svg": {
      color: "#121212 !important",
      marginRight: "10px",
    },
  },
  menuItem: {
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "26px !important",
  },
  selected: {
    backgroundColor: "transparent !important",
  },
  copied: {
    background: "#eaeaea !important",
    color: "#000000 !important",
    width: "100%",
  },
  clipboardArea: {
    padding: "10px",
    // border: "0.5px solid #717173",
    overflowWrap: "anywhere",
    wordBreak: "break-word",
    [theme.breakpoints.up("sm")]: {
      // padding: "20px",
    },
    "& *": {
      display: "inline !important",
    },
  },
  selectPaperContainer: {
    marginRight: "20px !important",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px !important",
      marginBottom: "20px !important",
    },
  },
  clipboardAreaText: {
    fontSize: "16px !important",
    lineHeight: "26px !important",
  },
  copyToClipboard: {
    textTransform: "initial !important",
  },
  dialogContainer: {
    width: "100% !important",
  },
  formatbtns: {
    "& button:hover": {
      backgroundColor: "#eaeaea !important",
      color: "#000000 !important",
    },
  },
  quoteIcon: {
    color: "#007596",
  },
}));
// const TooltipIcon = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#007596',
//     color: 'white',
//   },
//   arrow: {
//    color: '#007596',
//   },
// }))(Tooltip);
const CiteButton = ({
  artistId,
  entryId,
  apaString,
  mlaString,
  harvardString,
  chicagoString,
  vancouverString,
}) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { t, i18n } = useTranslation();

  const downloadCite = (type) => {
    var itemType = "entry";
    if (artistId) {
      itemType = `book/${artistId}`;
    } else {
      itemType = `entry/${entryId}`;
    }

    window.open(
      `${process.env.REACT_APP_SERVER_HOST_NAME}/download/${itemType}/cite?citeType=${type}`,
      "_self"
    );
  };

  const replaceQuotes = (str) => {
    return str; // Disabled for now
    // for language "de", replace “ with „ and ” with “
    // if (i18n.language === "de") {
    //   return str.replace(/“/g, "„").replace(/”/g, "“");
    // } else {
    //   return str;
    // }
  };

  const selectMap = {
    MLA: replaceQuotes(mlaString),
    APA: replaceQuotes(apaString),
    Harvard: replaceQuotes(harvardString),
    Chicago: replaceQuotes(chicagoString),
    Vancouver: replaceQuotes(vancouverString),
  };

  const [open, setOpen] = useState(false);

  const [selectedFormat, setSelectedFormat] = useState("MLA");

  const [copiedToClipboard, setCopiedToClipBoard] = useState(false);

  const copyToClipboard = () => {
    const area = document.getElementById("clipboardArea");
    copy(area.innerText);
    setCopiedToClipBoard(true);
  };

  const onSelectChange = (e) => {
    setSelectedFormat(e.target.value);
    setCopiedToClipBoard(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopiedToClipBoard(false);
  };

  const citModal = (
    <Dialog
      scroll="body"
      open={open}
      className="cite-container"
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{ container: classes.dialogContainer }}
    >
      <div className="cite-modal">
        <div className="modal-header">
          <Typography
            style={{ width: "100%" }}
            align="center"
            variant="h2"
            className="gm"
            color="inherit"
          >
            <Trans i18nKey="Cite">Cite</Trans>
          </Typography>
        </div>
        <div className="modal-close-btn">
          <IconButton onClick={handleClose}>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L22.2132 22.2132" stroke="black" strokeWidth="2" />
              <path d="M22 1L0.786795 22.2132" stroke="black" strokeWidth="2" />
            </svg>
          </IconButton>
        </div>
        <div className={`modal-body ${classes.paddingHorizontal0}`}>
          <div className={classes.body}>
            <Typography
              variant="body1"
              className={`gm format ${classes.format}`}
            >
              <Trans i18nKey="Style">Style</Trans>
            </Typography>
            <div className={classes.selectContainer}>
              <div className={classes.selectPaperContainer}>
                <Paper
                  square="true"
                  className={`modal-paper ${classes.selectPaper}`}
                  elevation={0}
                >
                  <Select
                    native={!isDesktop}
                    name="cite"
                    id="cite"
                    onChange={onSelectChange}
                    className={classes.select + " gm"}
                    value={selectedFormat}
                    autoFocus={false}
                  >
                    {Object.keys(selectMap).map((key) =>
                      isDesktop ? (
                        <MenuItem
                          key={key}
                          selected
                          classes={{ selected: classes.selected }}
                          value={key}
                          className={classes.menuItem + " gm"}
                        >
                          {key}
                        </MenuItem>
                      ) : (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      )
                    )}
                  </Select>
                </Paper>
              </div>
              <div className="downloadbtn">
                <Button
                  variant={copiedToClipboard ? "contained" : "outlined"}
                  color="primary"
                  onClick={copyToClipboard}
                  className={
                    "copybtn" +
                    classes.copyToClipboard +
                    " " +
                    (copiedToClipboard ? classes.copied : "")
                  }
                  disableElevation
                >
                  {copiedToClipboard ? (
                    <Trans i18nKey="Copied">Copied</Trans>
                  ) : (
                    <Trans i18nKey="Copy To Clipboard">Copy To Clipboard</Trans>
                  )}
                </Button>
              </div>
            </div>
            <div className={classes.clipboardArea} id="clipboardArea">
              <Typography
                variant="body1"
                className={classes.clipboardAreaText}
                dangerouslySetInnerHTML={{ __html: selectMap[selectedFormat] }}
              ></Typography>
            </div>
          </div>
          <Divider className="modal-divider" />
          <div className={classes.body}>
            <Typography
              variant="body1"
              className={`gm format ${classes.format}`}
            >
              {t("Format")}
            </Typography>
            <div className={classes.formatbtns + " formatbtns"}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Button
                    onClick={() => downloadCite("RIS")}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disableElevation
                  >
                    .RIS
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    onClick={() => downloadCite("BIB")}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disableElevation
                  >
                    .BIB
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    onClick={() => downloadCite("ENW")}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disableElevation
                  >
                    .ENW
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );

  const citeIcon = (
    // <svg
    //   width="13"
    //   height="11"
    //   viewBox="0 0 13 11"
    //   fill="none"

    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M2.1712 11C4.46766 9.3626 6.09606 6.71756 6.09606 3.82061C6.09606 1.3855 4.55117 0 2.83926 0C1.29437 0 0 1.30153 0 2.89695C0 4.45038 1.0856 5.54199 2.50523 5.54199C2.75575 5.54199 3.08978 5.45801 3.21504 5.41603C2.92277 6.84351 1.54489 8.56489 0.208769 9.3626L2.1712 11Z"
    //     fill="#007596"
    //   />
    //   <path
    //     d="M8.94024 11C11.2367 9.3626 12.8651 6.71756 12.8651 3.82061C12.8651 1.3855 11.3202 0 9.6083 0C8.06341 0 6.76904 1.30153 6.76904 2.89695C6.76904 4.45038 7.85464 5.54199 9.27427 5.54199C9.52479 5.54199 9.85882 5.45801 9.98409 5.41603C9.69181 6.84351 8.31393 8.56489 6.97781 9.3626L8.94024 11Z"
    //     fill="#007596"
    //   />
    // </svg>
    <FormatQuoteRoundedIcon />
  );
  // const { t } = this.props;
  return (
    <>
      <Fragment>
        {isDesktop ? (
          <Button
            onClick={handleOpen}
            variant="outlined"
            className="newButton"
            startIcon={citeIcon}
          >
            {t("Cite")}
          </Button>
        ) : (
          // <CustomTooltip title={t("Cite")} arrow>
          <IconButton
            className="iconButton"
            onClick={handleOpen}
            variant="outlined"
            color="primary"
            disableFocusRipple
          >
            {citeIcon}
          </IconButton>
          // </CustomTooltip>
        )}
        {/* </Tooltip> */}
        {/* </TooltipIcon> */}
        {citModal}
      </Fragment>
    </>
  );
};

export default CiteButton;
CiteButton.propTypes = {
  artistId: PropTypes.string,
  entryId: PropTypes.string,
  apaString: PropTypes.string.isRequired,
  mlaString: PropTypes.string.isRequired,
  harvardString: PropTypes.string.isRequired,
  chicagoString: PropTypes.string.isRequired,
  vancouverString: PropTypes.string.isRequired,
};
