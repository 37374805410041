import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import { Grid, Button, Typography, Box } from "@material-ui/core";

import { Trans, useTranslation } from "react-i18next";
import CollectionTile from "../components/Tiles/CollectionTile";
import axios from "axios";
import { Link } from "react-router-dom";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { trackPromise } from "react-promise-tracker";

const useStyles = makeStyles((theme) => ({
  section: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: "90px",
      paddingTop: "60px",
    },
  },
  // contentBox:{
  //   marginBottom:"50px !important",
  // },
  title: {
    fontSize: "40px",
    fontWeight: "700",
    color: "#000000",
    textAlign: "",
    marginTop: "50px",
    marginBottom: "55px",
    lineHeight: "30px",
    "&:hover": {
      borderRadius: "50px !important",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      marginBottom: "55px",
    },
  },
  homebtn: {
    marginLeft: "10px",
  },
  midpart: {
    width: "680px",
    height: "400px !important",
    fontFamily: "Times",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    marginLeft: "50px !important",
  },
  image: {
    width: "100%",
    height: "50%",
  },
  btnstyle: {
    marginLeft: "70px !important",
  },
  coverarea: {
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
  },
  cover: {
    marginBottom: "20px",
  },
  videosection: {
    marginTop: "60px",
    marginBottom: "30px",
  },
  list: {
    padding: "20px 0 30px 15px",
    "& li": {
      fontFamily: '"Times"',
      fontSize: "20px",
      marginBottom: "15px",
    },
  },
  overx: {
    backgroundColor: "white !important",
  },
  artistwarp: {
    marginTop: "50px",
  },
  mainDiv: { margin: "90px", marginTop: "0px" },
  collectionsGrid: {
    marginBottom: "60px",
  },
}));

function Collections(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [collections, setCollections] = useState([]);
  useEffect(() => {
    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME + "/collection/allPublished"
        )
        .then((res) => {
          setCollections(res?.data);
        })
        .catch((error) => {
          console.log(error.message);
        })
    );
  }, []);

  let gridCollections = [];
  for (let index = 0; index < collections.length; index += 8) {
    gridCollections.push(collections.slice(index, index + 8));
  }

  gridCollections = gridCollections.map((arr) => (
    <>
      {arr?.length !== 0 ? (
        <Grid container spacing={3} className={classes.collectionsGrid}>
          {arr?.length !== 0 ? (
            <Grid item md={6}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={6}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {arr?.length !== 0 ? (
        <Grid container spacing={3} className={classes.collectionsGrid}>
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {arr?.length !== 0 ? (
        <Grid container spacing={3} className={classes.collectionsGrid}>
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
          {arr?.length !== 0 ? (
            <Grid item md={4}>
              <CollectionTile collection={arr.splice(0, 1)[0]} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </>
  ));

  return (
    <div>
      <Navbar isHomepage={false} />

      <div className={classes.mainDiv}>
        <div className={classes.title + " btnstyle"}>
          <Stack spacing={0} direction="row">
            <Link to="/">
              <Button variant="text">
                <ArrowBackIcon />
                <div className={classes.homebtn}>Home</div>
              </Button>
            </Link>
          </Stack>
        </div>
        <Box className={classes.contentBox}>
          <Typography className={classes.title}>{t("Collections")}</Typography>

          <Box className={classes.tilesBox}>{gridCollections}</Box>
        </Box>
      </div>
      <OuterSpinner />
      <Footer />
    </div>
  );
}
export default Collections;
