import { Popover } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CustomPopOver = withStyles({
  paper: {
    background: "#ffffff",
    marginTop: "20px",
    overflow: "visible",
    width: "80px",
    // border: "1.5px solid #eaeaea",
    borderRadius: "10px",

    // "&::before": {
    //   content: '""',
    //   position: "absolute",
    //   top: "-18px",
    //   left: "10px",
    //   borderBottom: "10px solid #005D78",
    //   borderRight: "10px solid transparent",
    //   borderLeft: "10px solid transparent",
    //   borderTop: "10px solid transparent",
    //   zIndex: 10,
    // },
  },
})((props) => (
  <Popover
    // style={{
    //   position: "absolute",
    // }}
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  >
    {props.children}
  </Popover>
));

export default CustomPopOver;
