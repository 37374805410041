import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  topDiv: {
    marginTop: "25px",
    lineHeight: "30px",
    marginBottom: "50px",
  },
  homeButton: {
    paddingLeft: "0px",
  },
  homebtn: {
    fontSize: "16px",
    marginLeft: "10px",
  },
}));

const BackToHome = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  let navigate = useNavigate();
  return (
    <div className={classes.topDiv + " btnstyle"}>
      <Stack spacing={0} direction="row">
        <Button
          variant="text"
          className={classes.homeButton}
          onClick={() => {
            navigate("/");
          }}
        >
          <ArrowBackIcon />
          <div className={classes.homebtn}>{t("Home")}</div>
        </Button>
      </Stack>
    </div>
  );
};

export default BackToHome;
