// This configuration file is a single place to provide any values to set up the app

export const IDENTITY_POOL_ID =
  "eu-central-1:4c70e891-d120-4e2f-8727-dda905210c76"; // REQUIRED - Amazon Cognito Identity Pool ID

export const REGION = "eu-central-1"; // REQUIRED - Amazon Cognito Region

export const MAP = {
  NAME: "aow-connected-places-map", // REQUIRED - Amazon Location Service Map resource name
  STYLE: "VectorEsriLightGrayCanvas", // REQUIRED - String representing the style of map resource
};

export const PLACE = "AOWMapPlaceIndex"; // REQUIRED - Amazon Location Service place index resource name
