export const NAVIGATION = "NAVIGATION";
export const SEARCH = "SEARCH";

export const search = (searchString, onlyHighlightMark) => ({
  type: SEARCH,
  searchString: searchString,
  onlyHighlightMark: onlyHighlightMark
});

export const navigation = (currentIndex) => ({
  type: NAVIGATION,
  currentIndex: currentIndex,
});
