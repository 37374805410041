import React, { useEffect } from "react";

import OuterSpinner from "../components/Spinner/OuterSpinner";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

import { useLocation, useNavigate } from "react-router-dom";
import { getValidateUserAPIData, VALIDATE_USER_API } from "./Constants";
import {
  isJWTPresent,
  onValidateUserSuccessResponse,
} from "./helper_functions";
import { useInitialRender } from "./hooks/useInitialRender";

function ValidateUser() {
  const isInitialRender = useInitialRender();
  const navigate = useNavigate();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const redirectURLStrOrig = urlSearchParams.get("redirect_url");

  const redirectURLStr = redirectURLStrOrig
    ? redirectURLStrOrig
        .replace("logout=success&", "")
        .replace("logout=success", "")
    : undefined;

  const getRedirectUrl = (status) => {
    if (redirectURLStr) {
      return redirectURLStr.includes("%3F") || redirectURLStr.includes("?")
        ? `${redirectURLStr}&login=${status}`
        : `${redirectURLStr}?login=${status}`;
    }
    return null;
  };

  useEffect(() => {
    const getIP = async () => {
      try {
        const response = await axios.post(
          VALIDATE_USER_API,
          getValidateUserAPIData()
        );
        const data = response.data;

        // console.log(JSON.stringify(data, null, 2));

        if (data.login == null) {
          window.location.replace("/");
          return;
        }

        if (data.login === "success") {
          if (!data.jwtToken) {
            window.location.replace("/?login=fail");
            return;
          }

          onValidateUserSuccessResponse(data);

          if (redirectURLStr) {
            navigate(getRedirectUrl("success"), { replace: true });
            return;
          }

          window.location.replace("/?login=success");
          return;
        } else if (data.wayf) {
          // console.log("redirect to: " + response.data.wayf);
          if (!isJWTPresent()) {
            window.location.replace(data.wayf);
            return;
          }
          // a jwtToken exists
          // try redirecting to the redirectURLStr
          if (redirectURLStr) {
            navigate(getRedirectUrl("success"), { replace: true });
            return;
          }
          // if there's no redirectURLStr, redirect to the home page
          window.location.replace("/?login=success");
          return;
        } else {
          window.location.replace("/about?login=fail");
          return;
        }
      } catch (error) {
        const response = error.response;

        if (!response) {
          console.log(error);
          console.error("failed - response is null");
          window.location.replace("/about?login=fail");
          return;
        } else if (response.status === 401) {
          // UNAUTHORIZED
          // if the user was automatically IP authenticated,
          // redirect to the AOW WAYF page
          if (response.data.auth_method === "ip") {
            let wayfURL = response.data.wayf;
            // this should be http(s)://connect.liblynx.com/go/aow
            if (redirectURLStr) {
              // add the full path to the redirect URL
              const absoluteRedirectURLStr =
                window.location.origin + redirectURLStr;

              // url encode the redirect URL
              wayfURL +=
                "?target=" + encodeURIComponent(absoluteRedirectURLStr);
            }
            window.location.replace(wayfURL);
            return;
          } else {
            console.log(response);
            console.error("Unauthorized");
            navigate("/about?login=fail");
            return;
          }
        }

        console.error("Authentication failed");
        console.log(error);
        console.log(response);
        console.log("status: " + response.status);
        navigate("/about?login=fail");
        return;
      }
    };
    if (isInitialRender) getIP();
  }, []);

  return (
    <div>
      <div className="center-container">
        <h1 className="gm"> Authorizing Your Request </h1>
      </div>
      <OuterSpinner />
    </div>
  );
}

export default ValidateUser;
