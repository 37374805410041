import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  searchField: {
    fontSize: "24px",
    fontWeight: "500",
    paddingRight: "5px",
    fontFamily: "Gotham-Book,tllmedium,sans-serif",
  },
  searchText: {
    fontSize: "24px",
    fontFamily: "Gotham-Book,tllmedium,sans-serif",
    fontWeight: "900",
  },
  resultVal: {
    display: "flex",

    alignItems: "center",
    lineHeight: "40px",
  },
}));
const SearchValues = ({
  searchField,
  searchValue,
  searchQualifier,
  searchFieldI18nKey,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return searchValue ? (
    <div className={classes.resultVal}>
      {searchQualifier && searchValue ? (
        <Typography className={classes.searchField}>
          {", " + t(searchQualifier).toLowerCase()}{" "}
        </Typography>
      ) : null}
      <Typography className={classes.searchField}>
        {searchFieldI18nKey ? (
          <Trans i18nKey={searchFieldI18nKey}>{searchField}</Trans>
        ) : (
          searchField
        )}
        :
      </Typography>

      <Typography className={classes.searchText}>{searchValue}</Typography>
    </div>
  ) : null;
};

SearchValues.propTypes = {
  searchField: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  searchQualifier: PropTypes.string,
  searchFieldI18nKey: PropTypes.string,
};

export default SearchValues;
