// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { Typography } from "@mui/material";
import { Marker } from "react-map-gl";

// Displays a single marker with interaction and custom icon
const SingleMarker = ({ geometry, label, displayLabels }) => {
  const latitude = geometry?.point[1];
  const longitude = geometry?.point[0];

  return (
    <>
      {/* Display marker with click handler that passes data back to top component */}
      <Marker latitude={latitude} longitude={longitude}>
        <div className="map-marker-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={26}
            width={20}
            viewBox="0 0 20 26"
          >
            <circle
              fill="none"
              stroke="#ffffff"
              strokeWidth="3"
              cx="50%"
              cy="50%"
              r="3"
            />
            <circle fill="black" cx="50%" cy="50%" r="3" />
          </svg>
          {displayLabels && (
            <Typography className="map-marker-label gb">{label}</Typography>
          )}
        </div>
      </Marker>
    </>
  );
};

// Display markers from GeoJSON data.
const MarkersFeature = ({ geoCodedPlaces, displayLabels }) => {
  return (
    <>
      {
        // Loop through each marker from the GeoJSON to have them displayed
        geoCodedPlaces.map((geoCodedPlace, index) => {
          return (
            <SingleMarker
              key={index}
              geometry={geoCodedPlace.data?.geometry}
              label={geoCodedPlace.name}
              displayLabels={displayLabels}
            />
          );
        })
      }
    </>
  );
};

export default MarkersFeature;
