import { useEffect, useState } from "react";
import { Geo } from "aws-amplify";
import Map from "./AWSMap/Map";
import { Spinner } from "./Spinner/Spinner";
import { Divider, Grid, Typography, Checkbox } from "@mui/material";
import { countries } from "./AWSMap/countries";
import { useTranslation } from "react-i18next";

const ConnectedPlaces = ({ places }) => {
  const { t } = useTranslation();

  const [GeoCodedPlaces, setGeoCodedPlaces] = useState([]);
  const [FetchingGeoCodedPlaces, setFetchingGeoCodedPlaces] = useState(true);

  const fetchGeoCodedPlaces = async () => {
    let tempGeoCodedPlaces = [];
    for (const place of places) {
      try {
        const data = await Geo.searchByText(place, { maxResults: 1 });
        let country = countries.find(
          (element) => element["alpha-3"] === data[0]?.country
        );
        let existingPlace = tempGeoCodedPlaces.find(
          (element) =>
            element.data?.geometry?.point[0] === data[0]?.geometry?.point[0] &&
            element.data?.geometry?.point[1] === data[0]?.geometry?.point[1]
        );
        if (existingPlace) {
          existingPlace.name += ", " + place;
        } else {
          if (place && country?.name) {
            tempGeoCodedPlaces.push({
              name: place,
              country: country.name,
              data: data[0],
            });
          }
        }
      } catch (error) {
        console.log(error);
        console.log("Exception occurred while geocoding: " + place);
      }
    }
    setGeoCodedPlaces(tempGeoCodedPlaces);
  };

  useEffect(() => {
    fetchGeoCodedPlaces().finally(() => {
      setFetchingGeoCodedPlaces(false);
    });
  }, []);

  const [DisplayLabels, setDisplayLabels] = useState(true);
  const handleDisplayLabelCheckboxClick = (e) => {
    setDisplayLabels(!DisplayLabels);
  };

  return (
    <>
      <Grid container className="connected-places-container">
        <Grid item xs={12} md={4} className="connected-places-content-grid">
          <Typography className="gb fw600">{t("Map settings")}</Typography>
          <div className="displaylabel-checkbox-div">
            <Checkbox
              checked={DisplayLabels}
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": `list-item-label`,
              }}
              sx={{ "&.Mui-checked": { color: "#007596 !important" } }}
              onClick={handleDisplayLabelCheckboxClick}
            />
            <Typography
              id={`list-item-label`}
              className="gb cursor-pointer"
              onClick={handleDisplayLabelCheckboxClick}
            >
              {t("Display labels")}
            </Typography>
          </div>
          <Divider sx={{ borderBottomWidth: 1.5, margin: "20px 0px" }} />
          {!FetchingGeoCodedPlaces &&
            GeoCodedPlaces.map((place, i) => (
              <div key={"place" + i} className="connected-place">
                <Typography className="gb fw600">{place.country}</Typography>
                <Typography className="gb">{place.name}</Typography>
              </div>
            ))}
        </Grid>
        <Grid item xs={12} md={8} className="connected-places-map-grid">
          {FetchingGeoCodedPlaces ? (
            <Spinner show />
          ) : GeoCodedPlaces?.length !== 0 ? (
            <Map
              geoCodedPlaces={GeoCodedPlaces}
              displayLabels={DisplayLabels}
            />
          ) : (
            <div className="map-nodata-div">
              <Typography className="gb fw600">No data found</Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default ConnectedPlaces;
