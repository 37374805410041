import { SEARCH_FIELDS, SEARCH_QUOLIFIER, SEARCH_TYPE } from "./Constants";
import i18n from "i18next";
export const isJWTPresent = () =>
  localStorage.getItem("jwtToken") !== null &&
  localStorage.getItem("jwtToken") !== undefined;
export const isAuthorized = () =>
  isNotNullOrUndefined(localStorage.getItem("isAuthorized"))
    ? localStorage.getItem("isAuthorized") === "true"
    : false;

export function minimizeXML(data) {
  return data
    ?.replace(/\n/g, "")
    ?.replace(/\>[\t ]+\</g, "><")
    ?.replace(/\>[\t ]+$/g, ">")
    ?.replace(/[\n\t ]+/g, " ")
    ?.replaceAll("&amp;nbsp;", "&nbsp;")
    ?.replaceAll("<form", "<formcustom")
    ?.replaceAll("</form>", "</formcustom>");
}
export function fetchFileName(name) {
  if (!name) return "";
  var arr = [];
  arr = name.split("/");
  var fileName = arr[arr.length - 1];
  return fileName.substr(0, fileName.length - 4);
}

const otherKeys = ["volume", "paragraph", "line", "page", "prefix"];

export function getSearchURL(searchType, formData, alphabeticField, alphabet) {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("type", searchType);
  if (searchType === SEARCH_TYPE.ALPHABETICAL_SEARCH) {
    urlSearchParams.append(`field`, alphabeticField);
    urlSearchParams.append(`value`, alphabet);
  }
  formData?.search?.forEach((element, index) => {
    if (element?.value && element?.value?.trim()?.length) {
      urlSearchParams.append(`field${index}`, SEARCH_FIELDS[element?.field]);
      urlSearchParams.append(`val${index}`, element?.value);
    }
    if (element?.searchQualifier) {
      urlSearchParams.append(
        `join${index}`,
        SEARCH_QUOLIFIER[element?.searchQualifier]
      );
    }
  });
  if (formData) {
    otherKeys.forEach((key) => {
      if (formData[key] !== null && formData[key] !== undefined) {
        urlSearchParams.set(key, formData[key]);
      }
    });
  }
  //Reseting the perfix here
  urlSearchParams.delete("prefix");
  return urlSearchParams.toString();
}

export function getFormDataFromQuery(query) {
  const urlSearchParams = new URLSearchParams(query);
  var formData = {};
  var maxRows = -1;
  urlSearchParams.forEach(function (_, key) {
    if (key.startsWith("field") && key.length > 5) {
      const ind = parseInt(key.substr(5));
      if (ind !== null && ind !== undefined && ind > maxRows) {
        maxRows = ind;
      }
    }
  });
  const searchFields = Object.keys(SEARCH_FIELDS);
  const joins = Object.keys(SEARCH_QUOLIFIER);
  var search = new Array(maxRows + 1);
  urlSearchParams.forEach(function (value, key) {
    if (key.startsWith("field") && key.length > 5) {
      const ind = parseInt(key.substr(5));
      if (ind !== null && ind !== undefined) {
        if (search[ind]) {
          search[ind].field = searchFields[value].toString();
        } else {
          search[ind] = { field: searchFields[value].toString() };
        }
      }
    } else if (key.startsWith("val")) {
      const ind = parseInt(key.substr(3));
      if (ind !== null && ind !== undefined) {
        if (search[ind]) {
          search[ind].value = value;
        } else {
          search[ind] = { value: value };
        }
      }
    } else if (key.startsWith("join")) {
      const ind = parseInt(key.substr(4));
      if (ind !== null && ind !== undefined) {
        if (search[ind]) {
          search[ind].searchQualifier = joins[value];
        } else {
          search[ind] = { searchQualifier: joins[value] };
        }
      }
    } else if (otherKeys.indexOf(key) !== -1) {
      formData[key] = value;
    }
  });

  formData.search = search;

  return formData;
}

export const getSortBy = (value) => {
  if (value && value.startsWith("author")) {
    return "AUTHOR";
  } else if (value && (value.startsWith("asc") || value.startsWith("desc"))) {
    return "NAME";
  }
  return null;
};

export const getSortType = (value) => {
  if (value && value.endsWith("desc")) {
    return false;
  }
  return true;
};

export const getSearchFiltersFromQuery = (query) => {
  const pageNumber = parseInt(query.get("pageNo") ?? "0");
  const prefixFilter = query.get("prefix");
  const searchType = parseInt(query.get("type") ?? "0");
  const size = parseInt(query.get("size") ?? "10");
  const querySortBy = query.get("sortBy");
  const sortBy = getSortBy(querySortBy);
  const sortType = getSortType(querySortBy);
  const searchForm = getFormDataFromQuery(query);
  const alphabetSearch = query.get("value");
  const queryField = parseInt(query.get("field") ?? "0");
  const alphabetSearchField = Object.keys(SEARCH_FIELDS)[queryField];
  const volume = query.get("volume");
  const paragraph = query.get("paragraph");
  const line = query.get("line");
  const page = query.get("page");
  const accessType = query.get("accessType");

  return {
    pageNumber,
    prefixFilter,
    searchType,
    size,
    querySortBy,
    sortBy,
    sortType,
    searchForm,
    alphabetSearch,
    queryField,
    alphabetSearchField,
    volume,
    paragraph,
    line,
    page,
    accessType,
  };
};

export const getSearchURLParams = (searchURL) => {
  const url = new URL(decodeURIComponent(window.location.origin + searchURL));
  const params = new URLSearchParams(url.search);

  return params;
};

const isPharseSearch = (str) => {
  return (
    str?.length &&
    str.length > 2 &&
    str[0] === '"' &&
    str[str.length - 1] === '"'
  );
};

export const prepareSearchFormListForHighlight = (list) => {
  return list
    ?.map((e) => {
      var value = e?.value;
      if (isPharseSearch(value)) {
        value = value.substring(1, value.length - 1);
      } else {
        value = value.split(" ");
      }
      return value;
    })
    ?.flat();
};

export const checkIfLanguageIs = (locale) => {
  return i18n.language?.startsWith(locale);
};

export const isNotNullOrUndefined = (obj) => obj !== null && obj !== undefined;

export const isNotNullOrEmptyStr = (str) =>
  isNotNullOrUndefined(str) && str.trim().length !== 0;

export const isObjEmpty = (obj) => {
  for (var key in obj) return false;
  return true;
};

export const isNotNullOrEmptyArr = (arr) =>
  isNotNullOrUndefined(arr) && arr.length !== 0;

export const onValidateUserSuccessResponse = (data) => {
  if (!data?.jwtToken?.length) {
    return;
  }

  localStorage.setItem("jwtToken", data.jwtToken);
  console.info(data);

  const counterparty_id = data?.user?.account?.["counterparty_id"];
  const publisher_dot_reference = data?.user?.account?.["publisher.reference"];
  const publisher_reference = data?.user?.account?.["publisher_reference"];

  // console.log("----");
  // console.log("counterparty_id", counterparty_id);
  // console.log("publisher_reference", publisher_reference);

  if (isNotNullOrUndefined(counterparty_id)) {
    localStorage.setItem("aid", counterparty_id);
  } else if (isNotNullOrUndefined(publisher_dot_reference)) {
    localStorage.setItem("aid", publisher_dot_reference);
  } else if (isNotNullOrUndefined(publisher_reference)) {
    localStorage.setItem("aid", publisher_reference);
  }

  localStorage.setItem("AOW_logout_link", data?.user?.logoutLink);

  if (isNotNullOrUndefined(data?.user?.account?.["account.name"])) {
    localStorage.setItem("aname", data.user.account["account.name"]);
  } else if (isNotNullOrUndefined(data?.user?.account?.["account_name"])) {
    localStorage.setItem("aname", data.user.account["account_name"]);
  }

  let userName = undefined;

  if (
    isNotNullOrUndefined(
      data?.user?.account_individual?.individual?.["display.name"]
    )
  ) {
    userName = data.user.account_individual.individual["display.name"];
  } else if (isNotNullOrUndefined(data?.user?.account?.["account.name"])) {
    userName = data?.user?.account["account.name"];
  } else if (isNotNullOrUndefined(data?.user?.account?.["account_name"])) {
    userName = data?.user?.account["account_name"];
  } else if (
    isNotNullOrUndefined(
      data?.user?.account_individual?.individual?.["display_name"]
    )
  ) {
    userName = data.user.account_individual.individual["display_name"];
  }

  if (isNotNullOrUndefined(userName)) {
    localStorage.setItem("user_name", userName);
  }

  if (isNotNullOrUndefined(data?.isAuthorized)) {
    localStorage.setItem("isAuthorized", data?.isAuthorized);
  }
};

export const capitalizeStr = (str) => {
  var words = str.split(" ");
  var capWords = words.map((word) => {
    return word[0].toUpperCase() + word.slice(1);
  });
  return capWords.join(" ");
};

export const formateDate = (str) => {
  var dateStr = str.replaceAll(/(\d{4})\.(\d{2})\.(\d{2})/gi, "$1-$2-$3");
  var date = new Date(dateStr);
  return date.toString() !== "Invalid Date" && dateStr.length !== 4
    ? date.toLocaleDateString(
        i18n.language?.startsWith("de") ? "de-DE" : "en-GB",
        {
          day: "numeric",
          month: "long",
          year: "numeric",
        }
      )
    : dateStr;
};

export function getOrdinal(n) {
  let ord = ["st", "nd", "rd"];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] == undefined || exceptions.includes(n % 100)
      ? "th"
      : ord[(n % 10) - 1];
  return n + nth;
}

export const isNotEmptyDOBFilter = (dob) =>
  isNotNullOrEmptyStr(dob?.from) && isNotNullOrEmptyStr(dob?.to);

export const getPlacesFromGeoRegions = (geoRegions) => [
  ...new Set(geoRegions.map((ele) => ele.name)),
];
