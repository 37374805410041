import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import EastIcon from "@mui/icons-material/East";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { isNotNullOrEmptyStr } from "../utils/helper_functions";

const useStyles = makeStyles((theme) => ({
  artistNameDiv: {
    // margin: "19px 0px",
    marginTop: "8px",
    color: "#007596",
    fontFamily: "Gotham-medium !important",
    fontSize: "18px !important",
    fontWeight: "300 !important",
  },
  nameBox: {
    display: "flex",

    "&:hover": {
      "&  div": {
        display: "flex",
      },
    },
  },
  link: { float: "left" },
  subtextBox: { clear: "left", minHeight: "23px", marginBottom: "5px" },

  iconDiv: {
    display: "none",
    alignItems: "center",
    marginLeft: "15px",
  },
  nameText: {
    fontFamily: "Gotham-Medium, sans-serif !important",
    fontSize: "18px !important",
  },
  subtext: {
    fontFamily: "Gotham-Book, sans-serif !important",
    fontSize: "15px !important",
  },
  textNew: {
    fontFamily: "Gotham-Medium",
    color: "black",
    fontSize: "12px",
    background: "#F7F7F7",
    border: "1px solid #F7F7F7",
    padding: "5px",
    marginLeft: "20px",
  },
  textFree: {
    fontFamily: "Gotham-Medium",
    color: "white",
    fontSize: "12px",
    background: "#007596",
    border: "1px solid #F7F7F7",
    padding: "5px 8px",
    marginLeft: "20px",
  },
}));
const ArtistName = ({ id, name, isNew, isFree, isArtist, subtext }) => {
  const classes = useStyles();

  return isNotNullOrEmptyStr(name) ? (
    <>
      <div className={classes.artistNameDiv}>
        <Link
          to={(isArtist ? "/artist/" : "/entry/") + id}
          className={classes.link}
        >
          <Box className={classes.nameBox}>
            <Typography
              className={classes.nameText}
              style={{
                fontSize: "12px",
                color: "#007596",
                // fontWeight: "bold",
              }}
            >
              {name}
            </Typography>
            {isNew && <NewLabel />}
            {isFree && <FreeLabel />}
            <div className={classes.iconDiv}>
              <EastIcon />
            </div>
          </Box>
        </Link>
        <Box className={classes.subtextBox}>
          <Typography
            className={classes.subtext}
            style={{
              color: "#000000",
            }}
          >
            {subtext}
          </Typography>
        </Box>
      </div>

      {/* <div>
        <Link to={"/entry/" + id}>
          <Box className={classes.nameBox}>
            <Typography
              className={classes.nameText}
              style={{
                fontSize: "12px",
                color: "#007596",
                // fontWeight: "bold",
              }}
            >
              {name}
            </Typography>
          </Box>
        </Link>
      </div> */}
    </>
  ) : null;
};
// const Accessible = () => {
//   const { t } = useTranslation();
//   return (
//     <div>
//       <LockOpenIcon style={{ color: "black", width: "3%", height: "3%" }} />
//       <span style={{ color: "black" }}>{t("Accessible")}</span>
//     </div>
//   );
// };
const NewLabel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <span className={classes.textNew}>{t("New")}</span>
    </div>
  );
};
export default ArtistName;

const FreeLabel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return <div className={classes.textFree}>{t("Free")}</div>;
};
