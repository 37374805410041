import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

function SearchButton(props) {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      className="btn search-btn"
      type="submit"
      onClick={() => props.close()}
      value="submit"
    >
      <SearchIcon />
      &nbsp;
      {t("Search")}
    </Button>
  );
}

export default SearchButton;
