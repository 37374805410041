import { Typography } from "@material-ui/core";
import React from "react";
import ExpandableContent from "react-expandable-content";
import { Box } from "@mui/system";

export function contentTableOldFormat(classes, entry, t) {
  <>
    <Box className={classes.boxDesign}>
      <Typography className={classes.ContentTitle}>{t("Contents")}</Typography>

      {entry.bodyMap?.sec?.length > 0 &&
        entry.bodyMap?.sec?.map((element, i) => (
          <div key={"entrybody" + i} className={classes.mainBox}>
            <div className="mainTitle1">
              <a href={"#" + element.title}>{element.title}</a>
            </div>
            <div>
              {element?.sec?.map((sec, i) => (
                <div key={"sub" + i} className={classes.boxSubTitle}>
                  <a href={"#" + element.title + sec.title} target="_self">
                    {sec.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
    </Box>
  </>;
}

export function bodyOldFormat(classes, entry, tableData, t) {
  return (
    <div className={classes.contentDiv + " artistContent"}>
      {entry?.bodyMap?.sec?.length > 0 && (
        <>
          {/* <Typography variant="h6" className={classes.displayContent}>
                            Display Text:
                          </Typography>
                          <MUISwitch
                            leftswitch="Abbreviated"
                            rightswitch="Expanded"
                            className={classes.switchclick}
                            switchClick={onCollapse}
                          /> */}
          <div style={{ marginTop: "30px" }}>
            {entry.bodyMap?.sec?.map((element, index) => (
              <React.Fragment key={"bmap" + index}>
                <Typography
                  variant="body1"
                  id={element.title}
                  className={classes.mainTitle}
                >
                  {element.title}
                </Typography>

                <div
                  style={{
                    marginBottom:
                      index !== entry.bodyMap?.sec?.length - 1 ? "60px" : "0px",
                  }}
                >
                  {entry.bodyMap?.sec?.length > 0 &&
                    element.sec.map((element1, i) => {
                      let tableContent = element1.p;
                      if (tableData.includes(element1.title)) {
                        if (tableContent.startsWith("<p>")) {
                          tableContent = tableContent.substring(3);
                          if (tableContent.endsWith("</p>")) {
                            tableContent = tableContent.substring(
                              0,
                              tableContent.length - 4
                            );
                          }
                        }
                      }
                      return (
                        <React.Fragment key={"tbl" + i}>
                          <div
                            className={classes.tableTextContent}
                            style={{
                              marginTop: `${i !== 0 ? "60px" : "30px"}`,
                            }}
                          >
                            <Typography
                              id={element.title + element1.title}
                              variant="h3"
                              className={classes.subTitle}
                              dangerouslySetInnerHTML={{
                                __html: element1.title,
                              }}
                            />
                            {/* {tableData.includes(element1.title) ? (
                                                          <div className={classes.switchContent}>
                                                            <MUISwitch
                                                              leftswitch="Table"
                                                              rightswitch="Text"
                                                              name={element1.title}
                                                              defaultChecked
                                                              switchClick={switchClick}
                                                            />
                                                          </div>
                                                        ) : null} */}
                          </div>
                          <ExpandableContent
                            className={classes.textExpand}
                            maxHeight={250}
                            collapseText={t("View less")}
                            expandText={t("View more")}
                          >
                            {/* {tableData.includes(element1.title) &&
                                                        isNotNullOrUndefined(
                                                          SwitchChecked[element1.title]
                                                        ) &&
                                                        !SwitchChecked[element1.title] ? (
                                                          <CustomTable rowData={tableContent.split(";")} />
                                                        ) : ( */}
                            <Typography
                              variant="body1"
                              className={classes.content}
                              dangerouslySetInnerHTML={{
                                __html: element1.p,
                              }}
                            ></Typography>
                            {/* )} */}
                          </ExpandableContent>
                        </React.Fragment>
                      );
                    })}
                </div>
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
