import {
  Button,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../CustomTooltip";

import { ReactSession } from "react-client-session";
import { checkIfLanguageIs } from "../../utils/helper_functions";

const useStyles = makeStyles((_) => ({
  modalBody: {
    "& button:not(:last-child)": {
      marginBottom: "20px",
    },
  },
}));

const DownloadButton = ({ fileName, url }) => {
  const { t } = useTranslation();
  // const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const addScript = (fileName) => {
    if (!fileName) return;

    const aid = localStorage.getItem("aid") ?? "";
    const aname = localStorage.getItem("aname") ?? "";
    if (aid === "") return;

    const getCurrentURL = () => {
      const u = new URL(window.location.href);
      u.hash = "download";
      return u.toString();
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
        var _ll = _ll || [];
        _ll.push(["trackRequest", {
          ${
            aid
              ? `"aid": '${aid}',
                  "aname": '${aname}',`
              : "anon:true,"
          }
          "url": '${getCurrentURL()}',
          "sid": '${ReactSession.get("sid")}',
          "at": "controlled",
          "am": "regular",
          "im": {
            "title": '${fileName}',
            "dtype": "article",
            "id": 'AOW_${fileName}',
          }
      }]);
    `;
    document.body.appendChild(script);
  };

  const downloadPdf = () => {
    window.open(
      `${
        process.env.REACT_APP_SERVER_HOST_NAME
      }/download/book/${fileName}?languageCode=${
        checkIfLanguageIs("de") ? "de" : "en"
      }`,
      "_self"
    );

    addScript(fileName);
  };

  const openUrl = () => {
    //open pdfUrl in a new tab
    window.open(url, "_blank");
    addScript(fileName);
  };

  const downloadIcon = <GetAppIcon />;
  return (
    <Fragment>
      {isDesktop ? (
        <>
          <Button
            square
            variant="outlined"
            className="newButton"
            startIcon={downloadIcon}
            aria-describedby="download"
            onClick={() => (url ? openUrl() : downloadPdf())}
          >
            {t("Download")}
          </Button>
          {/* <CustomTooltip title={t("Download")} arrow>
            <IconButton
              variant="text"
              className="newButton"
              onClick={() => (url ? openUrl() : downloadPdf())}
            >
              {downloadIcon}
            </IconButton>
          </CustomTooltip> */}
        </>
      ) : (
        <Button
          className="iconButton"
          onClick={() => (url ? openUrl() : downloadPdf())}
          disableFocusRipple
          variant="outlined"
          color="primary"
        >
          {downloadIcon}
        </Button>
      )}
    </Fragment>
  );
};

export default DownloadButton;

DownloadButton.propTypes = {
  fileName: PropTypes.string,
};
